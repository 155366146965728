import React from "react";
import Skeleton from "react-loading-skeleton";

function ReviewPortfolioSkeleton() {
  return (
    <div className="sm:w-[100%] lg:w-2/6 h-[100%] border-black lg:border-r lg:border-gray-400 py-[4%] sm:px-[3%] lg:px-0">
      <div className="flex justify-center sm:justify-center lg:justify-end lg:mr-[10%]">
        <div className="">
          <div className="flex justify-center">
            <Skeleton circle={true} height={300} width={300} />
          </div>
          <div>
            <div className="mt-4 text-4xl">
              <Skeleton />
            </div>
            <div className="mt-2 text-2xl w-4/5 mx-auto">
              <Skeleton />
            </div>

            <div className="mt-2 text-base w-3/5 mx-auto">
              <Skeleton />
            </div>

            <div className="my-5 pl-2 text-2xl">
              <Skeleton count={2} />
            </div>

            <div className="text-4xl">
              <Skeleton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewPortfolioSkeleton;
