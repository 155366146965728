import React, { useEffect, useState } from "react";
import ClientReview from "./ClientReview";
import Pagination from "../../Elements/Pagination";
import ReviewProtfolio from "./ReviewProtfolio";
import Cookies from "universal-cookie";
import axios from "axios";
import { contractorAPI } from "../../../constants/API";
import { getCityArea } from "../../../constants/Request";
import ReviewPortfolioSkeleton from "./ReviewPortfolioSkeleton";
import toast from "react-hot-toast";

function Reviews() {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [contractorData, SetContractordata] = useState({});
  const [cityAreaData, setCityAreaData] = useState([]);
  const [overallrating, setOverallRating] = useState(5);

  const [load, setLoad] = useState(false);

  const cookies = new Cookies();

  const getReviews = async () => {
    try {
      setLoad(true);
      const res = await axios.get(`${contractorAPI}/review`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "contractor",
        },
      });
      setData(res.data.data.reviews);

      // Calculate overall rating
      const reviews = res.data.data.reviews;
      let sumOfRatings = 0;
      reviews.forEach((review) => {
        sumOfRatings += review.Rating;
      });
      const overallRating =
        reviews.length > 0 ? sumOfRatings / reviews.length : null;
      setOverallRating(overallRating);
      const cityAreaID = res.data.data.contractor.Contractor_CityAreas?.map(
        (item) => item.City_Area_ID
      );

      getCityArea().then((data) => {
        const cityAreaName = data
          .filter((city) => cityAreaID.includes(city.ID))
          .map((city) => city.Name);
        setCityAreaData(cityAreaName);
      });

      SetContractordata({ ...res.data.data.contractor, overallRating });
    } catch (error) {
      toast.error("Failed to fetch reviews");
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    getReviews();
  },[]);

  return (
    <div className="">
      <div className="h-full w-full flex md:flex-wrap flex-col sm:flex-col md:flex-row ">
        {/* First div  */}
        {load ? (
          <ReviewPortfolioSkeleton />
        ) : (
          <ReviewProtfolio
            contractor={contractorData || {}}
            cityAreaData={cityAreaData}
            ratings={overallrating}
          />
        )}

        {/* Secound Div */}
        <ClientReview data={data || []} load={load} />
      </div>

      {data.length > 0 && (
        <div className="h-full w-full flex md:flex-wrap flex-col sm:flex-col md:flex-row justify-center">
          <div className="sm:w-[100%] lg:w-2/6"></div>
          <div className="lg:w-4/6 md:-mt-14">
            <Pagination page={page} totalPages={10} setPage={setPage} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Reviews;
