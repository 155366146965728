import React, { useEffect } from "react";
import Layout from "../../../components/layout/Layout";
import CorporationSignupForm from "../../../components/Corporation/signup/CorporationSignupForm";

function CorporationSignup() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <CorporationSignupForm />
    </Layout>
  );
}

export default CorporationSignup;
