import React from "react";
import { LogoWithoutText } from "../../assets/images";

function Loading({ load }) {
  return (
    <div>
      {load && (
        <div className="absolute z-50 flex items-center justify-center h-full w-full bg-black opacity-55">
          <div className="loginLoader"></div>
          <img
            src={LogoWithoutText}
            className="lazy_image absolute"
            height={100}
            width={100}
            alt=""
          />
        </div>
      )}
    </div>
  );
}

export default Loading;
