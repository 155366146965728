import React from "react";

function PasswordIcon() {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="vuesax/linear/lock">
        <g id="lock">
          <path
            id="Vector"
            d="M6.5 10V8C6.5 4.69 7.5 2 12.5 2C17.5 2 18.5 4.69 18.5 8V10"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M12.5 18.5C13.8807 18.5 15 17.3807 15 16C15 14.6193 13.8807 13.5 12.5 13.5C11.1193 13.5 10 14.6193 10 16C10 17.3807 11.1193 18.5 12.5 18.5Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M17.5 22H7.5C3.5 22 2.5 21 2.5 17V15C2.5 11 3.5 10 7.5 10H17.5C21.5 10 22.5 11 22.5 15V17C22.5 21 21.5 22 17.5 22Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}

export default PasswordIcon;
