import Layout from "../../../components/layout/Layout";
import Login from "../../../components/Login/Login";

function ContractorLogin() {
  return (
    <div className="overflow-hidden">
      <Layout>
        <Login
          loginRoleText={"Contractor Login with UserID"}
          requiredRegister={true}
        />
      </Layout>
    </div>
  );
}

export default ContractorLogin;
