import {
  UserIdIcon,
  LoginVectorLeft,
  LoginVectorRight,
  LogoWithoutText,
} from "../../assets/images/index";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import Layout from "../../components/layout/Layout";
import axios from "axios";
import { resetPasswordMailAPI } from "../../constants/API";
import { useEffect, useState } from "react";

function SendMail() {
  const location = useLocation();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [userTypeContractor, setUserTypeContractor] = useState(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("User ID or email is required")
      .test("valid-userIdOrEmail", "Invalid user ID or email", (value) => {
        if (!value) return true; // Skip validation if the field is empty
        // Check if the value matches the pattern of either a user ID or an email
        return (
          /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/.test(value) ||
          /^[a-zA-Z0-9]+$/.test(value)
        );
      }),
  });

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    setUserTypeContractor(
      location.pathname.includes("contractor") ||
        location.pathname.includes("corporations")
    );
  }, []);

  const onSubmit = async (data) => {
    setLoad(true);
    try {
      let userType = "";
      if (location.pathname.includes("corporations")) {
        userType = "corporation";
        if (location.pathname.includes("propertymanager")) {
          userType = "propertymanager";
        } else if (location.pathname.includes("boardmember")) {
          userType = "boardmember";
        }
      } else if (location.pathname.includes("contractor")) {
        userType = "contractor";
      }
      // console.log(userType);

      if (userType) {
        const res = await axios.post(resetPasswordMailAPI, {
          email: data.email,
          userType: userType,
        });
        if (res.status === 200) {
          toast.success("Reset Password Mail Sent Successfully", {
            duration: 4000,
          });
          navigate("/");
        }
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      setLoad(false);
    }
  };

  return (
    <Layout>
      {load && (
        <div className="absolute z-50 flex items-center justify-center h-full w-full bg-black opacity-55">
          <div className="loginLoader"></div>
          <img
            src={LogoWithoutText}
            className="lazy_image absolute"
            height={100}
            width={100}
            alt=""
          />
        </div>
      )}
      <div className="relative w-full h-[100%] justify-center items-center">
        <div className="flex justify-center items-center mt-[8%] sm:mt-[9%] md:mt-[3%] lg:mt-[5%] h-[63%]">
          <div className="items-center justify-center">
            <h1 className="text-5xl euclidbold text-[#0893bd] text-center ">
              Welcome
            </h1>
            <p className="text-black text-opacity-50 pt-1 text-sm mt-0 text-center">
              Reset You Password
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="bg-white p-[1%] pt-4 rounded-lg">
                {/* ------------------------ */}
                <div className="relative bg-inherit my-2">
                  <input
                    type={userTypeContractor ? "text" : "email"}
                    id="email"
                    name="email"
                    {...register("email")}
                    className={`peer bg-transparent pl-5 h-10 w-72 rounded-lg text-black placeholder-transparent ring-1 px-2 ring-indigo-900 ps-10 focus:ring-sky-600 focus:outline-none focus:border-rose-600 ${
                      errors.email ? "ring-2 ring-red-500" : ""
                    }`}
                  />
                  <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                    <UserIdIcon />
                  </div>
                  <label
                    htmlFor="email"
                    className={`absolute cursor-text left-0 -top-3 euclidbold text-indigo-900 text-xs bg-inherit mx-1 px-1 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-sky-600  peer-focus:text-sm transition-all ${
                      errors.email ? "text-red-500" : ""
                    }`}
                  >
                    EMAIL ID Or USER ID
                  </label>
                </div>
                {/* Submit Button */}
                <div className="mt-3 mb-3">
                  <button
                    type="submit"
                    className="h-10 px-4 py-2.5  border mx-auto border-cyan-500 flex items-center justify-center text-cyan-500 text-xl euclidbold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800  hover:text-white transition duration-300 ease-in-out mt-4 md:mt-0"
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </form>

            {/* Go For Signup  */}

            <div>
              {/* OR Divider */}
              <div className="flex items-center justify-center mt-3">
                <div className="w-1/4 h-px border-t border-gray-300"></div>
              </div>
              <div className="text-center z-10 mb-3 text-black text-base mt-3">
                <span className="font-medium">Don’t have an account?</span>

                {location.pathname.includes("corporation") ? (
                  <Link to="/corporations/signup">
                    <span className="font-bold hover:text-gray-600 euclidbold">
                      {" "}
                      Register Now
                    </span>
                  </Link>
                ) : (
                  <Link to="/contractors/signup">
                    <span className="font-bold hover:text-gray-600 euclidbold">
                      {" "}
                      Register Now
                    </span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Login Form Bottom Side Left and Right Img */}
        <div className=" flex h-[25%]  mt-4  mx-[4%]  lg:mx-[16%]  justify-between ">
          <img
            src={LoginVectorLeft}
            className="w-34 h-36"
            alt="LogiForm Left Img"
          />
          <img
            src={LoginVectorRight}
            className="w-34 h-36"
            alt="LogiForm Right Img"
          />
        </div>
      </div>
    </Layout>
  );
}

export default SendMail;
