import React, { useState, useEffect, useRef } from "react";
import { IconDown, IconUp } from "../../assets/images";

const DropDown = ({ options, onSelect, selectOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleSelectOption = (option) => {
    onSelect(option);
    toggleDropdown(); // Close the dropdown after selecting an option
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative inline-block">
      {/* Dropdown button */}
      <button
        className="text-trueGray-500/50  w-full  md:w-52 h-10  focus:outline-none focus:font-medium rounded-sm text-base px-auto py-2.5 text-center inline-flex items-center"
        onClick={toggleDropdown}
        type="button"
      >
        <span className="flex items-center space-x-10">
          <p className="">{selectOption}</p>
          <p className=""> {isOpen ? <IconUp /> : <IconDown />}</p>
        </span>
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute z-50 top-full left-0 shadow-xl bg-white divide-y divide-gray-100 rounded-sm   sm:w-44 md:w-48">
          <ul className="py-2 text-base text-gray-700 cursor-pointer">
            {options.map((item) => (
              <li key={item.id} onClick={() => handleSelectOption(item.label)}>
                <div className="block px-4 py-2 hover:bg-gray-100 hover:text-gray-950">
                  {item.label}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropDown;
