import React from "react";
import LogoWhite from "../../assets/image/footer.png";
import { NavLink, useNavigate } from "react-router-dom";
import { FaFacebook, FaInstagram } from "react-icons/fa";

function Footer() {
  const navigate = useNavigate();
  return (
    <div className="relative w-full flex-col bg-[#0893bd] flex items-center justify-center overflow-x-hidden">
      <div className="mb-8">
        <img
          src={LogoWhite}
          onClick={() => navigate("/")}
          className="w-[20vh] h-[11vh] cursor-pointer"
          alt="Condominium White Logo"
        />
        <div className="flex items-center gap-4">
          <NavLink
            to="https://www.facebook.com/share/mypoXz4tCZbT9p1X/?mibextid=LQQJ4d"
            target="_blank"
          >
            <div className="text-indigo-900  bg-slate-50 rounded-full cursor-pointer text-lg p-1 hover:bg-indigo-900 hover:text-white ">
              <FaFacebook />
            </div>
          </NavLink>
          <NavLink
            to="https://www.instagram.com/condominiumportal?igsh=MXVmN2hiaHV0OTU3ZQ=="
            target="_blank"
          >
            <div className="text-indigo-900  bg-slate-50  rounded-full cursor-pointer hover:bg-indigo-900 hover:text-white text-lg p-1">
              <FaInstagram />
            </div>
          </NavLink>
        </div>
      </div>
      <hr className="w-[85%] m-auto my-4 mb-0 border-1 border-white/20" />

      <div className="w-full text-white my-3 flex flex-wrap items-center justify-center px-[2.5%] md:px-[10%] gap-4 md:gap-8">
        <NavLink to="/about">ABOUT US</NavLink>
        {/* <NavLink to="/features">FEATURES</NavLink> */}
        {/* <NavLink to="/benefits">BENEFITS</NavLink> */}
        <NavLink to="/contact">CONTACT US</NavLink>
        <NavLink to="/privacypolicy">PRIVACY POLICY</NavLink>
        <NavLink to="/terms">TERMS & CONDITIONS</NavLink>
      </div>
      <div className="text-gray-800 mb-2 text-center">
        Copyright © 2024 Condominium Portal Inc.
      </div>
    </div>
  );
}

export default Footer;
