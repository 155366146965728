import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Cookies from "universal-cookie";
import DropDown from "../../Elements/DropDown";
import { baseUrl, boardMember, contractAPI } from "../../../constants/API";
import toast from "react-hot-toast";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DropDownStyle1 from "../../Elements/DropDownStyle1";
import CheckBoxDropDown1 from "../../Elements/CheckBoxDropDown1";
import { approveProposal } from "../../../constants/Request";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Loading from "../../Elements/Loading";
import { TbHandClick } from "react-icons/tb";
import { duration } from "@mui/material";
import { CiImageOn } from "react-icons/ci";
import { Spinner } from "../../../assets/images";
import { FaUpload } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import InputBoxLive from "../../Elements/InputBoxLive";

const AssignBoardMember = ({
  isOpen,
  onClose,
  proposalId,
  setLoad,
  load,
  onlyAssignBoardMember = false,
  sampleApprovalProposalPdf = "",
  setSampleApprovalProposalPdf,
}) => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const [pageLoading, setPageLoading] = useState(false);
  const [boardMembersOptions, setBoardMembersOptions] = useState([]);
  const [selectedBoardMember, setSelectedBoardMember] = useState([]);
  const [boardMemberCount, setBoardMemberCount] = useState(0);
  const [manualAssignBoardMember, setManualAssignBoardMember] = useState(false);

  const [uploadingFile, setUploadingFile] = useState(false);
  const [approvalProposalPdf, setApprovalProposalPdf] = useState(null);
  const [approvalProposalPdfError, setApprovalProposalPdfError] =
    useState(null);

  const SUPPORTED_APPROVAL_PROPOSAL_PDF_FORMATS = ["application/pdf"];
  const FILE_SIZE_LIMIT_APPROVAL_PROPOSAL_PDF = 5 * 1024 * 1024;

  const handleChangeApprovalProposalPdf = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setApprovalProposalPdfError("Please Upload a Approval Proposal PDF");

      setApprovalProposalPdf(null);
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT_APPROVAL_PROPOSAL_PDF &&
        SUPPORTED_APPROVAL_PROPOSAL_PDF_FORMATS.includes(file.type)
      ) {
        setApprovalProposalPdfError("");
        setApprovalProposalPdf(file);
      } else if (
        file &&
        !SUPPORTED_APPROVAL_PROPOSAL_PDF_FORMATS.includes(file.type)
      ) {
        setApprovalProposalPdfError(
          "Unsupported file type file type must be pdf"
        );

        setApprovalProposalPdf(null);
      } else if (file && !file.size <= FILE_SIZE_LIMIT_APPROVAL_PROPOSAL_PDF) {
        setApprovalProposalPdfError(
          "pdf size exceeds limit. File size must be less than 5 MB"
        );

        setApprovalProposalPdf(null);
      } else {
        setApprovalProposalPdfError(
          "Something went wrong please try again later"
        );

        setApprovalProposalPdf(null);
      }
    }
  };

  const handleManualAssignBM = async (event) => {
    event.preventDefault();
    if (approvalProposalPdfError || !approvalProposalPdf) {
      setApprovalProposalPdfError("Please Upload a Approval Proposal PDF");
      return;
    }
    setLoad(true);

    try {
      const formData = new FormData();
      formData.append("Approval_Proposal_PDF", approvalProposalPdf);

      const res = await axios.post(
        `${baseUrl}/proposal/approvemanual/${proposalId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: cookies.get("token"),
            companyType: "corporation",
          },
        }
      );

      setLoad(false);

      if (res.status === 200) {
        navigate("/corporations/managecontracts");
        toast.success("Proposal Approved successfully", {
          duration: 4000,
        });
      } else {
        toast.error("Failed to Approve Proposal ", {
          duration: 4000,
        });
      }
    } catch (error) {
      toast.error(
        error.response.data.message ||
          "Something went wrong. Please try again later"
      );
    } finally {
      setLoad(false);
    }
  };

  const schema1 = yup.object().shape({
    // BoardMember: yup.string().required("Assign a Board Member is required"),
    BoardMember: yup.array().min(1, "Assign a Board Member is required"),
  });

  const schema2 = yup.object().shape({
    No_Of_Assingned_Board_Members: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === "" ? undefined : value;
      })
      .required("No of Assigned Board Members is required")
      .min(1, "No of Assigned Board Members is Greater than 0"),
  });

  const schema = manualAssignBoardMember ? schema2 : schema1;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useForm({ resolver: yupResolver(schema) });

  const getBoardMembersData = async () => {
    setPageLoading(true);
    try {
      const response = await axios.get(
        `${boardMember}/corporationverifyboardmembers`,
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );

      setBoardMembersOptions(response.data.data);
      console.log("response.data.data", response.data.data);
      setBoardMemberCount(response.data.data.length);
    } catch (error) {
      toast.error(
        error.response.data.message || "Error fetching board members"
      );
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    getBoardMembersData();
  }, []);

  const onAssignBoardMember = async (data) => {
    setLoad(true);
    try {
      // there change the api for assign board member
      if (onlyAssignBoardMember) {
        // if only assign board member is true then send a contract id in proposal id
        setLoad(true);
        const id = proposalId;

        const res = await axios.put(
          `${contractAPI}/assignboardmember/${id}`,
          { Assign_Board_Members_ID: data.BoardMember },
          {
            headers: {
              authorization: cookies.get("token"),
              companyType: cookies.get("companyType"),
            },
          }
        );
        setLoad(false);

        if (res.status === 200) {
          navigate("/corporations/managecontracts");
          toast.success("Board Member assigned successfully", {
            duration: 4000,
          });
        } else {
          toast.error("Failed to assign board member", {
            duration: 4000,
          });
        }
      } else {
        const res = await approveProposal(proposalId, data.BoardMember);
        console.log("res", res);

        if (res.status === 200) {
          toast.success("Board Member assigned successfully", {
            duration: 4000,
          });

          navigate("/corporations/managecontracts");
        } else {
          toast.error("Failed to assign board member", {
            duration: 4000,
          });
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to assign board member", {
        duration: 4000,
      });
    } finally {
      setLoad(false);
      onClose();
    }
  };

  const handleGenerateSamplePDF = async (data) => {
    console.log("data", data);

    setLoad(true);

    console.log(cookies.get("token"));
    console.log(cookies.get("companyType"));

    try {
      const res = await axios.put(
        `${baseUrl}/proposal/approvemanualpdfgenerate/${proposalId}`,
        {
          No_Of_Assingned_Board_Members: data.No_Of_Assingned_Board_Members,
        },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );

      if (res.status === 200) {
        console.log(
          "res.data.data",
          res.data.data.Sample_Approval_Proposal_PDF
        );
        setSampleApprovalProposalPdf(
          res.data.data.Sample_Approval_Proposal_PDF
        );
        toast.success("Pdf Generate successfully", {
          duration: 4000,
        });

        // navigate("/corporations/managecontracts");
      } else {
        toast.error("Failed to Pdf Generate", {
          duration: 4000,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to Pdf Generate", {
        duration: 4000,
      });
    } finally {
      setLoad(false);
    }
  };

  return (
    <div
      className={`fixed inset-0 z-30 overflow-y-auto  bg-gray-500 bg-opacity-50
      ${isOpen ? "block" : "hidden"}
      `}
    >
      {isOpen && <style>{`body { overflow: hidden; }`}</style>}
      <div className="flex items-center mt-10 mb-20 justify-center  pt-4 pb-10 text-center sm:block sm:p-0">
        <div
          className="inline-block w-[90vw] p-1 border-[1px] shadow-lg md:w-[80vw] align-bottom bg-white rounded-lg text-left overflow-hidden  transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className="transition-opacity  flex justify-end right-3"
            aria-hidden="true"
            onClick={onClose}
          >
            <span className="text-sky-500    rounded-sm ">
              <MdCancel className="text-4xl cursor-pointer hover:text-sky-700" />
            </span>
          </div>
          {/* Headiing */}
          <div className="p-3 h-fit">
            <h3
              className="text-3xl underline leading-6 font-medium euclidbold  text-primaryd"
              id="modal-headline"
            >
              Assign Board Member
            </h3>

            <div className="w-full flex mt-3 gap-3 items-center justify-center">
              <input
                type="checkbox"
                id="Mannual_Assign"
                name="Mannual_Assign"
                checked={manualAssignBoardMember}
                onChange={() =>
                  setManualAssignBoardMember(!manualAssignBoardMember)
                }
                className="bg-transparent  text-black h-5 w-7"
              />
              <label htmlFor="Mannual_Assign" className="pt-2">
                Manual Board Member Assign
              </label>
            </div>

            {!pageLoading && !manualAssignBoardMember ? (
              boardMemberCount > 0 ? (
                <form onSubmit={handleSubmit(onAssignBoardMember)}>
                  <div className="w-full flex flex-wrap mt-3 justify-center gap-3 items-center">
                    {/* <DropDownStyle1
                      title={"Select Board Member"}
                      options={boardMembersOptions}
                      onSelect={setSelectedBoardMember}
                      selectOption={selectedBoardMember}
                      setValue={setValue}
                      filedName={"BoardMember"}
                      clearErrors={clearErrors}
                      errors={errors}
                      dbfield={"Board_Member_Name"}
                    ></DropDownStyle1>
                 */}
                    <CheckBoxDropDown1
                      title={"Select Board Member"}
                      options={boardMembersOptions}
                      onSelect={setSelectedBoardMember}
                      selectOption={selectedBoardMember}
                      setValue={setValue}
                      filedName={"BoardMember"}
                      clearErrors={clearErrors}
                      errors={errors}
                      dbfield={"Board_Member_Name"}
                      setError={setError}
                      schema={schema}
                    />

                    {/* <p className="text-sm text-gray-500">
                <span className="font-bold">First Name:</span> f name
              </p> */}

                    <button
                      type="submit"
                      className="h-10 px-4 py-2 mb-5 items-center ring-1 rounded-lg euclidbold ring-cyan-500   text-cyan-500 text-xl  tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:ring-1 hover:ring-cyan-600  focus:outline-none  hover:text-white transition duration-300 ease-in-out mt-4 md:mt-0 flex "
                    >
                      Assign
                    </button>
                  </div>
                </form>
              ) : (
                <div className="w-full h-full items-center">
                  <div className="text-center text-lg text-gray-600 mt-10">
                    Currently, there are no board members available in the
                    corporation. please add a new board member.
                  </div>

                  <div className="mt-3">
                    <Link to="/corporations/profile">
                      <span className="font-bold gap-1 flex items-center justify-center text-indigo-900 hover:text-indigo-950 euclidbold">
                        <TbHandClick /> Create New Board Member
                      </span>
                    </Link>
                  </div>
                </div>
              )
            ) : (
              <div className="w-[90%] mx-auto">
                <form onSubmit={handleSubmit(handleGenerateSamplePDF)}>
                  <div className="grid grid-cols-1 md:grid-cols-2 mt-2  md:gap-5 items-center justify-center">
                    <InputBoxLive
                      type={"number"}
                      title={"No of Assigned Board Members"}
                      register={register}
                      filedName={"No_Of_Assingned_Board_Members"}
                      must={true}
                      errors={errors}
                      className={"md:text-sm lg:text-base"}
                      schema={schema}
                      min={1}
                      setError={setError}
                    />

                    <button className="h-10 px-4 py-2  items-center ring-1 rounded-lg euclidbold ring-cyan-500   text-cyan-500 text-xl w-fit tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:ring-1 hover:ring-cyan-600  focus:outline-none  hover:text-white transition duration-300 ease-in-out  flex cursor-pointer ">
                      Generate PDF
                    </button>
                  </div>
                </form>

                {sampleApprovalProposalPdf && (
                  <>
                    {" "}
                    <div className="border-t-[1px] border-gray-300  w-[80%] my-4 md:mb-4"></div>
                    <NavLink
                      className="h-fit px-4 py-2  items-center ring-1 rounded-lg euclidbold ring-cyan-500   text-cyan-500 text-xl w-fit tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:ring-1 hover:ring-cyan-600  focus:outline-none  hover:text-white transition duration-300 ease-in-out  flex cursor-pointer  "
                      to={sampleApprovalProposalPdf}
                    >
                      Download Approval Proposal PDF
                    </NavLink>
                    <div className="border-t-[1px] border-gray-300  w-[80%] my-4"></div>
                  </>
                )}

                {sampleApprovalProposalPdf && (
                  <form
                    onSubmit={handleManualAssignBM}
                    encType="multipart/form-data"
                  >
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-5 items-center">
                      <div className="lg:col-span-2">
                        <label className={`block font-normal text-base `}>
                          Upload Approval Proposal PDF
                          <span className="text-red-500">*</span>
                        </label>
                        <div className="flex items-center gap-5">
                          <input
                            type="file"
                            id="Approval_Proposal_PDF"
                            name="Approval_Proposal_PDF"
                            className={`hidden ${
                              uploadingFile
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            } `}
                            onChange={handleChangeApprovalProposalPdf}
                            disabled={uploadingFile}
                            accept="application/pdf"
                          />
                          {/* <!-- Profile Photo --> */}
                          <label
                            htmlFor="Approval_Proposal_PDF"
                            className={`h-10  bg-transparent  w-full rounded-sm text-black  px-2  ps-10  mb-2 focus:ring-sky-600 focus:outline-none ${
                              uploadingFile
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }  rounded-sm  focus:ring-sky-600 focus:outline-none focus:border-rose-600 ${
                              approvalProposalPdfError
                                ? "ring-1 ring-red-500"
                                : "ring-1 ring-gray-300"
                            } `}
                            disabled={uploadingFile}
                          >
                            <div className="flex items-center h-full space-x-1">
                              <FaUpload className="h-full w-8 text-cyan-500" />
                              {approvalProposalPdf ? (
                                <span className="line-clamp-1">
                                  {approvalProposalPdf.name}
                                </span>
                              ) : (
                                <span className="text-gray-400 truncate  text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                                  Upload Approval Proposal PDF
                                </span>
                              )}
                            </div>
                          </label>
                        </div>
                        {/* Error */}
                        <div className="h-[3vh] mb-3">
                          {uploadingFile && <Spinner />}
                          {approvalProposalPdfError && (
                            <p className="text-red-500 text-xs leading-none">
                              {approvalProposalPdfError}
                            </p>
                          )}
                        </div>
                      </div>

                      <button
                        // type="submit"
                        className="h-10 px-4 py-2 w-fit ring-1 mb-3 rounded-lg euclidbold ring-cyan-500   text-cyan-500 text-xl  tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:ring-1 hover:ring-cyan-600  focus:outline-none  hover:text-white transition duration-300 ease-in-out text-center flex items-center "
                      >
                        Assign
                      </button>
                    </div>
                  </form>
                )}
              </div>
            )}
          </div>
          {/* Close Button */}
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <div
              onClick={onClose}
              className="w-full inline-flex justify-center cursor-pointer rounded-md border border-transparent shadow-sm px-4 py-2 bg-cyan-500 text-base font-medium text-white hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Close
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignBoardMember;
