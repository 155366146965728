import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";

function ProtectedContractor({ children }) {
  const cookies = new Cookies();
  const companyType = cookies.get("companyType");
  const token = cookies.get("token");

  if (token && companyType === "contractor") {
    return <>{children}</>;
  } else if (token && companyType === "corporation") {
    return <Navigate to="/corporations/managecontracts"></Navigate>;
  } else {
    return <Navigate to="/"></Navigate>;
  }
}

export default ProtectedContractor;
