import React from "react";
import { Location, Star, HalfStar } from "../../../assets/images";
import { ClientImage } from "../../../assets/images";

function ClientReviewCard({ data }) {
  const renderRatingStars = () => {
    const stars = [];
    const fullStars = Math.floor(data.Rating);
    const hasHalfStar = data.rating % 1 !== 0;

    for (let i = 0; i < fullStars; i++) {
      stars.push(<Star key={i} className={"h-6 w-7"} />);
    }

    if (hasHalfStar) {
      stars.push(<HalfStar key={stars.length} className={"h-6 w-7 ml-0"} />);
    }

    return stars;
  };
  return (
    <div className="flex flex-wrap justify-center mt-10 md:mb-20 md:space-x-6 space-y-3 lg:space-y-0  lg:mr-[10%]">
      <div className="space-y-2 lg:w-1/6">
        <div className="flex justify-center">
          <img className="h-32 w-32 rounded-full " src={ClientImage} alt="" />
        </div>
        <div className="euclidbold text-gray-500  ">
          <div className="space-y-2">
            <h1 className="text-2xl flex justify-center truncate ">
              {data.Name}
            </h1>
            <div className="flex  justify-center   text-base space-x-2">
              <Location />
              <p> {data.projectLocation}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-[100%] md:w-[80%] lg:max-w-[75%] shadow-md lg:flex border border-gray-400  lg:border-gray-400 bg-white rounded-3xl p-3 mx-3">
        {/* Card Content */}
        <div className="w-full flex-col justify-start leading-normal">
          <div className="flex space-x-1">{renderRatingStars()}</div>

          {/* Description */}
          <p className="text-base  font-normal text-black mt-3">
            {data.Review_Content}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ClientReviewCard;
