import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout.jsx";
import ContractCard from "./ContractCard.jsx";
import DropDown from "./DropDown.jsx";
import Pagination2 from "../Elements/Pagination2.jsx";
import ContractCardSkeleton from "./ContractCardSkeleton.jsx";
import { Toaster } from "react-hot-toast";

function DisplayContracts({
  title,
  contracts,
  setContracts,
  totalPages,
  currentPage,
  setPage,
  buttonName,
  contractsPageType = "",
  load,
  setLoad,
  selectSortOpportunity,
  setSelectSortOpportunity,
  selectFilterOpportunity,
  setSelectFilterOpportunity,
  sortOpportunities,
  filterOpportunities,
}) {
  return (
    <div>
      <div className=" mt-3 mb-4">
        {/* Heading  */}

        <h1 className=" text-2xl sm:text-3xl md:text-5xl euclidbold justify-center flex text-primaryl mb-8">
          {title}
        </h1>

        {/* Dropdown */}
        <div className="justify-center sm:justify-center  lg:justify-start lg:ml-[7%] lg:pl-28 flex my-3 space-x-5">
          {/* Dropdown Opportunities */}
          <DropDown
            options={sortOpportunities}
            onSelect={setSelectSortOpportunity}
            selectOption={selectSortOpportunity}
          />
          {/* Dropdown Filter  */}
          <DropDown
            options={filterOpportunities}
            onSelect={setSelectFilterOpportunity}
            selectOption={selectFilterOpportunity}
          />
        </div>

        {/* Center hr line */}
        <div className="justify-center flex">
          <div className="border-t border-gray-300 w-5/6"></div>
        </div>
      </div>

      <div className="contracts_listing">
        {load ? (
          <ContractCardSkeleton />
        ) : contracts.length !== 0 ? (
          contracts.map((contract, index) => (
            <ContractCard
              key={index}
              contract={contract}
              buttonName={buttonName}
              contractsPageType={contractsPageType}
            />
          ))
        ) : (
          <div className="text-center text-2xl text-gray-600 mt-10">
            {title} are not available
          </div>
        )}
      </div>

      {!load && (
        <div className="my-5">
          {totalPages > 1 && (
            // <Pagination page={page} setPage={setPage} totalPages={totalPages} />
            <Pagination2
              totalPages={totalPages} // Total number of pages
              currentPage={currentPage} // Current active page
              setPage={setPage}
              setContracts={setContracts}
              setLoad={setLoad}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default DisplayContracts;
