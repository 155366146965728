import React, { useState, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { contractAPI } from "../../../constants/API.js";
import DisplayContracts from "../../../components/Contracts/DisplayContracts.jsx";
import Cookies from "universal-cookie";
import Layout from "../../../components/layout/Layout.jsx";

function ContractListing() {
  const [contracts, setContracts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [load, setLoad] = useState(true);
  const cookies = new Cookies();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectSortOpportunity, setSelectSortOpportunity] = useState("Sort");
  const [selectFilterOpportunity, setSelectFilterOpportunity] =
    useState("Filter");

  const sortOpportunities = [
    { id: 1, label: "Popularity" },
    { id: 2, label: "Newest First" },
    { id: 3, label: "Oldest First" },
    { id: 4, label: "Closing soon" },
    { id: 5, label: "Job Closed" },
  ];

  const filterOpportunities = [
    { id: 1, label: "All" },
    { id: 2, label: "Open" },
    { id: 3, label: "Closed" },
  ];

  const fetchData = useCallback(async () => {
    try {
      setLoad(true);
      const token = cookies.get("token");
      const response = await axios.get(`${contractAPI}/nonapplied`, {
        headers: {
          authorization: token,
          companyType: cookies.get("companyType"),
        },
        params: {
          page: page,
          pageSize: 5,
          sort: selectSortOpportunity,
          filter: selectFilterOpportunity,
        },
      });
      // console.log(response);

      setContracts(response.data.data.contracts);
      setTotalPages(response.data.data.totalPages);
      setCurrentPage(response.data.data.currentPage);
    } catch (error) {
      toast.error("Failed to Fetch Current Opportunities");
    } finally {
      setLoad(false);
    }
  }, [currentPage, page, selectSortOpportunity, selectFilterOpportunity]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Layout>
      <DisplayContracts
        title="Current Opportunities"
        contracts={contracts}
        setContracts={setContracts}
        totalPages={totalPages}
        currentPage={currentPage}
        setPage={setPage}
        buttonName={"APPLY"}
        load={load}
        setLoad={setLoad}
        selectSortOpportunity={selectSortOpportunity}
        setSelectSortOpportunity={setSelectSortOpportunity}
        selectFilterOpportunity={selectFilterOpportunity}
        setSelectFilterOpportunity={setSelectFilterOpportunity}
        sortOpportunities={sortOpportunities}
        filterOpportunities={filterOpportunities}
      />
    </Layout>
  );
}

export default ContractListing;
