import React, { useState, useEffect } from "react";
import { CountDay, Location, ApprovedIcon } from "../../assets/images";
import eye from "../../assets/eye.png";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";

const getDeadlineStatus = (deadline) => {
  const currentDate = new Date();
  const proposalDeadline = new Date(deadline).setHours(23, 59, 59, 999);
  if (proposalDeadline < currentDate) {
    return { status: "Expired", daysLeft: 0 };
  } else {
    const daysLeft = Math.ceil(
      (proposalDeadline - currentDate) / (1000 * 60 * 60 * 24)
    );
    return { status: `${daysLeft} days left!`, daysLeft: daysLeft };
  }
};

function ContractCard({ contract, buttonName, contractsPageType }) {
  const currentDate = new Date();
  const proposalDeadline = new Date(contract.Proposal_Deadline).setHours(
    23,
    59,
    59,
    999
  );
  const endDate = new Date(contract.End_Date).setHours(23, 59, 59, 999);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const companyType = cookies.get("companyType");
  const [daysLeft, setDaysLeft] = useState(0);
  const [deadlineStatus, setDeadlineStatus] = useState({
    status: "",
    className: "",
  });

  const [contractCardImage, setContractCardImage] = useState("");

  useEffect(() => {
    const deadlineAns = getDeadlineStatus(contract.Proposal_Deadline);
    setDeadlineStatus((prev) => ({ ...prev, status: deadlineAns.status }));
    for (let i = 1; i <= 10; i++) {
      if (contract[`Project_Photos_${i}`]) {
        setContractCardImage(contract[`Project_Photos_${i}`] || "");
        break;
      }
    }
    setDaysLeft(deadlineAns.daysLeft);
  }, [contract.Proposal_Deadline]);

  return (
    <div className="w-[90%] sm:w-[75%] lg:max-w-[80%] shadow-md lg:flex border border-gray-400  lg:border-gray-400 bg-white rounded p-4 lg:space-x-10 mx-auto my-3 justify-between">
      {/* Card Images  */}
      <div className="h-full relative lg:w-[20%] lg:pt-1 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center flex justify-center ">
        {contractCardImage !== "" ? (
          <img
            src={contractCardImage}
            alt={contract.Project_Name}
            className="lg:h-32 lg:w-48 "
          />
        ) : (
          "No Image  "
        )}
      </div>
      {/* Card Content */}
      <div className="flex-col relative lg:w-[50%] justify-start leading-normal lg:pr-10 ">
        <div className="space-y-2">
          {/* Title */}
          <div className="text-indigo-900 euclidbold text-xl mb-2  sm:pt-3 md:pt-3 lg:pt-0 ">
            {contract.Project_Name}
          </div>
          {/* Location */}
          <div className="text-sm text-trueGray-500 flex gap-2">
            <Location />

            <p className="text-gray-500">{contract.Project_Location}</p>
          </div>
          {/* Description */}
          <p
            style={{ overflow: "hidden !important" }}
            className=" text-left text-black line-clamp-3 "
          >
            {contract.Project_Description}
          </p>
        </div>
      </div>
      {/* Card Buttons */}
      <div className="py-2 relative w-auto lg:w-[20%] space-y-2">
        <div
          className={` sm:mt-3 md:mt-auto  lg:mt-auto  text-sm text-gray-600 flex 
                  ${companyType === "corporation" ? "lg:ml-0" : "lg:ml-8"} `}
        >
          {companyType === "contractor" && (
            <div className="gap-2 flex">
              <CountDay color={daysLeft >= 1 && daysLeft <= 3 ? "red" : ""} />
              <span className="truncate">{deadlineStatus.status}</span>
            </div>
          )}
        </div>

        {companyType === "contractor" && contractsPageType && (
          <div className="lg:ml-8 gap-2 mt-2  text-sm text-gray-600 flex ">
            {contract.proposal.Status === 2 ? (
              <>
                <ApprovedIcon />
                <p>Approved</p>
              </>
            ) : contract.proposal.Status === 1 ? (
              <div className="items-center flex flex-wrap gap-2">
                <img src={eye} alt="" className="h-4 w-6" />
                <p className="text-primaryl lg:w-32 "> Viewed By Management</p>
              </div>
            ) : (
              ""
            )}
          </div>
        )}

        {companyType === "contractor" && (
          <div
            className={`md:mr-8 ${contractsPageType ? "lg:mt-7" : "lg:mt-14"}`}
          >
            <div
              onClick={() => {
                navigate(`/contractors/detailscontract/${contract.ID}`, {
                  state: contract,
                });
              }}
              className="h-11 w-full px-6 py-2.5 euclidbold items-center  cursor-pointer ring-1 ring-cyan-500 flex justify-center text-cyan-500 text-xl hover:ring-cyan-600 font-bold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white transition duration-300 ease-in-out mt-4 md:mt-0"
            >
              {buttonName}
            </div>
          </div>
        )}

        {companyType === "corporation" && contract.Status === 0 ? (
          <>
            <div className={`md:mr-8 mt-3 }`}>
              <div className="h-11 w-full px-2 py-2.5 euclidbold items-center  cursor-pointer ring-1 ring-cyan-500 flex justify-center text-cyan-500 text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white transition duration-300 ease-in-out mt-4 md:mt-0">
                0 NEW MESSAGES
              </div>
            </div>
            {/* If Requried Edit Option is Open Then Board Member Access Leval Is only view */}
            {/* {cookies.get("access") === "board member" ? (
              ""
              // View Option
            ) 
            : (
              ""
              // Edit Option
            )} */}

            <div className={`md:mr-8 `}>
              <div
                onClick={() => {
                  navigate(`/contractors/detailscontract/${contract.ID}`, {
                    state: contract,
                  });
                }}
                className="h-11 w-full px-6 py-2.5 euclidbold items-center  cursor-pointer ring-1 ring-cyan-500 flex justify-center truncate text-cyan-500 text-sm hover:ring-cyan-600 font-bold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white transition duration-300 ease-in-out mt-3 md:mt-0"
              >
                {buttonName}
              </div>
            </div>
          </>
        ) : (
          // If the contract in Proposal Approval stage
          companyType === "corporation" &&
          contract.Status === 1 &&
          // If the proposal deadline has passed
          (endDate < currentDate ? (
            <>
              <div className={`md:mr-8 mt-3 }`}>
                <div
                  onClick={() => navigate("/corporations/messages")}
                  className="h-11 w-full px-2 py-2.5 euclidbold items-center  cursor-pointer ring-1 ring-cyan-500 flex justify-center text-cyan-500 text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white transition duration-300 ease-in-out mt-4 md:mt-0"
                >
                  MESSAGE
                </div>
              </div>
              <div className={`md:mr-8 `}>
                <div
                  onClick={
                    () => {
                      cookies.get("access") === "board member"
                        ? navigate(
                            `/contractors/detailscontract/${contract.ID}`,
                            {
                              state: contract,
                            }
                          )
                        : navigate(`/corporations/reviews/${contract.ID}`);
                    }
                    // toast.success("section under process")
                  }
                  className="h-11 w-full px-6 py-2.5 euclidbold items-center  cursor-pointer ring-1 ring-cyan-500 flex justify-center truncate text-cyan-500 text-sm hover:ring-cyan-600 font-bold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white transition duration-300 ease-in-out mt-3 md:mt-0"
                >
                  {cookies.get("access") === "board member" ? "VIEW" : "REVIEW"}
                </div>
              </div>
            </>
          ) : (
            // If the proposal deadline has not passed there is not options to review
            <>
              <div className={`md:mr-8 mt-3 }`}>
                <div
                  onClick={() => navigate("/corporations/messages")}
                  className="h-11 w-full px-2 py-2.5 euclidbold items-center  cursor-pointer ring-1 ring-cyan-500 flex justify-center text-cyan-500 text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white transition duration-300 ease-in-out mt-4 md:mt-0"
                >
                  MESSAGE
                </div>
              </div>
              {/* Here Change Page for the view sepcific proposal there if i am selected */}
              <div className={`md:mr-8 `}>
                <div
                  onClick={() => {
                    navigate(`/contractors/detailscontract/${contract.ID}`, {
                      state: contract,
                    });
                  }}
                  className="h-11 w-full px-6 py-2.5 euclidbold items-center  cursor-pointer ring-1 ring-cyan-500 flex justify-center truncate text-cyan-500 text-sm hover:ring-cyan-600 font-bold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white transition duration-300 ease-in-out mt-3 md:mt-0"
                >
                  {buttonName}
                </div>
              </div>
            </>
          ))
        )}
      </div>
    </div>
  );
}
export default ContractCard;
