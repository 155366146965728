import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

const BlogSkeleton = () => {
  return (
    <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 sm:px-3 md:px-5 lg:px-10 justify-center">
      {Array(8)
        .fill(0)
        .map((_, i) => (
          <div
            key={i}
            className="w-full  rounded overflow-hidden shadow-lg ring-1 ring-gray-200 my-2 px-4 py-2"
          >
            <Skeleton width="100%" height={192} />
            <div className="pt-4 pb-2">
              <Skeleton width="80%" height={24} />
            </div>
            <div className="pt-1 pb-2 flex justify-between items-center">
              <Skeleton width={200} height={32} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default BlogSkeleton;
