import React, { useState, useEffect } from "react";
import Layout from "../../../components/layout/Layout.jsx";
import ContractDetailsCard from "../../../components/ContractDetalis/ContractDetailsCard.jsx";
import { useParams, useLocation } from "react-router-dom";
import ContractDetailsCardSkeleton from "../../../components/ContractDetalis/ContractDetailsCardSkeleton.jsx";
import Cookies from "universal-cookie";
import ProposalForm from "../../../components/ContractDetalis/ProposalForm.jsx";
import ContractorProposalView from "../../../components/ContractDetalis/ContractorProposalView.jsx";
import { LogoWithoutText } from "../../../assets/index.jsx";
import Loading from "../../../components/Elements/Loading.jsx";

function DetailsContractListing() {
  const location = useLocation();
  const cookies = new Cookies();
  const [contracts, setContracts] = useState([]);
  const { id } = useParams();
  const [notFound, setNotFound] = useState(false);

  const [isExpired, setIsExpired] = useState(false);
  const [load, setLoad] = useState(false);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const projectDeadline = new Date(location.state.Proposal_Deadline).setHours(
      23,
      59,
      59,
      999
    );
    const currentDate = new Date();
    // console.log(currentDate, location.state.Proposal_Deadline);
    setIsExpired(projectDeadline < currentDate);
    // console.log(projectDeadline < currentDate);
    setStatus(cookies.get("status"));
  }, []);

  return (
    <Layout>
      <Loading load={load} />
      {load ? (
        <ContractDetailsCardSkeleton />
      ) : notFound ? (
        <div className="text-center text-2xl text-gray-600 mt-10">
          Contract Not Found
        </div>
      ) : (
        <div className="w-[80%] lg:max-w-[80%] py-8 shadow-lg  border border-gray-400   lg:border-gray-400 bg-white rounded p-3 lg:space-x-10 mx-auto my-4">
          <ContractDetailsCard contracts={location.state.contract} />
          {!location.state.proposal &&
            (isExpired ? (
              <div className="w-[93%] lg:max-w-[100%] mx-auto px-4 pt-10 sm:px-6 lg:px-8">
                <div className="flex-1">
                  <p className="text-2xl text-indigo-900  euclidbold py-3 ">
                    Proposal
                  </p>
                  {/* Horizontal line */}
                  <hr className=" border-gray-300" />
                </div>

                <div className="mt-4">
                  <p className="text-lg text-gray-600">
                    Proposal submission is closed for this project.
                  </p>
                </div>
              </div>
            ) : (
              !location.state.proposal &&
              (status !== 2 ? (
                <ProposalForm
                  setLoad={setLoad}
                  contractId={location.state.ID}
                />
              ) : (
                <div className="w-[93%] lg:max-w-[100%] mx-auto px-4 pt-10 sm:px-6 lg:px-8">
                  <div className="flex-1">
                    <p className="text-2xl text-indigo-900  euclidbold py-3 ">
                      Proposal
                    </p>
                    {/* Horizontal line */}
                    <hr className=" border-gray-300" />
                  </div>

                  <div className="mt-4">
                    <p className="text-lg text-gray-600">
                      Your Profile is Paused by Admin. You can't submit
                      proposal.
                    </p>
                  </div>
                </div>
              ))
            ))}

          {location.state.proposal &&
          Object.keys(location.state.proposal).length > 0 ? (
            <ContractorProposalView contract={location.state} load={load} />
          ) : (
            location.state.proposal && (
              <div className="w-[93%] lg:max-w-[100%] mx-auto px-4 pt-10 sm:px-6 lg:px-8">
                <div className="flex-1">
                  <p className="text-2xl text-indigo-900  euclidbold py-3 ">
                    Proposal
                  </p>
                  {/* Horizontal line */}
                  <hr className=" border-gray-300" />
                </div>

                <div className="mt-4">
                  <p className="text-lg text-gray-600">
                    Proposal submission is closed for this project.
                  </p>
                </div>
              </div>
            )
          )}
        </div>
      )}
    </Layout>
  );
}

export default DetailsContractListing;
