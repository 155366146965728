import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";

function ProtectedContractorOrCorporation({ children }) {
  const cookies = new Cookies();
  const companyType = cookies.get("companyType");
  const token = cookies.get("token");

  if (
    token &&
    (companyType === "contractor" || companyType === "corporation")
  ) {
    return <>{children}</>;
  } else {
    return <Navigate to="/"></Navigate>;
  }
}

export default ProtectedContractorOrCorporation;
