import React from "react";
import Layout from "../../../components/layout/Layout.jsx";
import ProfilePage from "../../../components/Contractor/Profile/ProfilePage.jsx";

function ContractorProfile() {
  return (
    <Layout>
     <ProfilePage/>
    </Layout>
  );
}

export default ContractorProfile;
