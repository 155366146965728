import React from "react";
import Skeleton from "react-loading-skeleton";

function ClientReviewCardSkeleton({ cards = 5 }) {
  return Array(cards)
    .fill(0)
    .map((item, i) => (
      <div
        key={i}
        className="flex flex-wrap justify-center mt-10 md:mb-20 md:space-x-6 space-y-3 lg:space-y-0  lg:mr-[10%]"
      >
        <div className="space-y-2 lg:w-1/6">
          <div className="flex justify-center">
            <Skeleton circle={true} height={150} width={150} />
            {/* <img className="h-32 w-32 rounded-full " src={ClientImage} alt="" /> */}
          </div>
          <div className="">
            <div className="space-y-2">
              <h1 className="text-2xl  justify-center truncate ">
                <Skeleton />
              </h1>
              <div className="mx-auto text-base space-x-2 w-2/3">
                <Skeleton />
              </div>
            </div>
          </div>
        </div>

        <div className="w-[100%] md:w-[80%] lg:max-w-[75%] shadow-md lg:flex border border-gray-400  lg:border-gray-400 bg-white rounded-3xl p-3 mx-3">
          {/* Card Content */}
          <div className="w-full flex-col justify-start ">
            <div className="w-1/2 text-4xl">
              <Skeleton />
            </div>

            {/* Description */}
            <p className="text-base mt-3">
              <Skeleton count={3} />
            </p>
          </div>
        </div>
      </div>
    ));
}

export default ClientReviewCardSkeleton;
