import React, { useEffect, useState } from "react";
import { FaCalendarDays } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { blogAPI } from "../../constants/API";
import axios from "axios";
import Layout from "../../components/layout/Layout";
import BlogDetailsSkeleton from "./BlogDetailsSkeleton";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

const BlogDetails = () => {
  const location = useLocation();
  const [blogSlug, setBlogSlug] = useState(
    location.pathname.split("/blog/")[1]
  );
  const [blogData, setBlogData] = useState(null);
  const [load, setLoad] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const fetchBlogData = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${blogAPI}/slug/${blogSlug}`);
      console.log(response.data.data);

      const rawContent = response.data.data.Blog_Description;
      const contentState = convertFromRaw(JSON.parse(rawContent));
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);

      setBlogData(response.data.data);
    } catch (error) {
      console.log("Error:", error.response);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    setBlogSlug(location.pathname.split("/blog/")[1]);
    fetchBlogData();
  }, [blogSlug]);

  const dateFormate = (date) => {
    const originalDate = new Date(date);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = originalDate.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  return (
    <Layout>
      {load ? (
        <BlogDetailsSkeleton />
      ) : (
        <div className="flex  justify-center h-full">
          <div className="min-w-[65%] sm:max-w-[80%] md:max-w-[70%] lg:max-w-[65%] rounded overflow-hidden shadow-lg ring-1 ring-gray-200 m-4 p-4">
            {blogData ? (
              <div className="pb-3">
                <div>
                  <div className="euclidbold text-3xl">
                    {blogData.Blog_Title}
                  </div>

                  <p className="text-gray-400 text-sm flex items-center gap-2 py-3">
                    <FaCalendarDays />
                    {dateFormate(blogData.Blog_Publish_Date)}
                  </p>
                </div>
                <img
                  className="w-full h-96 object-fill"
                  src={blogData.Blog_Image}
                  alt="Sunset in the mountains"
                />

                <div className="pt-3 pb-2 text-justify ">
                  <div>
                    {" "}
                    <Editor
                      editorState={editorState}
                      readOnly
                      toolbarHidden
                    />{" "}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <h1 className="text-3xl euclidbold text-gray-500 text-center">
                  Blog Not Found
                </h1>
              </div>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default BlogDetails;
