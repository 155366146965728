import React, { useEffect, useState } from "react";
import ReadInputBox from "../Elements/ReadInputBox";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { set, useForm } from "react-hook-form";
import { MdAssignmentInd } from "react-icons/md";
import AssignBoardMember from "../Corporation/BoardMember/AssignBoardMember";
import { TbHandClick } from "react-icons/tb";
import Cookies from "universal-cookie";

function AssignBoardMemberDetails({ contract, load, setLoad }) {
  const cookies = new Cookies();
  const [expriedContractEndDate, setExpriedContractEndDate] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [contractId, setContractId] = useState(null);
  const [boardMembersCount, setBoardMembersCount] = useState(0);

  useEffect(() => {
    setLoad(true);
    if (contract.CpContractAssignedToBoardMembers !== null) {
      contract.CpContractAssignedToBoardMembers.forEach(
        (boardMember, index) => {
          setValue(
            `BoardMembers[${index}][Board_Member_Name]`,
            boardMember["BoardMember"]["Board_Member_Name"]
          );
          setValue(
            `BoardMembers[${index}][Board_Member_Phone_No]`,
            boardMember["BoardMember"]["Board_Member_Phone_No"]
          );
          setValue(
            `BoardMembers[${index}][Board_Member_Email]`,
            boardMember["BoardMember"]["Board_Member_Email"]
          );
          setValue(
            `BoardMembers[${index}][Designation]`,
            boardMember["BoardMember"]["Designation"]
          );
        }
      );
    }

    const projectEndData = new Date(contract.End_Date);
    const currentDate = new Date();
    setExpriedContractEndDate(projectEndData < currentDate);
    setContractId(contract.ID);
    setBoardMembersCount(contract.CpContractAssignedToBoardMembers.length || 0);
    setLoad(false);
  }, [contract]);

  const schema = yup.object().shape({
    BoardMembers: yup.array().of(
      yup.object().shape({
        Board_Member_Name: yup.string(),
        Board_Member_Phone_No: yup.string(),
        Board_Member_Email: yup.string(),
        Designation: yup.string(),
      })
    ),
  });

  const { register, setValue } = useForm({ resolver: yupResolver(schema) });

  const handleOpenModel = () => {
    setIsModelOpen(true);
  };

  const handleCloseModel = () => {
    setIsModelOpen(false);
  };

  return (
    <div>
      <label
        className={`flex gap-2 items-center font-normal text-xl underline text-indigo-900  euclidbold mb-3`}
      >
        <MdAssignmentInd /> Assign Board Member Details :
      </label>
      {load ? (
        "Loading..."
      ) : contract?.proposal?.Mannual_Approval_Proposal ? (
        <div className="text-lg text-gray-600 mb-3">
          This contract has been signed manualy.
        </div>
      ) : boardMembersCount > 0 ? (
        <div>
          {Array.from({ length: boardMembersCount }).map((_, index) => (
            <div key={index}>
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-20">
                <ReadInputBox
                  title={"Board Member Name"}
                  register={register}
                  filedName={`BoardMembers[${index}].Board_Member_Name`}
                />
                <ReadInputBox
                  title={"Phone No"}
                  register={register}
                  filedName={`BoardMembers[${index}].Board_Member_Phone_No`}
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-20">
                <ReadInputBox
                  title={"Board Member Email"}
                  register={register}
                  filedName={`BoardMembers[${index}].Board_Member_Email`}
                />
                <ReadInputBox
                  title={"Board Member Designation"}
                  register={register}
                  filedName={`BoardMembers[${index}].Designation`}
                />
              </div>
              <div className="border-b-2 mb-4"></div>
            </div>
          ))}
        </div>
      ) : expriedContractEndDate ? (
        <div className="text-lg text-gray-600 mb-3">
          Project End Date has been expired.
        </div>
      ) : (
        <div className="mb-3">
          <div className="text-lg text-gray-600">
            Corporation have deleted the assigned board member details for this
            contract.
            {cookies.get("access") !== "board member" &&
              "Please assign a board member."}
          </div>

          {cookies.get("access") !== "board member" && (
            <div>
              <span
                className="font-bold gap-1 flex text-lg w-fit items-center justify-start text-indigo-900 hover:text-indigo-950 euclidbold mt-2 cursor-pointer"
                onClick={handleOpenModel}
              >
                <TbHandClick /> Assign A Board Member
              </span>

              <AssignBoardMember
                isOpen={isModelOpen}
                onClose={handleCloseModel}
                proposalId={contractId}
                setLoad={setLoad}
                load={load}
                onlyAssignBoardMember={true}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AssignBoardMemberDetails;
