import React, { useEffect, useState } from "react";
import { set } from "react-hook-form";

function InputBoxLiveDate({
  title,
  register,
  filedName,
  must,
  errors,
  placeHolder = "",
  className,
  type = "text",
  width = "w-full",
  min,
  max,
  schema,
  setError,
  disabled = false,
  otherDependencies = "",
  setMinEndDate = "",
  setMaxProposalDeadline = "",
  setSelectDate = "",
  setValue = "",
  changeMaxProposalDeadline,
  changeMinEndDate,
}) {
  const [touched, setTouched] = useState(false);

  const handleKeyUp = (event) => {
    if (touched) {
      const value = event.target.value;

      schema
        .validateAt(filedName, { [filedName]: value }) // Validate the field
        .then((valid) => {
          // If the field is valid, remove the error message
          if (errors[filedName]) {
            delete errors[filedName];
          }
          setTouched(false);
        })
        .catch((err) => {
          // If the field is invalid, add the error message
          setError(filedName, { type: "manual", message: err.errors[0] });
          setTouched(true);
        })
        .finally(() => {});
    }
  };

  const handleInput = (event) => {
    const value = event.target.value;

    if (value === "") {
      setMinEndDate("");
      setMaxProposalDeadline("");
      setSelectDate(true);
      setValue("endDate", "");
      setValue("proposalDeadline", "");
    } else {
      setSelectDate(false);
      if (changeMinEndDate < value) {
        setValue("End_Date", "");
      }

      setMinEndDate(
        new Date(new Date(value).getTime() + 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0]
      );

      if (changeMaxProposalDeadline > value) {
        setValue("Proposal_Deadline", "");
      }

      setMaxProposalDeadline(
        new Date(new Date(value).getTime() - 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0]
      );
    }
  };

  const handleBlur = (event) => {
    const value = event.target.value;

    if (!value == "") {
      schema
        .validateAt(filedName, { [filedName]: value }) // Validate the field
        .then((valid) => {
          // If the field is valid, remove the error message
          if (errors[filedName]) {
            delete errors[filedName];
          }
          setTouched(false);
        })
        .catch((err) => {
          // If the field is invalid, add the error message
          setError(filedName, { type: "manual", message: err.errors[0] });
          setTouched(true);
        })
        .finally(() => {});
    }
  };

  return (
    <div className="mb-3">
      {/* Label */}
      <label
        htmlFor={filedName}
        className={`block font-normal  
  }`}
      >
        <p>
          {title}{" "}
          {must ? <span className="text-red-500 text-base">*</span> : ""}
        </p>
      </label>
      {/* Input */}
      <input
        type={type}
        id={filedName}
        name={filedName}
        {...register(filedName)}
        pattern="\d{4}-\d{2}-\d{2}"
        className={` block  h-10  ${width}  rounded-sm text-black  ring-1 px-2 ring-gray-400 ps-10 focus:ring-sky-600 focus:outline-none focus:border-rose-600 ${
          errors[filedName] ? "ring-1 ring-red-500" : ""
        } ${className} ${disabled ? "bg-gray-200" : "bg-transparent"}`}
        {...(placeHolder
          ? { placeholder: placeHolder }
          : { placeholder: `Type ${title}` })}
        min={min}
        max={max}
        onKeyUp={handleKeyUp}
        onInput={otherDependencies ? handleInput : null}
        onBlur={handleBlur}
        disabled={disabled}

        // Conditionally attach the event listener
      />

      {/* Error */}
      <div className="h-[2vh] pt-1">
        {errors[filedName] && (
          <p className="text-red-500 text-xs leading-none">
            {errors[filedName].message}
          </p>
        )}
      </div>
    </div>
  );
}

export default InputBoxLiveDate;
