import React from "react";
import ClientReviewCard from "./ClientReviewCard";
import Skeleton from "react-loading-skeleton";
import ClientReviewCardSkeleton from "./ClientReviewCardSkeleton";

function ClientReview({ data, load }) {
  return (
    <div className="lg:w-4/6 lg:pl-[1%]">
      <div className="pl-5">
        <h1 className="text-3xl md:text-5xl text-[#0094BF] euclidbold">
          Client Reviews
        </h1>
        <hr className="w-5/6 border-t my-3 border-gray-500"></hr>
      </div>

      {load ? (
        <ClientReviewCardSkeleton cards={3} />
      ) : data.length > 0 ? (
        data.map((item, index) => <ClientReviewCard data={item} key={index} />)
      ) : (
        <label className="text-center w-full mt-3">
          No Reviews Available at the moment
        </label>
      )}
    </div>
  );
}

export default ClientReview;
