import Layout from "../../../components/layout/Layout";
import Login from "../../../components/Login/Login";

function BoardMemberLogin() {
  return (
    <div className="overflow-hidden">
      <Layout>
        <Login loginRoleText={"Board members Login with Email ID"} requiredRegister={false} loginFieldName={"Email Id"} forgotField={false} />
      </Layout>
    </div>
  );
}

export default BoardMemberLogin;
