import React, { useState } from "react";
import { ContractorProfileLeftImage } from "../../../assets/images";
import ContractorsSignupForm from "./ContractorSignupForm";
import Loading from "../../Elements/Loading";
import Test from "../../../pages/Test";

function Signup() {
  const [load, setLoad] = useState(false);

  return (
    <div className="overflow-hidden overflow-x-hidden">
      <Loading load={load} />
      <div className="h-full w-full flex relative ">
        {/* First div : images */}
        <div className="w-[70%] hidden md:block ">
          <img
            src={ContractorProfileLeftImage}
            className="absolute top-0 bottom-0 w-[40%] h-full object-cover"
            alt="Singup background"
          />
        </div>
        <div className="absolute hidden bottom-[-1%] left-0 w-full md:w-[40%] h-[40%] bg-gradient-to-b from-transparent to-[#0893bd]  md:block"></div>
        {/* Secound Div */}

        <ContractorsSignupForm setLoad={setLoad} />
      </div>
    </div>
  );
}

export default Signup;
