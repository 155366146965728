import React, { useEffect, useState } from "react";
import ManageCorporations from "../../components/admin/ManageCorporations";
import ManageContractors from "../../components/admin/ManageContractors";
import { LogoWithoutText } from "../../assets";
import { Footer, Header } from "../../components";
import {
  baseUrl,
  blogAPI,
  contractorAPI,
  corporationAPI,
} from "../../constants/API";
import axios from "axios";
import Cookies from "universal-cookie";
import Home from "../../components/admin/Home";
import ManageEnquiry from "../../components/admin/ManageEnquiry";
import toast from "react-hot-toast";
import { getCityArea, getServies } from "../../constants/Request";
import ManageServices from "../../components/admin/ManageServices";
import ManageWorkingArea from "../../components/admin/ManageWorkingArea";
import ManageBlog from "../../components/admin/ManageBlog";
import CreateBlog from "../../components/admin/Blog/CreateBlog";
import EditBlog from "../../components/admin/Blog/EditBlog";

function Admin() {
  const [activeTab, setActiveTab] = useState("Home"); // Set initial active tab to Home
  const [load, setLoad] = useState(false);
  const [corporationCount, setCorporationCount] = useState(0);
  const [contractorCount, setContractorCount] = useState(0);
  const [contractCount, setContractCount] = useState(0);
  const [enquiryCount, setEnquiryCount] = useState(0);
  const [serviceCount, setServiceCount] = useState(0); // Added service count state
  const [workingAreaCount, setWorkingAreaCount] = useState(0); // Added working area count state
  const [blogCount, setBlogCount] = useState(0); // Added blog count state
  const [corporations, setCorporations] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [contract, setContract] = useState([]);
  const [enquiries, setEnquiries] = useState([]);
  const [services, setServices] = useState([]); // Added services state
  const [workingarea, setWorkingArea] = useState([]); // Added working area state
  const [blogs, setBlogs] = useState([]); // Added blog state
  const [editBlogData, setEditBlogData] = useState(false);

  const cookies = new Cookies();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const fetchCorporationData = async () => {
    setLoad(true);
    try {
      const response = await axios.get(corporationAPI, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "admin",
        },
      });
      setCorporations(response.data.corporations);
      setCorporationCount(response.data.corporations.length);
    } catch (error) {
      console.error("Error fetching corporation data:", error);
    } finally {
      setLoad(false);
    }
  };

  const fetchContractorData = async () => {
    setLoad(true);
    try {
      const response = await axios.get(contractorAPI, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "admin",
        },
      });
      setContractors(response.data.contractors);
      setContractorCount(response.data.contractors.length);
    } catch (error) {
      console.error("Error fetching contractor data:", error);
    } finally {
      setLoad(false);
    }
  };

  const fetchContractData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/contract/allcontracts`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "admin",
        },
      });
      setContract(response.data.data);
      setContractCount(response.data.data.length);
    } catch (error) {
      toast.error("Error fetching contract data:");
      console.error(error);
    }
  };

  const fetchEnquiryData = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${baseUrl}/contactus`);
      // console.log(response.data.data);
      setEnquiries(response.data.data);
      setEnquiryCount(response.data.data.length);
    } catch (error) {
      console.error("Error fetching enquiry data:", error);
    } finally {
      setLoad(false);
    }
  };
  const fetchServiceData = async () => {
    setLoad(true);
    try {
      const response = await getServies();
      setServices(response);
      setServiceCount(response.length);
    } catch (error) {
      console.error("Error fetching enquiry data:", error);
    } finally {
      setLoad(false);
    }
  };

  const fetchWorkingAreaData = async () => {
    setLoad(true);
    try {
      const response = await getCityArea();
      setWorkingArea(response);
      setWorkingAreaCount(response.length);
    } catch (error) {
      console.error("Error fetching working area data:", error);
    } finally {
      setLoad(false);
    }
  };

  const fetchBlogData = async () => {
    setLoad(true);
    try {
      const response = await axios.get(blogAPI);
      setBlogs(response.data.data.reverse());
      setBlogCount(response.data.data.length);
    } catch (error) {
      console.error("Error fetching working area data:", error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    setLoad(true); // Start loading
    let count = 7; // Counter for pending API calls
    const fetchData = async () => {
      await Promise.all([
        fetchCorporationData(),
        fetchContractorData(),
        fetchContractData(),
        fetchEnquiryData(),
        fetchServiceData(),
        fetchWorkingAreaData(),
        fetchBlogData(),
      ]);
      count--;
      if (count === 0) {
        setLoad(false); // End loading when all API calls are complete
      }
    };
    fetchData();
  }, []);

  return (
    <div className="h-screen overflow-hidden">
      {load && (
        <div className="absolute z-50 flex items-center justify-center h-full w-full bg-black opacity-55">
          <div className="loginLoader"></div>
          <img
            src={LogoWithoutText}
            className="lazy_image absolute"
            height={100}
            width={100}
            alt=""
          />
        </div>
      )}
      <Header />

      <div className="flex h-[76.9vh]">
        {/* Sidebar */}
        <div className="w-1/5 h-auto bg-gray-200">
          <div className="flex flex-col justify-center gap-2">
            {/* Updated buttons to switch between Home, Manage Corporation, Manage Contractors, and Manage Enquiry */}
            <button
              className={`sm:px-4 py-2 p-1 text-[10px] sm:text-base rounded hover:bg-cyan-600 hover:text-white ${
                activeTab === "Home" ? "bg-cyan-600 text-white" : "bg-gray-300"
              }`}
              onClick={() => handleTabClick("Home")}
            >
              Home
            </button>
            <button
              className={`sm:px-4 py-2 p-1 text-[10px] sm:text-base rounded hover:bg-cyan-600 hover:text-white ${
                activeTab === "Manage Corporation"
                  ? "bg-cyan-600 text-white"
                  : "bg-gray-300"
              } `}
              onClick={() => handleTabClick("Manage Corporation")}
            >
              Manage Corporation {`(${corporationCount})`}
            </button>
            <button
              className={`sm:px-4 py-2 p-1 text-[10px] sm:text-base rounded hover:bg-cyan-600 hover:text-white ${
                activeTab === "Manage Contractors"
                  ? "bg-cyan-600 text-white"
                  : "bg-gray-300"
              } `}
              onClick={() => handleTabClick("Manage Contractors")}
            >
              Manage Contractors {`(${contractorCount})`}
            </button>
            <button
              className={`sm:px-4 py-2 p-1 text-[10px]  sm:text-base rounded hover:bg-cyan-600 hover:text-white ${
                activeTab === "Manage Enquiry"
                  ? "bg-cyan-600 text-white"
                  : "bg-gray-300"
              }`}
              onClick={() => handleTabClick("Manage Enquiry")}
            >
              Manage Enquiry {`(${enquiryCount})`}
            </button>
            <button
              className={`sm:px-4 py-2 p-1 text-[10px]  sm:text-base rounded hover:bg-cyan-600  hover:text-white ${
                activeTab === "Manage Services"
                  ? "bg-cyan-600 text-white"
                  : "bg-gray-300"
              }`}
              onClick={() => handleTabClick("Manage Services")}
            >
              Manage Services {`(${serviceCount})`}
            </button>
            <button
              className={`sm:px-4 py-2 p-1 text-[10px]  sm:text-base rounded hover:bg-cyan-600  hover:text-white ${
                activeTab === "Manage Working Area"
                  ? "bg-cyan-600 text-white"
                  : "bg-gray-300"
              }`}
              onClick={() => handleTabClick("Manage Working Area")}
            >
              Manage Working Area {`(${workingAreaCount})`}
            </button>
            <button
              className={`sm:px-4 py-2 p-1 text-[10px]  sm:text-base rounded hover:bg-cyan-600  hover:text-white ${
                activeTab === "Manage Blog"
                  ? "bg-cyan-600 text-white"
                  : "bg-gray-300"
              }`}
              onClick={() => handleTabClick("Manage Blog")}
            >
              Manage Blog {`(${blogCount})`}
            </button>
          </div>
        </div>
        {/* Main content */}
        <div className="w-full z-0 relative">
          <div className=" overflow-auto p-4">
            {/* Conditional rendering based on active tab */}
            {activeTab === "Home" && (
              <Home
                corporationCount={corporationCount}
                contractorCount={contractorCount}
                contractCount={contractCount}
                enquiryCount={enquiryCount}
                corporations={corporations}
                contractors={contractors}
                contract={contract}
                enquiries={enquiries}
                setLoad={setLoad}
              />
            )}
            {activeTab === "Manage Corporation" && (
              <ManageCorporations setLoad={setLoad} />
            )}
            {activeTab === "Manage Contractors" && (
              <ManageContractors setLoad={setLoad} />
            )}
            {activeTab === "Manage Enquiry" && (
              <ManageEnquiry
                setEnquiries={setEnquiries}
                enquiries={enquiries}
                setLoad={setLoad}
              />
            )}
            {activeTab === "Manage Services" && (
              <ManageServices
                setServices={setServices}
                setServiceCount={setServiceCount}
                services={services}
                setLoad={setLoad}
              />
            )}
            {activeTab === "Manage Working Area" && (
              <ManageWorkingArea
                setAreas={setWorkingArea}
                setAreasCount={setWorkingAreaCount}
                areas={workingarea}
                setLoad={setLoad}
              />
            )}
            {activeTab === "Manage Blog" && (
              <ManageBlog
                setBlogs={setBlogs}
                setBlogCount={setBlogCount}
                blogs={blogs}
                setLoad={setLoad}
                setActiveTab={setActiveTab}
                setEditBlogData={setEditBlogData}
                fetchBlogData={fetchBlogData}
              />
            )}
            {activeTab === "Create Blog" && (
              <CreateBlog
                setLoad={setLoad}
                setActiveTab={setActiveTab}
                fetchBlogData={fetchBlogData}
              />
            )}
            {activeTab === "Edit Blog" && (
              <EditBlog
                setLoad={setLoad}
                setActiveTab={setActiveTab}
                blog={editBlogData}
                fetchBlogData={fetchBlogData}
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Admin;
