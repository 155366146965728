import ContractorsSignup from "./pages/contractors/signup/ContractorsSignup";
import DetailsContractListing from "./pages/contractors/contract/DetailsContractListing";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CorporationForm from "./pages/corporations/signup/Signup";
import ContractListing from "./pages/contractors/contract/ContractListing";
import ContractorLogin from "./pages/contractors/login/ContractorLogin";
import CorporationLogin from "./pages/corporations/login/CorporationLogin";
import BoardMemberLogin from "./pages/corporations/login/BoardMemberLogin";
import AppliedContracts from "./pages/contractors/contract/AppliedContracts";
import ProtectedContractor from "./pages/Protected/ProtectedContractor";
import Home from "./pages/Home/Home";
import ContractorProfile from "./pages/contractors/profile/ContractorProfile";
import CorporationProfile from "./pages/corporations/profile/CorporationProfile";
import ContractorReviews from "./pages/contractors/Reviews/ContractorReviews";
import Landing from "./pages/corporations/Landing";
import Contracts from "./pages/contracts/Contracts";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Messages from "./pages/Messages/Messages";
import ManageContracts from "./pages/corporations/contract/ManageContracts";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import ProtectedCorporation from "./pages/Protected/ProtectedCorporation";
import Unprotected from "./pages/Unprotected";
import Admin from "./pages/admin/Admin";
import Dashboard from "./pages/corporations/dashboard/Dashboard";
import AdminLogin from "./pages/admin/AdminLogin";
import ProposalView from "./components/ContractDetalis/ProposalView";
import EditContract from "./pages/contracts/EditContract";
import SendMail from "./pages/Reset/Sendmail";
import ResetPassword from "./pages/Reset/ResetPassword";
import SetReviews from "./pages/corporations/Reviews/SetReviews";
import SavePassword from "./pages/Reset/SavePassword";
import ProtectedAdmin from "./pages/Protected/ProtectedAdmin";
import ProtectedContractorOrCorporation from "./pages/Protected/ProtectedContractorOrCorporation";
import VerifyBoardMember from "./pages/corporations/boardmember/VerifyBoardMember";
import ContractorMessages from "./pages/Messages/ContractorMessages";
import TermsAndCondition from "./pages/TermsAndPrivacy/TermsAndCondition";
import PrivacyPolicy from "./pages/TermsAndPrivacy/PrivacyPolicy";
import AdminProfile from "./pages/admin/AdminProfile";
import "./constants/CSS.css";
import "./styles.css";
import ApprovedContracts from "./pages/contractors/contract/ApprovedContracts";
import Test from "./pages/Test";
import Test2 from "./pages/Test2";
import Blog from "./pages/Blog/Blog";
import BlogDetails from "./pages/Blog/BlogDetails";
import CreateBlog from "./components/admin/Blog/CreateBlog";
import EditBlog from "./components/admin/Blog/EditBlog";
import Owners from "./pages/Owners/Owners";
import OwnersSignUp from "./pages/Owners/signup/OwnersSignUp";
import Tenant from "./pages/Tenant/Tenant";
import TenantSignUp from "./pages/Tenant/signup/TenantSignup";
import CreateTenant from "./pages/corporations/Residents/CreateTenant";
import CreateOwners from "./pages/corporations/Residents/CreateOwners";
import Test3 from "./pages/Test3";
import Test4 from "./pages/Test4";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  { path: "/corporations", element: <Landing /> },
  {
    path: "/corporations/login",
    element: (
      <Unprotected>
        <CorporationLogin />
      </Unprotected>
    ),
  },
  {
    path: "/corporations/boardmember/login",
    element: (
      <Unprotected>
        <BoardMemberLogin />
      </Unprotected>
    ),
  },
  {
    path: "/contractors/login",
    element: (
      <Unprotected>
        <ContractorLogin />
      </Unprotected>
    ),
  },
  // {
  //   path: "/owners/signup",
  //   element: (
  //     <Unprotected>
  //       <OwnersSignUp />
  //     </Unprotected>
  //   ),
  // },
  // {
  //   path: "/tenant/signup",
  //   element: (
  //     <Unprotected>
  //       <TenantSignUp />
  //     </Unprotected>
  //   ),
  // },
  // {
  //   path: "/owners/login",
  //   element: (
  //     <Unprotected>
  //       <Owners />
  //     </Unprotected>
  //   ),
  // },
  // {
  //   path: "/tenant/login",
  //   element: (
  //     <Unprotected>
  //       <Tenant />
  //     </Unprotected>
  //   ),
  // },
  {
    path: "/corporations/signup",
    element: (
      <Unprotected>
        <CorporationForm />
      </Unprotected>
    ),
  },
  {
    path: "/contractors/signup",
    element: (
      <Unprotected>
        <ContractorsSignup />
      </Unprotected>
    ),
  },

  { path: "/corporations/reset", element: <SendMail /> },
  {
    path: "/corporations/propertymanager/reset",
    element: <SendMail />,
  },
  {
    path: "/corporations/boardmember/reset",
    element: <SendMail />,
  },
  {
    path: "/contractors/reset",
    element: <SendMail />,
  },
  {
    path: "/passwordreset",
    element: <ResetPassword />,
  },
  {
    path: "/savepassword",
    element: <SavePassword />,
  },
  {
    path: "/corporations/proposal/:id",
    element: (
      <ProtectedCorporation>
        <ProposalView />
      </ProtectedCorporation>
    ),
  },
  {
    path: "/corporations/reviews/:id",
    element: (
      <ProtectedCorporation>
        <SetReviews />
      </ProtectedCorporation>
    ),
  },
  {
    path: "/corporations/contract",
    element: (
      <ProtectedCorporation>
        <Contracts />
      </ProtectedCorporation>
    ),
  },

  {
    path: "/corporations/owners",
    element: (
      <ProtectedCorporation>
        <CreateOwners />
      </ProtectedCorporation>
    ),
  },
  {
    path: "/corporations/tenant",
    element: (
      <ProtectedCorporation>
        <CreateTenant />
      </ProtectedCorporation>
    ),
  },
  {
    path: "/contractors/profile",
    element: (
      <ProtectedCorporation>
        <ContractorProfile />
      </ProtectedCorporation>
    ),
  },
  {
    path: "/corporations/editcontract/:id",
    element: (
      <ProtectedCorporation>
        <EditContract />
      </ProtectedCorporation>
    ),
  },
  {
    path: "/contractors/contractlisting",
    element: (
      <ProtectedContractor>
        <ContractListing />
      </ProtectedContractor>
    ),
  },
  {
    path: "/contractors/detailscontract/:id",
    element: (
      <ProtectedContractorOrCorporation>
        <DetailsContractListing />
      </ProtectedContractorOrCorporation>
    ),
  },
  {
    path: "/contractors/appliedcontracts",
    element: (
      <ProtectedContractor>
        <AppliedContracts />
      </ProtectedContractor>
    ),
  },
  {
    path: "/contractors/approvedcontracts",
    element: (
      <ProtectedContractor>
        <ApprovedContracts />
      </ProtectedContractor>
    ),
  },
  {
    path: "/contractors/reviews",
    element: (
      <ProtectedContractor>
        <ContractorReviews />
      </ProtectedContractor>
    ),
  },
  {
    path: "/corporations/managecontracts",
    element: (
      <ProtectedCorporation>
        <ManageContracts />
      </ProtectedCorporation>
    ),
  },
  {
    path: "/corporations/dashboard",
    element: (
      <ProtectedCorporation>
        <Dashboard />
      </ProtectedCorporation>
    ),
  },
  {
    path: "/corporations/profile",
    element: (
      <ProtectedCorporation>
        <CorporationProfile />
      </ProtectedCorporation>
    ),
  },
  {
    path: "corporations/messages",
    element: (
      <ProtectedCorporation>
        <Messages />
      </ProtectedCorporation>
    ),
  },

  {
    path: "contractors/messages",
    element: (
      <ProtectedCorporation>
        <ContractorMessages />
      </ProtectedCorporation>
    ),
  },
  {
    path: "/verifyboardmember",
    element: <VerifyBoardMember />,
  },
  { path: "/about", element: <About /> },
  { path: "/blog", element: <Blog /> },
  { path: "/blog/:id", element: <BlogDetails /> },
  { path: "/contact", element: <Contact /> },
  {
    path: "/admin",
    element: (
      <ProtectedAdmin>
        <Admin />
      </ProtectedAdmin>
    ),
  },
  {
    path: "/admin/profile",
    element: (
      <ProtectedAdmin>
        <AdminProfile />
      </ProtectedAdmin>
    ),
  },

  { path: "/terms", element: <TermsAndCondition /> },
  { path: "/privacypolicy", element: <PrivacyPolicy /> },
  { path: "/auth", element: <AdminLogin /> },
  { path: "*", element: <PageNotFound /> },
  // { path: "/test", element: <Test /> },
  // { path: "/test2", element: <Test2 /> },
  { path: "/test3", element: <Test3 /> },
  { path: "/test4", element: <Test4 /> },
]);

export default function App() {
  return <RouterProvider router={router} exact={true} />;
}
