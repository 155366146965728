import React, { memo } from "react";

function Pagination({ page, totalPages, setPage, noOfButton = 3 }) {

  const generatePaginationButtons = () => {
    const buttons = [];
    const maxPage = Math.min(page + noOfButton - 1, totalPages - 1);

    if (totalPages - page > noOfButton && totalPages - page) {

      for (let i = page; i <= maxPage; i++) {
        buttons.push(
          <li key={i}>
            <div
              className={`block rounded-lg border border-gray-100 px-3 py-1.5 text-sm sm:text-sm md:text-base text-bold cursor-pointer transition-all duration-300 hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white hover:border-white ${i === page
                ? "bg-gradient-to-r from-cyan-600 to-blue-800 text-white"
                : ""
                }`}
              onClick={() => setPage(i)}
            >
              {i}
            </div>
          </li>
        );
      }
    } else {
      for (let i = totalPages - noOfButton; i <= totalPages - 1; i++) {
        buttons.push(
          <li key={i}>
            <div
              className={`block rounded-lg border border-gray-100 px-3 py-1.5 text-sm sm:text-sm md:text-base  text-bold cursor-pointer transition-all duration-300 hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white hover:border-white ${i === page
                ? "bg-gradient-to-r from-cyan-600 to-blue-800 text-white"
                : ""
                }`}
              onClick={() => setPage(i)}
            >
              {i}
            </div>
          </li>
        );
      }
    }

    return buttons;
  };

  return (
    <div className="flex justify-center">
      {totalPages > 0 && (
        <ul className="list-style-none flex flex-wrap space-x-2 py-8">
          {/* Prev Button */}
          <li>
            <div
              className={`block rounded-lg px-3 py-1.5 text-base text-bold  ${page !== 1
                ? "text-black cursor-pointer text-sm sm:text-sm md:text-base transition-all duration-300 hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white hover:border-white  "
                : "text-gray-400"
                }`}
              onClick={() => page !== 1 && setPage(page - 1)}
            >
              Prev
            </div>
          </li>

          {/* Pagination Buttons */}
          {generatePaginationButtons()}

          {/* Ellipsis (if there are more pages to show) */}
          {totalPages > noOfButton && page + 1 <= totalPages - noOfButton && (
            <li key="...">...</li>
          )}

          {/* Last page (if there are more pages to show) */}
          {totalPages > noOfButton && (
            <>
              <li key={totalPages}>
                <div
                  className={`block rounded-lg border border-gray-100  text-sm sm:text-sm md:text-base px-3 py-1.5  text-bold cursor-pointer transition-all duration-300 hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white hover:border-white ${totalPages === page
                    ? "bg-gradient-to-r from-cyan-600 to-blue-800 text-white"
                    : ""
                    }`}
                  onClick={() => setPage(totalPages)}
                >
                  {totalPages}
                </div>
              </li>
            </>
          )}

          {/* Next Button */}
          <li>
            <div
              className={`block rounded-lg px-3 py-1.5 text-base text-bold  ${page !== totalPages
                ? "text-black cursor-pointer text-sm sm:text-sm md:text-base transition-all duration-300 hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white hover:border-white "
                : "text-gray-400"
                }`}
              onClick={() => page !== totalPages && setPage(page + 1)}
            >
              Next
            </div>
          </li>
        </ul>
      )}
    </div>
  );
}

export default memo(Pagination);
