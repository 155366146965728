import React from "react";
import Layout from "../../components/layout/Layout";
import { NavLink } from "react-router-dom";
import { BiSolidPhoneCall } from "react-icons/bi";

import { IoMdMail } from "react-icons/io";
import { ContractorProfileLeftImage } from "../../assets/images";

function PrivacyPolicy() {
  return (
    <Layout>
      <div>
        <div>
          <div>
            <div className="h-full w-full flex relative ">
              {/* First div : images */}

              {/* Terms and Conditions Us Section */}
              <div className="w-full h-full pb-[3%] pl-[2%] pr-[3%] py-4 mx-3 sm:mx-3 md:mx-0">
                <hr className="border-t-2  w-full" />
                <h2 className="text-2xl lg:text-4xl my-3 text-primaryl euclidbold">
                  CONDOMINIUM PORTAL INC. – PRIVACY POLICY
                </h2>
                <hr className="border-t-2 mb-4" />
                <p className="mb-4  text-gray-800 text-justify italic">
                  Last updated: June 10, 2024
                </p>
                <p className="mb-2  text-gray-800 text-justify ">
                  Condominium Portal Inc. and its affiliates ("
                  <strong>CPI</strong>", "<strong>us</strong>", "
                  <strong>our</strong>" or "<strong>we</strong>") are dedicated
                  to protecting the privacy rights of our users ("
                  <strong>users</strong>" or "<strong>you</strong>"). This
                  Privacy Policy (the "<strong>Policy</strong>") describes the
                  ways we collect, store, use, and manage the information,
                  including personal information, that you provide or we collect
                  in connection with our websites, including
                  CondominiumPortal.com (the "<strong>Sites</strong>") or any
                  CPI service provided on a mobile platform (for example, iOS
                  and Android) (collectively, the "<strong>Service</strong>").
                </p>
                <p className="mb-2  text-gray-800 text-justify ">
                  Please note that the scope of this Policy is limited to
                  information collected or received by CPI through your use of
                  the Service.
                </p>
                <p className="mb-2  text-gray-800 text-justify ">
                  CPI is not responsible for the actions of third-party people
                  or companies, the content of their sites, the use of
                  information you provide to them, or any products or services
                  they may offer. Any link to those sites does not constitute
                  our sponsorship of, or affiliation with, those people or
                  companies.
                </p>
                <p className="mb-2  text-gray-800 text-justify ">
                  By using the Service, you are expressing your agreement to
                  this Policy and the processing of your data, including your
                  personal information, in the manner provided in this Policy.
                  If you do not agree to these terms, please do not use the
                  Service.
                </p>
                <p></p>
                <p className="mb-4  text-gray-800 text-justify">
                  If you have questions or concerns about our privacy policy or
                  practices, please contact us{" "}
                  <span className=" text-primaryl  cursor-pointer hover:text-primaryd">
                    <NavLink
                      to="mailto:support@condominiumportal.com"
                      className="text-primaryl cursor-pointer hover:text-primaryd"
                    >
                      support@condominiumportal.com
                    </NavLink>
                  </span>
                  .
                </p>
                <h1 className="euclidbold mb-1">
                  Information Collection and Use
                </h1>
                <p className="mb-2  text-gray-800 text-justify">
                  CPI collects information as described below. CPI’s primary
                  goals in collecting and using information is to create your
                  account, provide Services to you, improve our Service, contact
                  you, conduct research and create reports for internal use. We
                  store information on servers located in North America (United
                  States of America and Canada) via Microsoft Azure Services and
                  will only store information on servers and equipment in North
                  America.
                </p>
                <p className="mb-4  text-gray-800 text-justify">
                  We may also acquire information from you through (1) your
                  access and participation in message boards on the Service or
                  (2) your participation in surveys regarding the Service.
                </p>
                <h1 className="euclidbold mb-1"> CPI’s Sites</h1>
                <p className="mb-2  text-gray-800 text-justify">
                  While you are browsing CPI’s Sites, your computer's operating
                  system, Internet Protocol (IP) address, access times, browser
                  type and language, and referring Web site addresses may be
                  logged automatically.
                </p>
                <p className="mb-4  text-gray-800 text-justify">
                  We may use this information to monitor, develop, and analyze
                  your use of the Service. In addition, we may ask you to
                  submit, and we may process, data that is personal to you,
                  including but not limited to your name, profile photo, gender,
                  age or birthday, links to your profiles on social networking
                  websites and other third party sites, user names and e-mail
                  and mail addresses. We may use this information to create a
                  user profile and provide you services. We may use your email
                  address to contact you about your experience with CPI Sites
                  and notify you about company news and promotions. Your gender
                  and birthday may be used to analyze user trends and target
                  certain promotions. Your mailing address may be used if we
                  have merchandise or other physical promotional materials that
                  we want to send to you. If you no longer wish to receive these
                  types of promotional materials, you may opt-out from receiving
                  them at any time by emailing us at{" "}
                  <span className=" text-primaryl  cursor-pointer hover:text-primaryd">
                    <NavLink
                      to="mailto:support@condominiumportal.com"
                      className="text-primaryl cursor-pointer hover:text-primaryd"
                    >
                      support@condominiumportal.com
                    </NavLink>
                  </span>
                  .
                </p>
                <h1 className="euclidbold mb-1"> iOS and Android Platforms</h1>
                <p className="mb-2  text-gray-800 text-justify">
                  When you use any of CPI applications on a mobile platform, we
                  may collect and record certain information such as your unique
                  device ID (persistent / non-persistent), hardware type, media
                  access control ("<strong>MAC</strong>") address, international
                  mobile equipment identity ("
                  <strong>IMEI</strong>"), the version of your operating system
                  ("<strong>OS</strong>"), your device name, your email address
                  (if you have connected to Facebook or Google+), and your
                  location (based on your Internet Protocol ("
                  <strong>IP</strong>") address). This information is useful to
                  us for troubleshooting and helps us understand usage trends.
                </p>
                <p className="mb-2  text-gray-800 text-justify">
                  We may collect your location-based information for the purpose
                  of providing you with the correct version of the application.
                  We will not share this information with any third party
                  without your permission. If you no longer wish to allow us to
                  track or use this information, you may turn it off at the
                  device level. Please note, the application may not work
                  properly if we are unable to tell where you are coming from
                  (Country specific).
                </p>
                <p className="mb-4  text-gray-800 text-justify">
                  In addition, we create a unique user ID to track your use of
                  our Service. This unique user ID is stored in connection with
                  your profile information.
                </p>
                <h1 className="euclidbold mb-1">Customer Service</h1>
                <p className="mb-4  text-gray-800 text-justify">
                  We may collect your email address when you contact our
                  customer service group and we may use that email address to
                  contact you about your experience with CPI and notify you
                  about company news and promotions. If you no longer wish to
                  receive certain email notifications you may opt-out at any
                  time by following the unsubscribe link located at the bottom
                  of each communication or by emailing us at{" "}
                  <span className=" text-primaryl  cursor-pointer hover:text-primaryd">
                    <NavLink
                      to="mailto:support@condominiumportal.com"
                      className="text-primaryl cursor-pointer hover:text-primaryd"
                    >
                      support@condominiumportal.com
                    </NavLink>
                  </span>
                  .
                </p>
                <h1 className="euclidbold mb-1"> Push Notifications</h1>
                <p className="mb-4  text-gray-800 text-justify">
                  We may occasionally send you push notifications through our
                  mobile applications to send you updates and other
                  service-related notifications that may be of importance to
                  you. You may at any time opt-out from receiving these types of
                  communications by turning them off at the device level through
                  your settings.
                </p>
                <h1 className="euclidbold mb-1"> Tracking Technologies</h1>
                <p className="mb-2  text-gray-800 text-justify">
                  We, and our marketing and outsourcing partners, affiliates, or
                  analytics service providers use technologies such as cookies,
                  beacons, scripts, and tags to identify a user's
                  computer/device and to "remember" things about your visit,
                  such as your preferences or a user name and password.
                  Information contained in a cookie may be linked to your
                  personal information, such as your user ID, for purposes such
                  as improving the quality of our Services, tailoring
                  recommendations to your interests, and making the Service
                  easier to use. You can disable cookies at any time, although
                  you may not be able to access or use features of the Service.
                </p>
                <p className="mb-4 text-gray-800 text-justify">
                  We may feature advertisements served by third parties that
                  deliver cookies to your computer/device so the content you
                  access and advertisements you see can be tracked. Since the
                  third-party advertising companies associate your
                  computer/device with a number, they will be able to recognize
                  your computer/device each time they send you an advertisement.
                  These advertisers may use information about your visits to our
                  Service and third-party sites and applications in order to
                  measure advertisement performance and to provide
                  advertisements about goods and services of interest to you.
                  This Policy does not apply to, and we are not responsible for
                  the data collection practices of these third-party
                  advertisers, and we encourage you to check their privacy
                  policies to learn more about their use of cookies and other
                  technology.
                </p>
                <h1 className="euclidbold mb-1">Mobile Analytics</h1>
                <p className="mb-4  text-gray-800 text-justify">
                  We may use mobile analytics software to allow us to better
                  understand the functionality of our Mobile Software on your
                  device. This software may record information such as how often
                  you use the application, the events that occur within the
                  application, aggregated usage, performance data, and where the
                  application was downloaded from. We do not link the
                  information we store within the analytics software to any
                  personal information you submit within the mobile application.
                </p>
                <h1 className="euclidbold mb-1">Third Party Services</h1>
                <p className="mb-4  text-gray-800 text-justify">
                  Our services may contain third party tracking tools from our
                  service providers, examples of which may include Google
                  Analytics. Such third parties may use cookies, APIs, and SDKs
                  in our services to enable them to collect and analyze user
                  information on our behalf. The third parties may have access
                  to information such as your device identifier, MAC address,
                  IMEI, locale (specific location where a given language is
                  spoken), geo-location information, and IP address for the
                  purpose of providing their services under their respective
                  privacy policies. Our privacy policy does not cover the use of
                  tracking tools from third parties. We do not have access or
                  control over these third parties.
                </p>
                <h1 className="euclidbold mb-1">How We Use Information</h1>
                <p className="mb-2  text-gray-800 text-justify">
                  We use information collected through our Service for purposes
                  described in this Policy or disclosed to you in connection
                  with our Service. For example, we may use your information to:
                </p>
                <ul class="list-disc ml-4 mb-4 text-gray-800 text-justify space-y-2">
                  <li>
                    create accounts and allow users to use the mobile
                    application;
                  </li>
                  <li>operate and improve our Service;</li>
                  <li>
                    understand you and your preferences to enhance your
                    experience and enjoyment using our Service;
                  </li>
                  <li>
                    respond to your comments and questions and provide customer
                    service;
                  </li>
                  <li>
                    provide and deliver products and services you request;
                  </li>
                  <li>
                    send you related information, including confirmations,
                    invoices, technical notices, updates, security alerts, and
                    support and administrative messages;
                  </li>
                  <li>
                    communicate with you about promotions, rewards, upcoming
                    events, and other news about products and services offered
                    by CPI and our selected partners;
                  </li>
                  <li>enable you to communicate with other users; and</li>
                  <li>
                    link or combine it with other information we get from third
                    parties, to help understand your preferences and provide you
                    with better services.
                  </li>
                </ul>
                <h1 className="euclidbold mb-1">
                  Disclosure of Your information
                </h1>
                <p className="mb-2  text-gray-800 text-justify">
                  CPI does not share your personal information except as
                  approved by you or as described below:
                </p>
                <ul class="list-disc ml-4 mb-4 text-gray-800 text-justify space-y-2">
                  <li>
                    With your consent, for example, when you agree to our
                    sharing your information with other third parties for their
                    own marketing purposes subject to their separate privacy
                    policies. If you no longer wish to allow us to share your
                    information with third parties, please contact us{" "}
                    <span className=" text-primaryl  cursor-pointer hover:text-primaryd">
                      <NavLink
                        to="mailto:support@condominiumportal.com"
                        className="text-primaryl cursor-pointer hover:text-primaryd"
                      >
                        support@condominiumportal.com
                      </NavLink>
                    </span>
                    . If you no longer wish to receive such communications from
                    third parties, please contact that third party directly.
                  </li>
                  <li>
                    CPI may engage other companies and individuals to perform
                    services on our behalf. Example of these services include
                    analyzing data and providing customer support. These agents
                    and service providers may have access to your personal
                    information in connection with the performance of services
                    for CPI.
                  </li>
                  <li>
                    We may release your information as permitted by law, such as
                    to comply with a subpoena, or when we believe that release
                    is appropriate to comply with the law; investigate fraud,
                    respond to a government request, enforce or apply our
                    rights; or protect the rights, property, or safety of us or
                    our users, or others. This includes exchanging information
                    with other companies and organizations for fraud protection.
                  </li>
                  <li>
                    CPI may share your information in connection with any
                    merger, sale of our assets, or a financing or acquisition of
                    all or a portion of our business to another company. You
                    will be notified via email and/or notice on our site of any
                    change in ownership or users of your personal information.
                  </li>
                  <li>
                    We may share aggregate or anonymous information about you
                    with advertisers, publishers, business partners, sponsors,
                    and other third parties.
                  </li>
                </ul>
                <h1 className="euclidbold mb-1">Changes to the Policy</h1>
                <p className="mb-4  text-gray-800 text-justify">
                  We may update this privacy policy to reflect changes to our
                  information practices. If we make any material changes, we
                  will notify you by email (sent to the e-mail address specified
                  in your account) or by means of a notice on this Site prior to
                  the change becoming effective. We encourage you to
                  periodically review this page for the latest information on
                  our privacy practices.
                </p>
                <h1 className="euclidbold mb-1">Security</h1>
                <p className="mb-2  text-gray-800 text-justify">
                  CPI takes reasonable measures to protect your information from
                  unauthorized access or against loss, misuse or alteration by
                  third parties.
                </p>
                <p className="mb-2  text-gray-800 text-justify">
                  Although we make good faith efforts to store the information
                  collected on the Service in a secure operating environment
                  that is not available to the public, we cannot guarantee the
                  absolute security of that information during its transmission
                  or its storage on our systems.
                </p>
                <p className="mb-4  text-gray-800 text-justify">
                  Further, while we attempt to ensure the integrity and security
                  of our network and systems, we cannot guarantee that our
                  security measures will prevent third-party "hackers" from
                  illegally obtaining access to this information. We do not
                  warrant or represent that your information will be protected
                  against, loss, misuse, or alteration by third parties. No
                  method of transmission over the Internet, or method of
                  electronic storage, is 100% secure, however. Therefore, we
                  cannot guarantee its absolute security.
                </p>
                <h1 className="euclidbold mb-1">
                  Access to Personal Information
                </h1>
                <p className="mb-4  text-gray-800 text-justify">
                  If your personal information changes, or if you no longer
                  desire our service, you may correct, update, or delete
                  inaccuracies by making the change within your account settings
                  or by contacting us at{" "}
                  <span className=" text-primaryl  cursor-pointer hover:text-primaryd">
                    <NavLink
                      to="mailto:support@condominiumportal.com"
                      className="text-primaryl cursor-pointer hover:text-primaryd"
                    >
                      support@condominiumportal.com
                    </NavLink>
                  </span>
                  . We will respond to your access request within thirty (30)
                  days.
                </p>
                <h1 className="euclidbold mb-1">Data Retention</h1>
                <p className="mb-4  text-gray-800 text-justify">
                  We will retain your information for as long as your account is
                  active or as needed to provide you services. If you wish to
                  cancel your account or request that we no longer use your
                  information to provide you services, contact us at{" "}
                  <span className=" text-primaryl  cursor-pointer hover:text-primaryd">
                    <NavLink
                      to="mailto:support@condominiumportal.com"
                      className="text-primaryl cursor-pointer hover:text-primaryd"
                    >
                      support@condominiumportal.com
                    </NavLink>
                  </span>
                  . We will retain and use your information as necessary to
                  comply with our legal obligations, resolve disputes, and
                  enforce our agreements.
                </p>
                <h1 className="euclidbold mb-1">
                  Our Policy Regarding Children
                </h1>
                <p className="mb-4  text-gray-800 text-justify">
                  We do not knowingly collect or solicit personal information
                  from anyone under the age of 13 or knowingly allow such
                  persons to use our Service. If you are under 13, please do not
                  send any information about yourself to us, including your
                  name, address, telephone number, or email address. No one
                  under the age of 13 may provide any personal information. In
                  the event that we learn that we have collected personal
                  information from a child under age 13, we will delete that
                  information as quickly as possible. If you believe that we
                  might have any information from or about a child under the age
                  of 13, please contact us at{" "}
                  <span className=" text-primaryl  cursor-pointer hover:text-primaryd">
                    <NavLink
                      to="mailto:support@condominiumportal.com"
                      className="text-primaryl cursor-pointer hover:text-primaryd"
                    >
                      support@condominiumportal.com
                    </NavLink>
                  </span>
                  .
                </p>
                <h1 className="euclidbold mb-1">Opting Out of Marketing</h1>
                <p className="mb-4  text-gray-800 text-justify">
                  You may opt-out of receiving promotional emails from us by
                  following the instructions in those emails by emailing us at{" "}
                  <span>
                    <NavLink
                      to="mailto:support@condominiumportal.com"
                      className="text-primaryl cursor-pointer hover:text-primaryd"
                    >
                      support@condominiumportal.com
                    </NavLink>
                  </span>
                  . If you opt-out, we may still send you non-promotional
                  emails, such as emails about your accounts or our ongoing
                  business relations.
                </p>
                <p className="mb-4  text-gray-800 text-justify">
                  You may also opt-out of receiving SMS notifications from us,
                  either via SMS or by emailing us at{" "}
                  <span className=" text-primaryl  cursor-pointer hover:text-primaryd">
                    <NavLink
                      to="mailto:support@condominiumportal.com"
                      className="text-primaryl cursor-pointer hover:text-primaryd"
                    >
                      support@condominiumportal.com
                    </NavLink>
                  </span>
                  .
                </p>
                <h1 className="euclidbold mb-1">Contact Information</h1>
                <p className="mb-1  text-gray-800 text-justify">
                  Condominium Portal Inc.
                </p>
                <p className="mb-1  text-gray-800 text-justify">
                  1290 Central Pkwy W 11th Floor Suite 1100,
                </p>
                <p className="mb-1  text-gray-800 text-justify">
                  Mississauga, ON L5C 4R3
                </p>
                <p className="mb-1 flex items-center gap-1 text-gray-800 text-justify ">
                  Email:{" "}
                  <span className=" text-primaryl  cursor-pointer hover:text-primaryd">
                    <NavLink
                      to="mailto:support@condominiumportal.com"
                      className="text-primaryl cursor-pointer hover:text-primaryd"
                    >
                      support@condominiumportal.com
                    </NavLink>
                  </span>
                </p>
                <p className="mb-1  text-gray-800 text-justify">
                  If you have any questions about this Policy, please contact us
                  at{" "}
                  <span className=" text-primaryl  cursor-pointer hover:text-primaryd">
                    <NavLink
                      to="mailto:support@condominiumportal.com"
                      className="text-primaryl cursor-pointer hover:text-primaryd"
                    >
                      support@condominiumportal.com
                    </NavLink>
                  </span>
                </p>
              </div>
              <div className="w-[45%]  hidden md:block ">
                <img
                  src={ContractorProfileLeftImage}
                  className="absolute top-6 bottom-0 w-[30%] h-full object-cover"
                  alt="Signup background"
                />
              </div>
              <div className="absolute hidden bottom-[-1%] right-0 w-full md:w-[30%] h-[40%] bg-gradient-to-b from-transparent to-[#0893bd]  md:block"></div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default PrivacyPolicy;
