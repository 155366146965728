import React from 'react'

function PlusSquare() {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.1875 13.2812H13.2812V17.1875C13.2812 17.6172 12.9312 17.9688 12.5 17.9688C12.0688 17.9688 11.7188 17.6172 11.7188 17.1875V13.2812H7.8125C7.38125 13.2812 7.03125 12.9297 7.03125 12.5C7.03125 12.0703 7.38125 11.7188 7.8125 11.7188H11.7188V7.8125C11.7188 7.38281 12.0688 7.03125 12.5 7.03125C12.9312 7.03125 13.2812 7.38281 13.2812 7.8125V11.7188H17.1875C17.6187 11.7188 17.9688 12.0703 17.9688 12.5C17.9688 12.9297 17.6187 13.2812 17.1875 13.2812ZM21.875 0H3.125C1.39922 0 0 1.39844 0 3.125V21.875C0 23.6016 1.39922 25 3.125 25H21.875C23.6008 25 25 23.6016 25 21.875V3.125C25 1.39844 23.6008 0 21.875 0Z" fill="#666666" />
        </svg>

    )
}

export default PlusSquare
