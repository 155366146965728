import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import {
  ApprovedIcon,
  InputFileIcon,
  LogoWithoutText,
  Spinner,
} from "../../assets/images/index.jsx";
import { set, useForm } from "react-hook-form";
import { proposalFileAPI, proposalAPI } from "../../constants/API.js";
import InputBox from "../Elements/InputBox.jsx";
import toast from "react-hot-toast";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import InputBoxLive from "../Elements/InputBoxLive.jsx";

function ProposalForm({ contractId, setLoad }) {
  const cookies = new Cookies();
  const navigate = useNavigate();
  // image upload handling constants
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5 MB
  const SUPPORTED_FILE_TYPES = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "image/jpg",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  // Example supported file types

  // proposal file handling states
  const [proposalFile, setProposalFile] = useState(null);
  const [proposalFileError, setProposalFileError] = useState("");
  const [proposalFileUploded, setProposalFileUploded] = useState("");
  const [proposalFileUrl, setProposalFileUrl] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);

  // Form validation schema using yup
  const schema = yup.object().shape({
    companyName: yup.string().required("Company Name is required"),
    yourName: yup.string().required("Your Name is required"),
    designation: yup.string().required("Designation is required"),
    estimateTime: yup
      .number()
      .required("Estimate Time  is required")
      .typeError("Estimate Time  is required")
      .min(1, "Estimate time  must be greater than 0"),
    otherDetails: yup.string(),
    attachProposal: yup.string().required("Proposal Upload is required"),
    // token: yup.string().default(cookies.get("token")),
    // contractId: yup.string().default(contractId),
  });

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  // Handle form submit
  const onProposalSubmit = async (data, e) => {
    e.preventDefault();
    setLoad(true);
    data.contractId = contractId;

    try {
      const response = await axios.post(proposalAPI, data, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "contractor",
        },
      });
      setProposalFileError("");
      setProposalFileUploded("");
      setProposalFileUrl("");
      setProposalFile(null);
      toast.success(response.data.message, { duration: 4000 });
      navigate("/contractors/contractlisting");
    } catch (error) {
      toast.error("Failed to Submit Proposal !");
      if (error.response) {
        toast.error(error.response.data.message, { duration: 4000 });
      } else {
        toast.error("Something went wrong !");
      }
      if (
        error.response.data.message ===
        "You have already submitted a proposal for this contract"
      ) {
        navigate("/contractors/contractlisting");
      }
    } finally {
      setLoad(false);
    }
  };

  // Handle file upload for proposal file local
  const handleChangeProposalFile = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setProposalFile(null);
      setProposalFileError("Please Attach a Proposal");
      setProposalFileUploded("");
      setProposalFileUrl("");
      clearErrors("attachProposal");
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT &&
        SUPPORTED_FILE_TYPES.includes(file.type)
      ) {
        setProposalFile(file);
        setProposalFileError("");
        setProposalFileUploded("");
        clearErrors("attachProposal");
        setProposalFileUrl("");
      } else if (file && !SUPPORTED_FILE_TYPES.includes(file.type)) {
        setProposalFile(null);
        setProposalFileError(
          "Unsupported file type file type must be jpg , jpeg, png, pdf, docx"
        );
        setProposalFileUploded("");
        setProposalFileUrl("");
        clearErrors("attachProposal");
      } else if (file && !file.size <= FILE_SIZE_LIMIT) {
        setProposalFile(null);
        setProposalFileError("File size exceeds limit (5 MB)");
        setProposalFileUploded("");
        setProposalFileUrl("");
        clearErrors("attachProposal");
      } else {
        setProposalFile(null);
        setProposalFileError("Something went wrong please try again later");
        setProposalFileUploded("");
        setProposalFileUrl("");
        clearErrors("attachProposal");
      }
    }
  };

  // Handle Proposal File Upload API
  const handleUploadProposalFile = async () => {
    if (!proposalFile) {
      if (!proposalFileError) {
        setProposalFileError("Please Attach a Proposal");
        clearErrors("attachProposal");
        setProposalFileUploded("");
      }
      return;
    }

    const formData = new FormData();
    formData.append("file", proposalFile);

    try {
      setProposalFileError("");
      clearErrors("attachProposal");
      setUploadingFile(true);
      const response = await axios.post(proposalFileAPI, formData, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "contractor",
        },
      });
      setProposalFileError("");
      setValue("attachProposal", response.data.data.url);
      setProposalFileUrl(response.data.data.url);
      setUploadingFile(false);
      setProposalFileUploded("Uploaded Successfully");
      clearErrors("attachProposal");
      // console.log(response.data.data.url);
    } catch (error) {
      setUploadingFile(false);
      console.error("Error uploading Proposal :", error);
      setProposalFileError("Failed to upload Proposal");
      clearErrors("attachProposal");
      setProposalFileUrl("");
      setProposalFileUploded("");
      setValue("attachProposal", "");
    }
  };

  function clearProposalFileError() {
    setProposalFileError("");
  }

  return (
    <div className="w-[93%] lg:max-w-[100%] mx-auto px-4 pt-10 sm:px-6 lg:px-8">
      <div className="flex-1">
        <p className="text-2xl text-indigo-900  euclidbold py-3 ">Proposal</p>
        {/* Horizontal line */}
        <hr className=" border-gray-300" />
      </div>

      <div className="mt-4">
        <form onSubmit={handleSubmit(onProposalSubmit)}>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 ">
            {/* Company Name */}
            <div className="mb-3 md:pr-4 lg:pr-0">
              <InputBox
                title={"Company Name"}
                register={register}
                filedName={"companyName"}
                must={true}
                errors={errors}
              />
            </div>

            {/*Your Name  */}
            <div className="mb-3 md:pl-4 lg:pl-0">
              <InputBox
                title={"Your Name"}
                register={register}
                filedName={"yourName"}
                must={true}
                errors={errors}
              />
            </div>

            {/* Designations */}
            <div className="mb-3 md:pr-4 lg:pr-0">
              <InputBox
                title={"Designation"}
                register={register}
                filedName={"designation"}
                errors={errors}
                must={true}
              />
            </div>
          </div>

          <div className="grid sm:grid-rows-1 md:grid-rows-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:grid-flow-col">
            {/* Estimation time */}
            <div className="mb-3 md:col-span-1 lg:pr-12 ">
              <div className="flex items-center">
                <InputBoxLive
                  title="Estimate time"
                  register={register}
                  filedName={"estimateTime"}
                  errors={errors}
                  width="w-11/12"
                  type="number"
                  className={"sm:text-base md:text-sm lg:text-base"}
                  min="1"
                  schema={schema}
                  setError={setError}
                  must={true}
                />
                {/* <p className="">Days </p> */}
              </div>
            </div>

            {/* Attach Proposal */}
            <div className="mb-3 md:col-span-1  lg:pr-12">
              {/* Label */}
              <label htmlFor="proposalFile" className={`block font-normal`}>
                Attach Proposal <sup className="text-red-500 text-base">*</sup>
              </label>
              {/* Input */}
              <input
                type="file"
                id="proposalFile"
                name="proposalFile"
                className={`hidden  ${
                  uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                } `}
                onChange={handleChangeProposalFile}
                disabled={uploadingFile}
              />
              {/* Label For style is input tag and use in File input */}
              <label
                htmlFor="proposalFile"
                className={`block bg-transparent h-10 w-full rounded-sm text-black  ring-1 px-2 ring-gray-400 ps-10 focus:ring-sky-600 focus:outline-none focus:border-rose-600 ${
                  errors.proposalFile ? "ring-1 ring-red-500" : ""
                }  ${uploadingFile ? "cursor-not-allowed" : "cursor-pointer"} `}
                disabled={uploadingFile}
              >
                <div className="flex items-center h-full space-x-1">
                  <InputFileIcon />
                  {proposalFile ? (
                    <span className="line-clamp-1">{proposalFile.name}</span>
                  ) : (
                    <span className="text-gray-600/50">Upload Proposal</span>
                  )}
                </div>
              </label>

              {/* Error */}
              <div className="h-[3vh] pt-0">
                {uploadingFile && <Spinner />}

                {proposalFileError && (
                  <p className="text-red-500 text-xs leading-none">
                    {proposalFileError}
                  </p>
                )}

                {errors.attachProposal && ( // Error message from yup validation
                  <p className="text-red-500 text-xs leading-none">
                    {errors.attachProposal.message}
                  </p>
                )}

                {proposalFileUploded && (
                  <p className="text-gray-600 text-sm leading-none flex items-center gap-2 mt-2">
                    <ApprovedIcon /> Proposal uploaded Successfully!
                  </p>
                )}
              </div>
            </div>
            {/* Upload Button */}
            <div className="mb-3 md:col-span-1  lg:pr-12">
              {proposalFileUrl ? (
                <div className="h-10 w-full max-w-32 text-sm px-6 py-2.5   euclidbold   cursor-not-allowed ring-1  ring-cyan-500 flex  justify-center items-center text-cyan-500 sm:text-xl    tracking-widest   md:mt-0 ">
                  UPLOADED
                </div>
              ) : (
                <div
                  onClick={handleUploadProposalFile}
                  className={`h-10 w-32  px-6 py-2.5 ring-1  ring-cyan-500  flex  justify-center text-cyan-500 text-xl font-bold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800  hover:text-white hover:ring-cyan-600 transition duration-300 ease-in-out  md:mt-0 items-center euclidbold   ${
                    uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  disabled={uploadingFile}
                >
                  UPLOAD
                </div>
              )}
            </div>

            {/* Other Details */}
            <div className="md:mb-3 md:row-span-2 md:col-span-2 md:ml-14 lg:ml-0 md:pl-4">
              <div className=" mb-3">
                {/* Label */}
                <label htmlFor="otherDetails" className={`block font-normal `}>
                  Other Details
                </label>
                {/* Input */}
                <textarea
                  type="text"
                  id="otherDetails"
                  name="otherDetails"
                  {...register("otherDetails")}
                  className={`bg-transparent block h-40 max-h-40 w-full rounded-sm text-black  ring-1 px-2 ring-gray-400 ps-10 focus:ring -sky-600 focus:outline-none focus:border-rose-600 ${
                    errors.otherDetails ? "ring-1 ring-red-500" : ""
                  }`}
                  placeholder="Type Other Details"
                />

                {/* Error */}
                <div className="h-[2vh] pt-1">
                  {errors.otherDetails && (
                    <p className="text-red-500 text-xs  leading-none">
                      {errors.otherDetails.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Apply Button */}
            <div className="mb-3  md:row-end-4 md:col-end-4 md:flex md:justify-end">
              <button
                type="submit"
                onClick={clearProposalFileError}
                className={`h-11 w-32 px-6 py-2.5  border-2  border-cyan-500   text-cyan-500 text-xl font-bold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800  hover:text-white transition duration-300 items-center flex uppercase justify-center euclidbold  ${
                  uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                disabled={uploadingFile}
              >
                APPLY
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProposalForm;
