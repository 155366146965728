import React, { useState } from "react";
import circle from "../../assets/images/contactcircle.png";
import axios from "axios";
import { baseUrl } from "../../constants/API";
import { NavLink, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Loading from "../../components/Elements/Loading";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoMdMail } from "react-icons/io";

function ContactForm() {
  const navigate = useNavigate();

  const [load, setLoad] = useState(false);
  const schema = yup.object().shape({
    First_Name: yup.string().required("First Name is required"),
    Last_Name: yup.string().required("Last Name is required"),
    Email: yup
      .string()
      .email()
      .required("Email is required")
      .test("valid-email", "Invalid Email Address", (value) => {
        if (!value) return true; // Skip validation if the field is empty
        // Check if the value matches the pattern of an email
        return /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/.test(value);
      }),
    Phone_No: yup
      .string()
      .required("Phone Number is required")
      .test("invalid-phoneno", "Invalid Phone No", (value) => {
        if (value.length > 10) {
          return true;
        }
        return false;
      }),
    Subject: yup.string().required("Subject is required"),
    Message: yup.string().required("Message is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onContactUsSubmit = async (data, e) => {
    e.preventDefault();
    // console.log(data);
    try {
      setLoad(true);
      const response = await axios.post(`${baseUrl}/contactus`, data);
      // console.log(response);
      // if (response.status === 200) {
      //   navigate("/");
      // }

      toast.success("Message Sent Successfully");
      reset();
    } catch (error) {
      toast.error(
        error.response.data.message ||
          "Failed to send message. Please try again."
      );
    } finally {
      setLoad(false);
    }
  };

  const handleKeyDown = (event) => {
    // Check if the pressed key is not a number
    if (
      !(
        (
          (event.key >= "0" && event.key <= "9") ||
          event.key === "+" ||
          event.key === "Tab" ||
          event.key === "F5" ||
          event.key === "ArrowLeft" ||
          event.key === "ArrowRight" ||
          event.key === "Delete" ||
          event.keyCode === 8 || // backspace
          (event.ctrlKey && event.keyCode === 65) ||
          (event.ctrlKey && event.keyCode === 86) || // Ctrl + V
          (event.ctrlKey && event.keyCode === 67)
        ) // Ctrl + C
      )
    ) {
      // Prevent the default behavior (don't write the character)
      event.preventDefault();
    }
  };
  return (
    <div>
      <Loading load={load} />
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-8 py-5 md:py-16 px-[2.5%] md:px-[8%] ">
        {/* Contact Information */}

        <div className="lg:col-span-2">
          <h2 className="text-2xl lg:text-4xl font-bold text-primaryl euclidbold">
            Contact Us
          </h2>
          <p className="text-gray-500 mb-4">
            Any question or remarks? Just write us a Message!
          </p>

          <div className="bg-primaryl rounded-lg p-6 relative">
            <div>
              <img
                src={circle}
                alt="contactcircle"
                className="absolute h-[60%] w-[25%] -right-7 -bottom-7 z-0"
              />
            </div>

            <div>
              <img
                src={circle}
                alt="contactcircle"
                className="absolute h-[30%] w-[15%] right-[17%] bottom-[17%] z-0"
              />
            </div>
            <h2 className="text-2xl lg:text-4xl text-white my-2 euclidbold">
              Contact Information
            </h2>
            <div className="flex items-center gap-4 my-3 ">
              <MdLocationPin className="text-white text-xl rounded-full" />
              <div className="text-white euclidbold">
                1290 Central Pkwy W 11th Floor Suite 1100, Mississauga, ON L5C
                4R3
              </div>
            </div>
            <div className="flex items-center gap-4 my-3 ">
              <BiSolidPhoneCall className="text-white text-lg rounded-full" />

              <NavLink
                to="tel:+249-777-7667"
                className="text-white cursor-pointer euclidbold"
              >
                (249-777-7667)
              </NavLink>
            </div>

            <div className="flex items-center gap-4 my-3 ">
              <IoMdMail className="text-white text-lg rounded-full " />
              <NavLink
                to="mailto:support@condominiumportal.com"
                className="text-white cursor-pointer euclidbold"
              >
                support@condominiumportal.com
              </NavLink>
            </div>
            <div className="flex items-center gap-4">
              <NavLink
                to="https://www.facebook.com/share/mypoXz4tCZbT9p1X/?mibextid=LQQJ4d"
                target="_blank"
              >
                <div className="text-indigo-900  bg-slate-50 rounded-full cursor-pointer text-lg p-1 hover:bg-indigo-900 hover:text-white ">
                  <FaFacebook />
                </div>
              </NavLink>
              <NavLink
                to="https://www.instagram.com/condominiumportal?igsh=MXVmN2hiaHV0OTU3ZQ=="
                target="_blank"
              >
                <div className="text-indigo-900  bg-slate-50  rounded-full cursor-pointer hover:bg-indigo-900 hover:text-white text-lg p-1">
                  <FaInstagram />
                </div>
              </NavLink>
            </div>
          </div>
        </div>
        {/* Contact Form */}
        <div className="lg:col-span-3 lg:mt-24">
          <form onSubmit={handleSubmit(onContactUsSubmit)}>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="mb-3">
                <label
                  htmlFor="First_Name"
                  className="block text-sm font-medium text-gray-700"
                >
                  First Name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="First_Name"
                  name="First_Name"
                  placeholder="Joe"
                  className="mt-1 p-2 block w-full border-b border-gray-300 rounded-t-md shadow-sm focus:outline-none focus:border-primaryl focus:ring focus:ring-primaryl focus:ring-opacity-50"
                  {...register("First_Name")}
                />
                <div className="h-[2vh] pt-1">
                  {errors.First_Name && (
                    <p className="text-red-500 text-xs leading-none">
                      {errors.First_Name.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="Last_Name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="Last_Name"
                  name="Last_Name"
                  placeholder="Doe"
                  className="mt-1 p-2 block w-full border-b border-gray-300 rounded-t-md shadow-sm focus:outline-none focus:border-primaryl focus:ring focus:ring-primaryl focus:ring-opacity-50"
                  {...register("Last_Name")}
                />
                <div className="h-[2vh] pt-1">
                  {errors.Last_Name && (
                    <p className="text-red-500 text-xs leading-none">
                      {errors.Last_Name.message}
                    </p>
                  )}
                </div>
              </div>
              <div>
                <label
                  htmlFor="Email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="Email"
                  name="Email"
                  placeholder="Joe@doe.com"
                  className="mt-1 p-2 block w-full border-b border-gray-300 rounded-t-md shadow-sm focus:outline-none focus:border-primaryl focus:ring focus:ring-primaryl focus:ring-opacity-50"
                  {...register("Email")}
                />
                <div className="h-[2vh] pt-1">
                  {errors.Email && (
                    <p className="text-red-500 text-xs leading-none">
                      {errors.Email.message}
                    </p>
                  )}
                </div>
              </div>
              <div>
                <label
                  htmlFor="Phone_No"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number<span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  id="Phone_No"
                  name="Phone_No"
                  placeholder="+1012 3456 789"
                  className="mt-1 p-2 block w-full border-b border-gray-300 rounded-t-md shadow-sm focus:outline-none focus:border-primaryl focus:ring focus:ring-primaryl focus:ring-opacity-50"
                  {...register("Phone_No")}
                  onKeyDown={handleKeyDown}
                />
                <div className="h-[2vh] pt-1">
                  {errors.Phone_No && (
                    <p className="text-red-500 text-xs leading-none">
                      {errors.Phone_No.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-span-full">
                <label className="block text-sm font-medium text-gray-700">
                  Subject?<span className="text-red-500">*</span>
                </label>
                <div className=" flex items-center">
                  <input
                    type="radio"
                    id="generalInquiry"
                    name="Subject"
                    value="generalInquiry"
                    className="focus:ring-primaryl  h-4 w-4 text-primaryl border-gray-300"
                    {...register("Subject")}
                  />
                  <label
                    htmlFor="generalInquiry"
                    className="ml-2 pt-1 block text-sm text-gray-900"
                  >
                    General Inquiry
                  </label>

                  <input
                    type="radio"
                    id="contractor"
                    name="Subject"
                    value="contractor"
                    className="ml-6  focus:ring-primaryl h-4 w-4 text-primaryl border-gray-300"
                    {...register("Subject")}
                  />
                  <label
                    htmlFor="contractor"
                    className="ml-2  pt-1 block text-sm text-gray-900"
                  >
                    Contractor
                  </label>
                  <input
                    type="radio"
                    id="corporation"
                    name="Subject"
                    value="corporation"
                    className="ml-6 focus:ring-primaryl h-4 w-4 text-primaryl border-gray-300"
                    {...register("Subject")}
                  />
                  <label
                    htmlFor="corporation"
                    className="ml-2  pt-1 block text-sm text-gray-900"
                  >
                    Corporation
                  </label>
                </div>

                <div className="h-[2vh] pt-1">
                  {errors.Subject && (
                    <p className="text-red-500 text-xs leading-none">
                      {errors.Subject.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-span-full mb-3">
                <label
                  htmlFor="Message"
                  className="block text-sm font-medium text-gray-700"
                >
                  Message<span className="text-red-500">*</span>
                </label>
                <textarea
                  id="Message"
                  name="Message"
                  rows="2"
                  placeholder="Write your Message..."
                  className="mt-1 p-2 block w-full border-b border-gray-300 rounded-t-md shadow-sm focus:outline-none focus:border-primaryl focus:ring focus:ring-primaryl focus:ring-opacity-50"
                  {...register("Message")}
                ></textarea>
                <div className="h-[2vh] pt-1">
                  {errors.Message && (
                    <p className="text-red-500 text-xs leading-none">
                      {errors.Message.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-span-full flex justify-end">
                <button
                  type="submit"
                  className="h-10 px-4 py-2.5 border border-cyan-500 flex items-center justify-center text-cyan-500 text-xl euclidbold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800  hover:text-white transition duration-300 ease-in-out "
                >
                  SEND MESSAGE
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default ContactForm;
