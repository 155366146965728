import React from "react";
import { Footer, Header } from "../../components";
import ContactForm from "./ContactForm";

function Contact() {
  return (
    <div className="overflow-hidden">
      <Header></Header>
      <ContactForm/>
      <Footer></Footer>
    </div>
  );
}

export default Contact;
