import React, { useEffect, useState } from "react";
import { RiExternalLinkLine } from "react-icons/ri";
import { FaCalendarDays } from "react-icons/fa6";
import Layout from "../../components/layout/Layout";
import axios from "axios";
import toast from "react-hot-toast";
import { baseUrl } from "../../constants/API";
import Loading from "../../components/Elements/Loading";
import { Link } from "react-router-dom";
import BlogSkeleton from "./BlogSkeleton";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [load, setLoad] = useState(false);

  const featchBlog = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${baseUrl}/blog/publishblogs`);
      console.log(response.data.data);
      setBlogs(response.data.data);
    } catch (error) {
      console.error("Error:", error.response);
      toast.error(
        error.response.data.message ||
          "Something went wrong. Please try again later"
      );
    } finally {
      setLoad(false);
    }
  };

  const dateFormate = (date) => {
    const originalDate = new Date(date);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = originalDate.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  useEffect(() => {
    featchBlog();
  }, []);

  return (
    <Layout>
      <div className="pt-3">
        {load ? (
          <BlogSkeleton />
        ) : blogs.length > 0 ? (
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 sm:px-10 md:px-5 lg:px-10 justify-center">
            {blogs.map((blog, index) => (
              <div
                className="w-full  rounded  overflow-hidden shadow-lg ring-1 ring-gray-200 my-4  px-4 py-2 "
                key={index}
              >
                {blog.Blog_Image ? (
                  <img
                    className="w-full h-48 "
                    src={blog.Blog_Image}
                    alt={blog.Blog_Title}
                  />
                ) : (
                  <div className="w-full h-48 flex justify-center items-center">
                    No Image
                  </div>
                )}

                <div className="pt-4 pb-2">
                  <div className="font-bold text-xl">{blog.Blog_Title}</div>
                </div>
                <div className="pt-1 pb-2 flex justify-between items-center">
                  <Link
                    className="align-middle cursor-pointer font-bold text-center truncate text-sm py-2  w-1/2 rounded-lg bg-primaryl euclidbold ring-cyan-500   text-white   tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white transition duration-300 ease-in-out  shadow-md hover:shadow-lg focus:opacity-85 active:opacity-85 flex justify-center items-center gap-1"
                    to={`/blog/${blog.Blog_Slug}`}
                  >
                    Read More <RiExternalLinkLine />
                  </Link>
                  <div className="truncate">
                    <p className="text-gray-400 text-xs  flex items-center gap-1">
                      <FaCalendarDays />
                      {dateFormate(blog.Blog_Publish_Date)}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center">
            <h2 className="text-2xl font-bold">
              There are no blog posts available right now. Check back later for
              updates!
            </h2>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Blog;
