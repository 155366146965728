import {
  PasswordIcon,
  UserIdIcon,
  LoginVectorLeft,
  LoginVectorRight,
} from "../../assets/images/index";

import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  contractorLoginAsync,
  corporationLoginAsync,
} from "../../store/Auth/Auth";

import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  boardMemberLoginAPI,
  propertyManagerLoginAPI,
} from "../../constants/API";
import AssignPropertyManager from "./AssignPropertyManager";

const notifyfailure = (props) => toast.error(props);

function LoginForm({
  loginRoleText,
  requiredRegister,
  loginFieldName,
  forgotField,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [propertyManagersdata, setpropertyManagerData] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const cookies = new Cookies();

  const schema = yup.object().shape({
    userId: yup.string().required("User Id is required"),
    password: yup.string().required("Password is required"),
  });

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  // Handle form submission
  const onSubmit = async (data) => {
    let api;
    if (location.pathname.includes("boardmember")) {
      api = boardMemberLoginAPI;
    } else {
      api = propertyManagerLoginAPI;
    }

    try {
      let response;
      if (location.pathname.includes("corporation")) {
        response = await dispatch(
          corporationLoginAsync({ data: data, api: api })
        );
      } else if (location.pathname.includes("contractor")) {
        response = await dispatch(contractorLoginAsync(data));
      } else if (location.pathname.includes("owners")) {
        toast.success("In Development", { duration: 1500 });
        return;
      }

      console.log(response);
      const oneHour = 60 * 60 * 4000; // 1 hour in milliseconds
      const expirationDate = new Date(Date.now() + oneHour); // Current time + 1 hour
      if (response.payload) {
        const expirationTime = 60 * 60 * 4000;
        const options = { expires: new Date(Date.now() + expirationTime) };
        cookies.set("token", response.payload.token, options);
        if (location.pathname.includes("corporation")) {
          if (location.pathname.includes("boardmember")) {
            cookies.set("companyType", "corporation", {
              expires: expirationDate,
            });
            cookies.set("access", response.payload.access, options);
            cookies.set("status", response.payload.status, options);

            toast.success("Login Successfully", { duration: 1500 });
            navigate("/corporations/dashboard");
          } else {
            if (response.payload.propertyManagers.length > 1) {
              setpropertyManagerData(response.payload.propertyManagers);
              setShowModel(true);

              cookies.set("companyType", "corporation", {
                expires: expirationDate,
              });
              setpropertyManagerData(response.payload.propertyManagers);
              cookies.set("access", response.payload.access, options);
              cookies.set("status", response.payload.status, options);
              toast.success("Login Successfully", { duration: 1500 });
            } else {
              cookies.set("companyType", "corporation", {
                expires: expirationDate,
              });
              cookies.set("access", response.payload.access, options);
              cookies.set("status", response.payload.status, options);

              toast.success("Login Successfully", { duration: 1500 });
              navigate("/corporations/dashboard");
            }
          }
        } else if (location.pathname.includes("contractor")) {
          cookies.set("companyType", "contractor", {
            expires: expirationDate,
          });
          cookies.set("Profile_Photo", response.payload.profileimg, options);

          cookies.set("status", response.payload.status, options);

          toast.success("Login Successfully", { duration: 1500 });
          navigate("/contractors/contractlisting", {});
        }
      }
    } catch (error) {
      notifyfailure("Some error occurred");
    }
  };

  const handlePropertyManagerSelect = (selectedManagerId) => {
    cookies.set("selectedPropertyManagerId", selectedManagerId, { path: "/" });
    toast.success("Property Manager assigned successfully");
    navigate("/corporations/dashboard");
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = async () => {
    setShowModel(false);
    cookies.remove("access");
    cookies.remove("token");
    cookies.remove("companyType");
    cookies.remove("status");
    window.location.reload();
  };

  return (
    <div className="relative w-full h-[100%] justify-center items-center">
      {showModel && (
        <AssignPropertyManager
          isOpen={showModel}
          onClose={handleClose}
          setShowModel={() => setShowModel(false)}
          propertyManagers={propertyManagersdata}
          onSelect={handlePropertyManagerSelect}
        />
      )}
      <div className="flex justify-center items-center mt-[8%] sm:mt-[9%] md:mt-[3%] lg:mt-[5%] h-[63%]">
        <div className="items-center justify-center">
          <h1 className="text-5xl euclidbold text-[#0893bd] text-center ">
            Welcome
          </h1>
          <p className="text-black text-opacity-50 pt-1 text-sm mt-0 text-center">
            {loginRoleText}
          </p>
          {/* Login Form Start Here  */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="bg-white p-[1%] pt-4 rounded-lg">
              {/* ------------------------ */}
              <div className="relative bg-inherit my-2">
                <input
                  type="text"
                  id="userId"
                  name="userId"
                  {...register("userId")}
                  className={`peer bg-transparent pl-5 h-10 w-72 rounded-lg text-black placeholder-transparent ring-1 px-2 ring-indigo-900 ps-10 focus:ring-sky-600 focus:outline-none focus:border-rose-600 ${
                    errors.userId ? "ring-2 ring-red-500" : ""
                  }`}
                />
                <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                  <UserIdIcon />
                </div>
                <label
                  htmlFor="userId"
                  className={`absolute cursor-text left-0 -top-3 euclidbold text-indigo-900 text-xs bg-inherit mx-1 px-1 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-sky-600  peer-focus:text-sm transition-all ${
                    errors.userId ? "text-red-500" : ""
                  }`}
                >
                  {loginFieldName}
                </label>
              </div>
              {/* Error Message and Forgot User ID */}
              <div className="flex justify-between mb-3">
                <div className="h-[2vh] mt-1">
                  {errors.userId && (
                    <p className="text-red-500 text-xs  leading-none">
                      {errors.userId.message}
                    </p>
                  )}
                </div>
                <div className="h-[2vh] mt-1">
                  {forgotField && (
                    <Link to="/">
                      <p className=" text-right text-xs text-opacity-50 text-black euclidbold leading-none">
                        Forgot your UserID?
                      </p>
                    </Link>
                  )}
                </div>
              </div>

              <div className="relative bg-inherit my-2">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  {...register("password")}
                  className={`peer bg-transparent pl-5 h-10 w-72 rounded-lg text-black placeholder-transparent ring-1 px-2 ring-indigo-900 ps-10 focus:ring-sky-600 focus:outline-none focus:border-rose-600 password"
                   ${errors.password ? "ring-2 ring-red-500" : ""}`}
                />
                <div className="absolute  left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                  <PasswordIcon />
                </div>
                <label
                  htmlFor="password"
                  className={`absolute cursor-text left-0 -top-3 euclidbold text-indigo-900 text-xs bg-inherit mx-1 px-1 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-sky-600 peer-focus:text-sm transition-all ${
                    errors.password ? "text-red-500" : ""
                  }`}
                >
                  Password
                </label>

                <div
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="text-lg mr-2 transition-colors hover:text-gray-500"
                  />
                </div>
              </div>

              {/* Error Message and Forgot Password Link for Password */}
              <div className="flex justify-between mb-3">
                <div className="h-[2vh] mt-1">
                  {errors.password && (
                    <p className="text-red-500 text-xs  leading-none">
                      {errors.password.message}
                    </p>
                  )}
                </div>
                <div className="h-[2vh] mt-1">
                  {(location.pathname.includes("corporation") ||
                    location.pathname.includes("contractor")) &&
                    // Reson of the Reaming Logic of the Tenats and Owner
                    (location.pathname.includes("corporation") ? (
                      loginRoleText.includes("Property Manager") ? (
                        <>
                          <Link
                            to={{
                              pathname: "/corporations/reset",
                              state: {
                                userType: loginRoleText,
                              },
                            }}
                          >
                            <p className="m text-right text-xs text-opacity-50 text-black euclidbold leading-none">
                              Forgot your password?
                            </p>
                          </Link>
                        </>
                      ) : (
                        <Link
                          to={{
                            pathname: "/corporations/boardmember/reset",
                          }}
                        >
                          <p className="m text-right text-xs text-opacity-50 text-black euclidbold leading-none">
                            Forgot your password?
                          </p>
                        </Link>
                      )
                    ) : (
                      <Link
                        to={{
                          pathname: "/contractors/reset",
                          state: {
                            userType: "contractor",
                          },
                        }}
                      >
                        <p className="m text-right text-xs text-opacity-50 text-black euclidbold leading-none">
                          Forgot your password?
                        </p>
                      </Link>
                    ))}
                </div>
              </div>
              {/* Submit Button */}
              <div className="mt-3 mb-3">
                <button
                  type="submit"
                  className="h-10 px-4 py-2.5  border mx-auto border-cyan-500 flex items-center justify-center text-cyan-500 text-xl euclidbold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 focus:outline-none hover:text-white transition duration-300 ease-in-out mt-4 md:mt-0"
                >
                  LOGIN
                </button>
              </div>
            </div>
          </form>

          {/* Go For Signup  */}
          {(location.pathname.includes("corporation") ||
            location.pathname.includes("contractor")) &&
            // Reson of the Reaming Logic of the Tenats and Owner
            requiredRegister && (
              <div>
                {/* OR Divider */}
                <div className="flex items-center justify-center mt-3">
                  <div className="w-1/4 border-t border-gray-300"></div>
                  <span className="px-1 text-black text-sm font-medium leading-none">
                    OR
                  </span>
                  <div className="w-1/4 h-px border-t border-gray-300"></div>
                </div>
                <div className="text-center z-10 mb-3 text-black text-base mt-3">
                  <span className="font-medium">Don’t have an account?</span>

                  {location.pathname.includes("corporation") ? (
                    <Link to="/corporations/signup">
                      <span className="font-bold hover:text-gray-600 euclidbold">
                        {" "}
                        Register Now
                      </span>
                    </Link>
                  ) : (
                    <Link to="/contractors/signup">
                      <span className="font-bold hover:text-gray-600 euclidbold">
                        {" "}
                        Register Now
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
      {/* Login Form Bottom Side Left and Right Img */}
      <div className=" flex h-[25%]  mt-4  mx-[4%]  lg:mx-[16%]  justify-between ">
        <img src={LoginVectorLeft} className="" alt="LogiForm Left Img" />
        <img src={LoginVectorRight} className="" alt="LogiForm Right Img" />
      </div>
    </div>
  );
}

export default LoginForm;
