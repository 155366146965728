import Layout from "../../../components/layout/Layout";
import Reviews from "../../../components/Contractor/Reviews/Reviews";

function ContractorReviews() {
  return (
    <Layout>
      <Reviews />
    </Layout>
  );
}

export default ContractorReviews;
