import axios from "axios";
import React, { useRef, useState } from "react";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import SignatureCanvas from "react-signature-canvas";
import { InputFileIcon, ApprovedIcon, Spinner } from "../../../assets/images";
import { IoImageSharp } from "react-icons/io5";
import { PiSignatureBold } from "react-icons/pi";

const SignatureModel = ({
  isOpen,
  onClose,
  signature,
  setSignature,
  setValue,
  errors,
  uploadingFile,
  signatureError,
  displaySignature,
  handleChangeSignature,
  uploadingSignature,
  signatureUploded,
  setDisplaySignature,
  setSignatureError,
  signatureDrawError,
  setSignatureDrawError,
}) => {
  const [activeTab, setActiveTab] = useState("Image");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const signatureCanvasRef = useRef(null);

  const isCanvasBlank = (canvas) => {
    return !canvas
      .getTrimmedCanvas()
      .getContext("2d")
      .getImageData(
        0,
        0,
        canvas.getTrimmedCanvas().width,
        canvas.getTrimmedCanvas().height
      )
      .data.some((channel) => channel !== 0);
  };

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
    setSignature(null); // Clear the stored image as well
    setDisplaySignature(null);
    setSignatureDrawError("");
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const saveSignature = () => {
    if (isCanvasBlank(signatureCanvasRef.current)) {
      setSignatureDrawError("Please provide a signature before saving.");
      return;
    }
    const signatureDataUrl = signatureCanvasRef.current.toDataURL();
    const signatureFile = dataURLtoFile(signatureDataUrl, "signature.png");
    setSignature(signatureFile);

    setValue("Signature", signatureFile);
    setDisplaySignature(URL.createObjectURL(signatureFile));
    setSignatureError("");
    setSignatureDrawError("");
    onClose();
    // Save the signature image to your server or database if needed
  };

  return (
    <div
      className={`fixed inset-0 z-50 overflow-y-auto
      ${isOpen ? "block" : "hidden"}
      `}
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75  font-bold cursor-pointer">
            <span className="text-white bg-sky-600 p-2  rounded-sm hover:bg-sky-700">
              X
            </span>
          </div>
        </div>

        <div
          className="inline-block z-50 w-[90vw] md:w-[80vw] align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="p-3">
            <h3
              className="text-lg leading-6 euclidbold underline justify-center flex font-medium text-gray-900"
              id="modal-headline"
            >
              Signature Upload / Draw
            </h3>

            <div className="flex flex-row justify-center gap-2 mt-3">
              <div
                className={`px-4 py-2 rounded cursor-pointer items-center justify-center flex gap-1 hover:bg-cyan-700 hover:text-white ${
                  activeTab === "Image"
                    ? "bg-cyan-600 text-white"
                    : "bg-gray-300"
                } mb-2`}
                onClick={() => handleTabClick("Image")}
              >
                <IoImageSharp /> Upload
              </div>
              <div
                className={`px-4 py-2 flex gap-2 items-center justify-center rounded cursor-pointer hover:bg-cyan-700  hover:text-white ${
                  activeTab === "Draw"
                    ? "bg-cyan-600 text-white"
                    : "bg-gray-300"
                } mb-2`}
                onClick={() => handleTabClick("Draw")}
              >
                <PiSignatureBold /> Draw
              </div>
            </div>

            <div className="w-full flex flex-wrap mt-4 justify-start  items-center">
              {/* <p className="text-sm text-gray-500">
                    <span className="font-bold">First Name:</span> f name
                  </p> */}

              {activeTab === "Image" && (
                <div className="flex justify-center w-full">
                  <div>
                    <div className="">
                      <input
                        type="file"
                        id="SignatureUpload"
                        name="SignatureUpload"
                        accept=".jpg,.jpeg,.png"
                        className={`hidden ${
                          uploadingFile
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        }
                      } `}
                        disabled={uploadingFile}
                        onChange={(e) => handleChangeSignature(e)}
                      />

                      {/* Label For style is input tag and use in File input */}
                      <label
                        htmlFor="SignatureUpload"
                        className={`block  bg-transparent w-64 h-64 rounded-sm text-black  focus:ring-sky-600 focus:outline-none   ${
                          signatureError
                            ? "ring-1 ring-red-500"
                            : "ring-1 ring-gray-400"
                        }  ${
                          uploadingFile
                            ? "cursor-not-allowed"
                            : "cursor-pointer bg-transparent"
                        } 
                       ${displaySignature ? "ps-0" : "ps-1"}
                      `}
                        disabled={uploadingFile}
                      >
                        <div className="flex items-center h-full space-x-1  overflow-hidden">
                          {displaySignature ? (
                            <>
                              <img src={displaySignature} alt="Signature" />
                            </>
                          ) : (
                            <>
                              <InputFileIcon />
                              <span className="text-gray-400  text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                                .jpg, .png, .jpeg files only
                              </span>
                            </>
                          )}
                          {/* Reamaing Logic */}
                        </div>
                      </label>
                    </div>
                    {/* Error */}
                    <div className="h-[3vh] pt-0">
                      {uploadingSignature && <Spinner />}

                      {signatureError && (
                        <p className="text-red-500 text-xs leading-none">
                          {signatureError}
                        </p>
                      )}

                      {/* {errors.Signature && (
                        <p className="text-red-500 text-xs leading-none">
                          {errors.Signature.message}
                        </p>
                      )} */}

                      {/* {signatureUploded && (
                      <p className="text-gray-600 text-sm leading-none flex items-center gap-2 mt-2">
                        <ApprovedIcon /> Signature uploaded Successfully!
                      </p>
                    )} */}
                    </div>
                  </div>
                </div>
              )}

              {activeTab === "Draw" && (
                <div className="flex flex-col items-center w-full justify-center space-y-4">
                  <div className="border border-gray-300 rounded-md w-64 h-64">
                    <SignatureCanvas
                      ref={signatureCanvasRef}
                      canvasProps={{
                        className: "signature-canvas w-full h-full pen-cursor",
                      }}
                    />
                  </div>
                  <div className="h-[3vh] pt-0">
                    {signatureDrawError && (
                      <p className="text-red-500 text-xs leading-none">
                        {signatureDrawError}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2 justify-center">
                    <div
                      onClick={clearSignature}
                      className="bg-red-500 cursor-pointer text-white px-4 py-2 rounded-md hover:bg-red-600"
                    >
                      Clear Signature
                    </div>
                    <div
                      onClick={saveSignature}
                      className="bg-blue-500 cursor-pointer text-white px-4 py-2 rounded-md hover:bg-blue-600"
                    >
                      Save Signature
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Close Button */}
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <div
              onClick={onClose}
              type="button"
              className="w-full inline-flex cursor-pointer justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-cyan-500 text-base font-medium text-white hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Close
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignatureModel;
