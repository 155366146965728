import React from "react";
import { Location, Star } from "../../../assets/images";

function ReviewPortfolio({ contractor, cityAreaData, ratings }) {
  const renderRatingStars = () => {
    const stars = [];
    const fullStars = Math.floor(contractor.averageRating);

    for (let i = 0; i < fullStars; i++) {
      stars.push(<Star key={i} />);
    }

    return stars;
  };

  return (
    <div className="sm:w-[100%] lg:w-2/6 h-[100%] border-black lg:border-r lg:border-gray-400 py-[4%] sm:px-[3%] lg:px-0">
      <div className="flex justify-center sm:justify-center lg:justify-end lg:mr-[10%]">
        <div className="">
          <div className="flex justify-center">
            <img
              className="border flex w-64 h-64 md:w-72 md:h-72 rounded-full"
              src={contractor.Profile_Photo}
              alt=""
            />
          </div>
          <div className="euclidbold">
            <div>
              <div>
                <h1 className="text-4xl md:text-5xl mt-4 text-[#0094BF] flex justify-center  ">
                  {contractor.First_Name} {contractor.Last_Name}
                </h1>
                <h2 className="text-2xl mt-2 flex justify-center text-gray-500">
                  {contractor.Designation}
                </h2>
                <div className="text-base mt-2 flex justify-center items-center text-gray-500 space-x-2 ">
                  <Location /> {"  "}
                  <p>
                    {cityAreaData.map((name, index) => {
                      if (index === cityAreaData.length - 1) {
                        return name;
                      } else {
                        return name + " ,";
                      }
                    })}
                  </p>
                </div>
              </div>
            </div>

            <div className="my-5 pl-2">
              <p className="text-2xl">Project Completed: 50</p>
              <p className="text-2xl mt-3">Current Rating: {ratings}</p>
              <div className="flex mt-3 space-x-2 md:space-x-3 ">
                {renderRatingStars()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewPortfolio;
