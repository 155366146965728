import {
  UserIdIcon,
  LoginVectorLeft,
  LoginVectorRight,
  PasswordIcon,
} from "../../assets/images/index";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import Layout from "../../components/layout/Layout";
import axios from "axios";
import { setPasswordAPI } from "../../constants/API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const userType = queryParams.get("userType");
  const schema = yup.object().shape({
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character"
      ),
  });

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    try {
      const res = await axios.post(setPasswordAPI, {
        password: data.password,
        userType: userType,
        token: token,
      });
      if (res.status === 200) {
        navigate("/");
      }
      toast.success("Password changed successfully. Please login.");
    } catch (error) {
      toast.error("Failed to change password. Please try again later.");
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Layout>
      <div className="relative w-full h-[100%] justify-center items-center">
        <div className="flex justify-center items-center mt-[8%] sm:mt-[9%] md:mt-[3%] lg:mt-[5%] h-[63%]">
          <div className="items-center justify-center">
            <h1 className="text-5xl euclidbold text-[#0893bd] text-center ">
              Welcome
            </h1>
            <p className="text-black text-opacity-50 pt-1 text-sm mt-0 text-center">
              Reset You Password
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="bg-white p-[1%] pt-4 rounded-lg">
                {/* ------------------------ */}

                <div className="relative bg-inherit my-2">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    {...register("password")}
                    className={`peer bg-transparent pl-5 h-10 w-72 rounded-lg text-black placeholder-transparent ring-1 px-2 ring-indigo-900 ps-10 focus:ring-sky-600 focus:outline-none focus:border-rose-600 ${
                      errors.password ? "ring-2 ring-red-500" : ""
                    }`}
                  />
                  <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                    <PasswordIcon />
                  </div>
                  <label
                    htmlFor="email"
                    className={`absolute cursor-text left-0 -top-3 euclidbold text-indigo-900 text-xs bg-inherit mx-1 px-1 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-sky-600  peer-focus:text-sm transition-all ${
                      errors.password ? "text-red-500" : ""
                    }`}
                  >
                    Password
                  </label>

                  <div
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      className="text-lg mr-2 transition-colors hover:text-gray-500"
                    />
                  </div>
                </div>
                {/* Submit Button */}
                <div className="mt-3 mb-3">
                  <button
                    type="submit"
                    className="h-10 px-4 py-2.5  border mx-auto border-cyan-500 flex items-center justify-center text-cyan-500 text-xl euclidbold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800  hover:text-white transition duration-300 ease-in-out mt-4 md:mt-0"
                  >
                   Reset Password
                  </button>
                </div>
              </div>
            </form>

            {/* Go For Signup  */}
          </div>
        </div>
        {/* Login Form Bottom Side Left and Right Img */}
        <div className=" flex h-[25%]  mt-4  mx-[4%]  lg:mx-[16%]  justify-between ">
          <img
            src={LoginVectorLeft}
            className="w-34 h-36"
            alt="LogiForm Left Img"
          />
          <img
            src={LoginVectorRight}
            className="w-34 h-36"
            alt="LogiForm Right Img"
          />
        </div>
      </div>
    </Layout>
  );
}

export default ResetPassword;
