import React, { useState } from "react";
import { Chart } from "react-google-charts";

const getQuarter = (date) => {
  const month = date.getUTCMonth() + 1;
  if (month <= 3) return "1st";
  if (month <= 6) return "2nd";
  if (month <= 9) return "3rd";
  return "4th";
};

const processData = (corporations, contractors) => {
  const quarters = {
    "1st": { corporations: 0, contractors: 0 },
    "2nd": { corporations: 0, contractors: 0 },
    "3rd": { corporations: 0, contractors: 0 },
    "4th": { corporations: 0, contractors: 0 },
  };

  corporations.forEach((item) => {
    const date = new Date(item.CreatedDate);
    if (isNaN(date)) {
      console.error(`Invalid date: ${item.createdDate}`);
      return;
    }
    const quarter = getQuarter(date);
    quarters[quarter].corporations += 1;
  });

  contractors.forEach((item) => {
    const date = new Date(item.CreatedDate);
    if (isNaN(date)) {
      console.error(`Invalid date: ${item.createdDate}`);
      return;
    }
    const quarter = getQuarter(date);
    quarters[quarter].contractors += 1;
  });

  return [
    ["Quarter", "Corporations", "Contractors"],
    ["1st", quarters["1st"].corporations, quarters["1st"].contractors],
    ["2nd", quarters["2nd"].corporations, quarters["2nd"].contractors],
    ["3rd", quarters["3rd"].corporations, quarters["3rd"].contractors],
    ["4th", quarters["4th"].corporations, quarters["4th"].contractors],
  ];
};

const options = {
  chart: {
    title: "Corporations & Contractors by Quarter",
  },
  hAxis: {
    title: "Quarter",
  },
  vAxis: {
    title: "Count",
  },
};

const TotalRevenue = ({ corporations, contractors }) => {
  const [selectedQuarter, setSelectedQuarter] = useState("All");

  const handleQuarterChange = (e) => {
    setSelectedQuarter(e.target.value);
  };

  const filterDataByQuarter = (data, quarter) => {
    if (quarter === "All") return data;
    return data.filter((item) => item[0] === quarter);
  };

  const data = processData(corporations, contractors);
  const filteredData = [
    ["Quarter", "Corporations", "Contractors"],
    ...filterDataByQuarter(data.slice(1), selectedQuarter),
  ];

  return (
    <div className="bg-white shadow-xl hover:shadow-2xl flex-col rounded-lg p-4 h-[320px] flex items-center justify-center">
      <h3 className="text-xl font-bold mb-4">
        Corporations & Contractors by Quarter
      </h3>
      <div className="">
        <select
          value={selectedQuarter}
          onChange={handleQuarterChange}
          className="border p-1 rounded"
        >
          <option value="All">All Quarters</option>
          <option value="1st">1st Quarter</option>
          <option value="2nd">2nd Quarter</option>
          <option value="3rd">3rd Quarter</option>
          <option value="4th">4th Quarter</option>
        </select>
      </div>
      <Chart
        chartType="ColumnChart"
        width="90%"
        height="200px"
        data={filteredData}
        options={options}
      />
    </div>
  );
};

export default TotalRevenue;
