import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout/Layout";

function Landing() {
  const navigate = useNavigate();
  return (
    <Layout>
      <div className="w-full flex items-center justify-center h-[70vh] relative landing_page">
        <div className="w-full h-full flex items-center flex-wrap justify-center gap-12">
          <button
            onClick={() =>
              navigate("/corporations/login", {
                state: { companyType: "corporation" },
              })
            }
            className="px-6 py-2.5 ring-1 ring-white hover:ring-transparent  text-white text-xl font-bold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 transition duration-100 ease-in-out "
          >
            Property Manager
          </button>
          <button
            onClick={() =>
              navigate("/corporations/boardmember/login", {
                state: { companyType: "corporation" },
              })
            }
            className=" px-6 ring-1 py-2.5 ring-white hover:ring-transparent text-white text-xl font-bold tracking-widest   hover:bg-gradient-to-r from-cyan-600 to-blue-800  transition duration-100 ease-in-out"
          >
            Board Members
          </button>
        </div>
        <div className="absolute bottom-[-1%] left-0 w-full h-[17%] bg-gradient-to-b from-transparent to-[#0893bd]"></div>
      </div>
    </Layout>
  );
}

export default Landing;


  /* <div
  // className="flex flex-col sm:flex-row gap-12 items-center justify-center"
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center !important",
  }}
>
 
  <div className="relative bottom-[17%] left-0 w-full h-[17%] bg-gradient-to-b from-transparent to-[#0893bd]"></div>
</div>; */
