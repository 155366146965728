import React from "react";
import LoginPageLeft from "../../assets/image/Rectangle 524.png";
import LoginForm from "./LoginForm";
import "./Login.css";
import { useSelector } from "react-redux";
import { LogoWithoutText } from "../../assets";
import Loading from "../Elements/Loading";
function Login({
  loginRoleText,
  requiredRegister = true,
  loginFieldName = "User Id",
  forgotField = true,
}) {
  const loading = useSelector((state) => state.auth.loading);

  return (
    <div className="h-[77.9vh] flex relative login_section">
      <Loading load={loading} />
      {/* First div : images */}
      <div className="relative w-full hidden md:block ">
        <img
          src={LoginPageLeft}
          className="w-full h-[102%] opecity-50"
          alt="Login Page Left"
        />{" "}
        <div className="absolute top-[6%] px-4 py-2 font-normal text-white  bg-cyan-600/50 w-full text-center">
          <h1 className="leading-10 text-4xl">Condominium Portal</h1>
          <p className="pt-1">New Contracting job opportunities everyday!</p>
        </div>
        <div className="absolute hidden bottom-[0%] left-0 w-full h-1/2 bg-gradient-to-b from-transparent to-[#0893bd]  md:block"></div>
        <div className="absolute hidden -bottom-[2%] left-0 w-full h-1/2 bg-gradient-to-b from-transparent to-[#0893bd]  md:block"></div>
      </div>
      {/* Second div : Login form */}
      <LoginForm
        loginRoleText={loginRoleText}
        requiredRegister={requiredRegister}
        loginFieldName={loginFieldName}
        forgotField={forgotField}
      />
    </div>
  );
}

export default Login;
