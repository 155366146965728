import React from "react";
function ApprovedIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
        fill="#028DBA"
        fillOpacity="0.94"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9291 6.23444C15.3519 6.58975 15.4067 7.22055 15.0514 7.64337L10.0093 13.6434C9.83151 13.855 9.57401 13.9836 9.298 13.9986C9.02198 14.0135 8.75207 13.9136 8.55236 13.7225L5.02295 10.3448C4.62394 9.96298 4.61004 9.32997 4.99189 8.93096C5.37374 8.53195 6.00675 8.51804 6.40576 8.89989L9.16448 11.54L13.5202 6.35668C13.8755 5.93386 14.5063 5.87914 14.9291 6.23444Z"
        fill="#028DBA"
        fillOpacity="0.94"
      />
    </svg>
  );
}
export default ApprovedIcon;
