import Layout from "../../../components/layout/Layout";
import Login from "../../../components/Login/Login";

function CorporationLogin() {
  return (
    <div className="overflow-hidden">
      <Layout>
        <Login loginRoleText={"Property Manager Login with UserID"} requiredRegister={true}/>
      </Layout>
    </div>
  );
}

export default CorporationLogin;
