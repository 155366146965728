import React, { useState, useEffect, useRef } from "react";
import { IconDown, IconUp } from "../../assets/images";

const DropDown = ({
  title,
  options,
  onSelect,
  selectOption,
  setValue,
  filedName,
  clearErrors,
  errors,
  dbfield,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleSelectOption = (selectOptionsValue) => {
    onSelect(selectOptionsValue);
    const a = setValue(`${filedName}`, selectOptionsValue);

    if (errors[filedName]) {
      clearErrors(filedName);
    }
    toggleDropdown(); // Close the dropdown after selecting an option
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative inline-block">
      {/* Dropdown button */}
      <button
        className="text-gray-500 w-full h-14  shadow-lg focus:outline-none focus:font-medium rounded-sm text-base px-auto py-2.5 text-center inline-flex min-w-44 items-center"
        onClick={toggleDropdown}
        type="button"
      >
        <span className="grid grid-cols-2 items-center px-2 truncate">
          <p className="">
            {selectOption
              ? options.find((item) => item.ID === selectOption)?.[dbfield]
              : title}
          </p>
          <p className="justify-end flex">
            {isOpen ? <IconUp /> : <IconDown />}
          </p>
        </span>
      </button>

      {/* Dropdown menu */}
      {isOpen && options && (
        <div className="absolute z-50 max-h-44 overflow-auto top-full left-0 shadow-xl bg-white divide-y divide-gray-100 rounded-sm min-w-40 w-full text-gray-800">
          <ul className="py-1 text-base text-gray-700 cursor-pointer">
            {options?.map((item, index) => (
              <li key={index} onClick={() => handleSelectOption(item.ID)}>
                <div className="block px-4 py-2 hover:bg-gray-100 hover:text-gray-950">
                  {item[dbfield]}
                </div>
                {index !== options.length - 1 && (
                  <div className="border-t border-gray-300"></div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      {/* Error */}
      <div className="h-[3vh] pt-1">
        {errors[filedName] && (
          <p className="text-red-500 text-xs leading-none">
            {errors[filedName].message}
          </p>
        )}
      </div>
    </div>
  );
};

export default DropDown;
