import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/layout/Layout";
import uimg from "../../assets/image/user.png";
import "../../styles.css";
import { InputFileIcon } from "../../assets/images";
import Cookies from "universal-cookie";
import { fetchChats, fetchContractorMessages } from "../../constants/Request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { LogoWithoutText } from "../../assets";
import toast from "react-hot-toast";
import { io } from "socket.io-client";
import { baseUrl } from "../../constants/API";

const socket = io(baseUrl);

function ContractorMessages() {
  const [activeTab, setActiveTab] = useState("inbox");
  const [selectedUser, setSelectedUser] = useState(null);
  const content = useRef();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const cookies = new Cookies();
  let token = cookies.get("token");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSelectedUser(null);
  };

  const handleUserClick = async (user) => {
    try {
      const res = await fetchChats(user.ID);
      setMessages(res.data);
    } catch (error) {
      toast.error("Error fetching messages");
    } finally {
      setSelectedUser(user);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const meridiem = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const time = `${formattedHours}:${formattedMinutes} ${meridiem}`;
    return [`${day} ${monthNames[monthIndex]} ${year}`, time];
  };

  useEffect(() => {
    const handleCreateChat = (data) => {
      console.log(messages);
      if (selectedUser && data.chat.Proposal_ID === selectedUser.ID) {
        setMessages((prevMessages) => [...prevMessages, data.chat]);
        console.log("messages");
      }
    };

    socket.on("receiveMessage", handleCreateChat);
    socket.on("createMessageResponse", () => console.log("created"));

    const getUsers = async () => {
      setLoading(true);
      try {
        const res = await fetchContractorMessages(token);
        const formattedUsers = res.map((user) => ({
          ...user,
          createdAt: formatDate(user.contract.CreatedDate),
        }));
        setUsers(formattedUsers);
      } catch (error) {
        toast.error("Failed to get users");
      } finally {
        setLoading(false);
      }
    };

    getUsers();

    return () => {
      socket.off("receiveMessage");
    };
  }, [token, selectedUser]);

  const createMessages = async (id) => {
    const messageContent = content.current.value;
    if (messageContent.trim() !== "") {
      const data = {
        Proposal_ID: id,
        Sender: "contractor",
        Content: messageContent,
      };
      try {
        // Emit the createMessage event to the server
        socket.emit("createMessage", data, (response) => {
          console.log(response);
        });
        content.current.value = ""; // Clear the input after sending the message
      } catch (error) {
        toast.error("Failed to create messages");
      }
    }
  };

  return (
    <Layout>
      {loading && (
        <div className="absolute z-50 flex items-center justify-center h-full w-full bg-black opacity-55">
          <div className="loginLoader"></div>
          <img
            src={LogoWithoutText}
            className="lazy_image absolute"
            height={100}
            width={100}
            alt=""
          />
        </div>
      )}
      <div className="text-center px-[10%]">
        <h1 className="text-4xl font-bold mb-4 text-primaryl euclidbold">
          Messages
        </h1>
        <hr className="my-4" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-6 gap-6 px-[10%] m-auto">
        {/* First Column */}
        <div className="col-span-2 md:shadow-md h-fit w-[78vw] justify-between md:w-auto flex md:flex-col flex-wrap md:gap-2">
          <div
            className={`font-bold cursor-pointer text-start p-2 ${
              activeTab === "inbox" ? "bg-blue-900 text-white" : ""
            } ${activeTab !== "inbox" ? "hover:bg-gray-100" : ""}`}
            onClick={() => handleTabClick("inbox")}
          >
            Inbox
          </div>
          <div
            className={`font-bold cursor-pointer text-start p-2 ${
              activeTab === "send" ? "bg-blue-900  text-white" : ""
            } ${activeTab !== "send" ? "hover:bg-gray-100" : ""}`}
            onClick={() => handleTabClick("send")}
          >
            Send
          </div>
          <div
            className={`font-bold cursor-pointer text-start p-2 ${
              activeTab === "important" ? "bg-blue-900  text-white" : ""
            } ${activeTab !== "important" ? "hover:bg-gray-100" : ""}`}
            onClick={() => handleTabClick("important")}
          >
            Important
          </div>
          <div
            className={`font-bold cursor-pointer text-start p-2 ${
              activeTab === "drafts" ? "bg-blue-900  text-white" : ""
            } ${activeTab !== "drafts" ? "hover:bg-gray-100" : ""}`}
            onClick={() => handleTabClick("drafts")}
          >
            Drafts
          </div>
          <div
            className={`font-bold cursor-pointer text-start p-2 ${
              activeTab === "compose" ? "bg-blue-900 text-white" : ""
            } ${activeTab !== "compose" ? "hover:bg-gray-100" : ""}`}
            onClick={() => handleTabClick("compose")}
          >
            Compose
          </div>
        </div>

        {/* Second Column */}
        <div className="col-span-4 md:col-span-4">
          <div className="flex justify-between mb-3">
            <input
              type="text"
              placeholder="Search"
              className="w-3/5 px-2 border-b border-gray-300 focus:outline-none"
            />
            <select className="w-2/5 px-2 mx-1 border-b border-gray- focus:outline-none">
              <option value="default">All Projects</option>
              <option value="ongoing">Ongoing Projects</option>
              <option value="expired">Expired Projects</option>
            </select>
          </div>
          <div className="flex justify-between mb-4 text-primaryl">
            <button>All</button>
            <button>Unread</button>
            <button>Marked</button>
            <button>Oldest</button>
            <button>Newest</button>
          </div>
          <div className="border-[1px] rounded-sm border-gray-300 mb-2 scrollable-list">
            <div className="scrollable-list-inner">
              {users.map((user) => (
                <div
                  key={user.ID}
                  className={`scrollable-list-item cursor-pointer ${
                    selectedUser?.ID === user.ID
                      ? "bg-primaryl text-white p-4"
                      : "p-4"
                  } ${selectedUser?.ID !== user.ID ? "hover:bg-gray-100" : ""}`}
                  onClick={() => handleUserClick(user)}
                >
                  <div className="flex gap-6 items-center justify-between w-full">
                    <div className="flex">
                      <img
                        src={uimg}
                        alt="User Logo"
                        className="w-14 rounded-full"
                      />
                      <div className="italic ml-4">
                        <h3
                          className={`text-sm ${
                            selectedUser?.ID === user.ID
                              ? "text-white"
                              : "text-gray-500"
                          }`}
                        >
                          {user.corporation.Corporation_Name}
                        </h3>
                        <p
                          className={
                            selectedUser?.ID === user.ID
                              ? "text-white"
                              : "text-gray-800"
                          }
                        >
                          {user.contract.Project_Name}
                        </p>
                      </div>
                    </div>
                    <div>
                      <h3
                        className={`text-sm text-right ${
                          selectedUser?.ID === user.ID
                            ? "text-white"
                            : "text-gray-500"
                        }`}
                      >
                        {formatDate(user.contract.CreatedDate)}
                      </h3>
                    </div>
                  </div>
                  <div className="ml-[29%] text-[10px] italic">
                    <p
                      className={
                        selectedUser?.ID === user.ID
                          ? "text-white line-clamp-2"
                          : "text-gray-500 line-clamp-2"
                      }
                    >
                      {user.contract.Project_Description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Chat Model */}
      {selectedUser && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg w-11/12 md:w-3/5 max-h-[90vh] overflow-y-auto scrollable-list">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <img src={uimg} alt="" className="w-14 rounded-full" />
                <h3 className="text-secondaryl italic">
                  {selectedUser.corporation.Corporation_Name}
                </h3>
              </div>
              <button
                onClick={() => setSelectedUser(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                &times;
              </button>
            </div>
            <div className="p-2 border-gray-300 border-[1px]">
              <div className="flex items-center">
                <p className="text-secondaryl text-xl">
                  {selectedUser.contract.Project_Name}
                </p>
              </div>
              <p className="">To: Me</p>
              {/* <p className="py-2 text-gray-500">
                <span className="">{selectedUser.Other_Details}</span>
              </p> */}
              <hr className="" />
              <div className="flex justify-between flex-col">
                <div>Attachments:</div>
                <div className="flex justify-between">
                  <span className="mr-2 text-gray-500">Download</span>
                  <NavLink
                    to={selectedUser.Attach_Proposal}
                    target="_blank"
                    download
                    className="mr-2"
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </NavLink>
                </div>
              </div>
              <hr className="mb-3" />
              <span className="text-secondaryl ">Messages</span> <br />
              <p className=" text-gray-500 h-[35vh] scrollable-list p-2">
                {messages?.map((message, index) => (
                  <span
                    key={index}
                    className={`${
                      message.Sender === "corporation"
                        ? "text-left leading-[4px] block bg-gray-100 p-2 pb-0"
                        : "text-right leading-[4px] block text-black p-2 pb-0"
                    }`}
                  >
                    {message.Content}
                  </span>
                ))}
              </p>
              <input
                type="text"
                ref={content}
                placeholder="Type your message..."
                className="w-full h-[40px] px-4 py-2 border border-gray-300 focus:outline-none"
              />
              <div className="mt-4">
                <div>
                  <button className="text-blue-900">Attach File(s)</button>
                </div>
                <div className="flex justify-between items-center">
                  <div>
                    <div className="">
                      <div className="mb-2">
                        <label
                          className={`block font-normal  text-[12px] sm:text-[12px] md:text-base `}
                        ></label>
                        <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-2 gap-4 items-center md:gap-16 md:mr-4 lg:mr-20">
                          <div className="col-span-1 sm:col-span-1 md:col-span-1">
                            <input
                              type="file"
                              id="companyContractDocs"
                              name="companyContractDocs"
                              className="hidden"
                            />
                            <label
                              htmlFor="companyContractDocs"
                              className={`block bg-transparent cursor-pointer h-10 w-full rounded-sm text-black  ring-1 px-2 ring-gray-400 ps-10 focus:ring-sky-600 focus:outline-none`}
                            >
                              <div className="flex items-center h-full space-x-1 overflow-hidden">
                                <InputFileIcon />
                              </div>
                            </label>
                          </div>
                          <div className="text-sm px-2 py-1 h-fit euclidbold cursor-pointer ring-1  ring-cyan-500 flex  justify-center items-center text-cyan-500 sm:text-xl  hover:ring-1 hover:ring-cyan-600 tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800  hover:text-white transition duration-300 ease-in-out  mt-[1px] ">
                            UPLOAD
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => createMessages(selectedUser.ID)}
                    className="px-6 py-2.5 items-center ring-1  euclidbold ring-cyan-500   text-cyan-500 text-xl  tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:ring-1 hover:ring-cyan-600 hover:text-white transition duration-300 ease-in-out"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default ContractorMessages;
