import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import axios from "axios";
import { baseUrl, serviesAPI } from "../../constants/API";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { getServies } from "../../constants/Request";
import { IoMdAdd } from "react-icons/io";
import { set } from "react-hook-form";

const UpdateServiceModal = ({
  isOpen,
  onClose,
  updateService,
  selectedService,
  isEditModel,
  addNewService,
}) => {
  const [serviceName, setServiceName] = useState("");

  useEffect(() => {
    if (isEditModel) {
      setServiceName(selectedService?.Name);
    }
  }, [selectedService]);

  const handleSubmit = () => {
    if (serviceName.trim() === "") {
      toast.error("Service Name is required");
      return;
    }

    if (isEditModel) {
      if (serviceName === selectedService.Name) {
        onClose();
        return;
      }
      console.log(serviceName);
      updateService(serviceName);
      setServiceName("");
    } else {
      addNewService(serviceName);
      setServiceName("");
    }
    onClose();
  };

  useEffect(() => {
    if (!isEditModel) {
      setServiceName("");
    }
  }, [onClose]);

  return (
    <div
      className={`${
        isOpen ? "block" : "hidden"
      } fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center`}
    >
      <div className="bg-white w-4/5 rounded-lg p-8">
        <h2 className="text-xl font-bold mb-4">
          {" "}
          {isEditModel ? "Update " : "New "} Service Name :{" "}
        </h2>

        <>
          <input
            type="text"
            placeholder="Service Name"
            className="border border-gray-300 rounded-md p-2 mb-4 w-full"
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value)}
          />

          <div className="flex justify-end">
            <button
              className="bg-primaryl hover:bg-opacity-70 text-white px-4 py-2 rounded-md mr-2"
              onClick={handleSubmit}
            >
              Save
            </button>
            <button
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

const ManageServices = ({
  setLoad,
  services,
  setServices,
  setServiceCount,
}) => {
  const [selectedService, setSelectedService] = useState(null);
  const [isEditModel, setIsEditModel] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const cookies = new Cookies();

  const fetchServices = async () => {
    setLoad(true);
    try {
      const response = await getServies();
      // console.log(response);
      setServices(response);
      setServiceCount(response.length);
      setServices(response.data.data);
    } catch (error) {
      console.error("Error fetching services:", error);
    } finally {
      setLoad(false);
    }
  };

  const updateService = async (serviceName) => {
    setLoad(true);

    try {
      const response = await axios.put(
        `${serviesAPI}/${selectedService.ID}`,
        {
          Name: serviceName,
        },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: "admin",
          },
        }
      );
      toast.success("Service Name Updated Successfully");
      fetchServices();

      // console.log(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
      setIsEditModel(false);
    }
  };

  const addNewService = async (serviceName) => {
    setLoad(true);
    try {
      const response = await axios.post(
        serviesAPI,
        {
          Name: serviceName,
        },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: "admin",
          },
        }
      );
      toast.success("Service Added Successfully");
      fetchServices();
      // console.log(response);
    } catch (error) {
      toast.error("Error Adding Service");
      console.error(error);
    } finally {
      setLoad(false);
    }
  };

  const handleUpdateClick = (service) => {
    setIsEditModel(true);
    setSelectedService(service);
    setIsModalOpen(true);
  };

  const handleAddNewClick = () => {
    setIsEditModel(false);
    setIsModalOpen(true);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Manage Services</h2>
      <div className="flex flex-col">
        <div className="flex justify-center">
          <div className="overflow-auto max-h-[60vh] w-full table-container">
            <div className="mb-4">
              <button
                onClick={handleAddNewClick}
                className="mr-2 px-4 py-2 flex gap-2 items-center rounded-md bg-primaryl text-white font-bold"
              >
                Add New <IoMdAdd />
              </button>
            </div>
            <table className="table-auto w-full relative">
              <thead className="relative">
                <tr>
                  <th className="px-4 py-2 text-sm">Service Name </th>

                  <th className="px-4 py-2 text-sm">Update</th>
                </tr>
              </thead>
              <tbody>
                {services?.map((service) => (
                  <tr key={service.ID}>
                    <td className="border px-4 py-2">{service.Name}</td>
                    <td className="border px-4 py-2">
                      <FaEdit
                        className="text-cyan-500 cursor-pointer"
                        onClick={() => handleUpdateClick(service)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <UpdateServiceModal
        isOpen={isModalOpen}
        onClose={() => {
          setSelectedService(null);
          setIsModalOpen(false);
        }}
        updateService={(serviceName) => {
          updateService(serviceName);
        }}
        selectedService={selectedService}
        isEditModel={isEditModel}
        addNewService={addNewService}
      />
    </div>
  );
};

export default ManageServices;
