import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { ownerAPI } from "../../../constants/API.js";
import {
  ContractorProfileLeftImage,
  Spinner,
} from "../../../assets/images/index.jsx";
import InputBox from "../../../components/Elements/InputBox.jsx";
import InputBoxLive from "../../../components/Elements/InputBoxLive.jsx";
import Loading from "../../../components/Elements/Loading.jsx";
import { IoMdPerson } from "react-icons/io";
import { PiIdentificationCardFill } from "react-icons/pi";
import { FaHouseUser } from "react-icons/fa";
import { BsFillHouseCheckFill } from "react-icons/bs";
import Layout from "../../../components/layout/Layout.jsx";
import { toast } from "react-hot-toast";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

function CreateOwners() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [Proof_Of_Ownership, setProof_Of_Ownership] = useState(null);
  const [Proof_Of_Ownership_Error, setProof_Of_Ownership_Error] =
    useState(null);
  const [Id_Proof_Document, setId_Proof_Document] = useState(null);
  const [Id_Proof_Document_Error, setId_Proof_Document_Error] = useState(null);

  // Form validation schema using yup
  const schema = yup.object().shape({
    First_Name: yup.string().required("First Name is required"),
    Last_Name: yup.string().required("LastName is required"),
    Email: yup
      .string()
      .required("Email is required")
      .matches(
        /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/,
        "Invalid recovery email"
      )
      .test(
        "not-same-as-uidOrEmail",
        "Recovery email cannot be the same as user email id",
        function (value) {
          const Uid_Or_Email = this.parent.Uid_Or_Email;
          // If either of the fields is empty, skip the validation
          if (!value || !Uid_Or_Email) return true;
          return value !== Uid_Or_Email;
        }
      ),
    Phone_No: yup
      .string()
      .required("Phone No is required")
      .matches(
        /^[0-9]{10}$/, // Regular expression for 10-digit phone number
        "Phone No must be exactly 10 digits"
      ),
    Date_Of_Birth: yup.string().required("Date of Birth is required"),
    Residential_Address: yup.string().required("Home Address is required"),
    Id_Proof_Document: yup.string().required("Id Proof Document is required"),
    Property_Name: yup.string().required("Property Name is required"),
    Property_Type: yup.string().required("Property Type is required"),
    Proof_Of_Ownership: yup
      .string()
      .required("Proof Of Ownership Document is required"),
  });

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError,
  } = useForm({ resolver: yupResolver(schema) });

  const onOwnerSignUp = async (data, e) => {
    e.preventDefault();
    setLoad(true);

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.set(key, data[key]);
    });

    if (Proof_Of_Ownership) {
      console.log("Proof_Of_Ownership", Proof_Of_Ownership);

      formData.set("Proof_Of_Ownership", Proof_Of_Ownership);
    }

    if (Id_Proof_Document) {
      console.log("Id_Proof_Document", Id_Proof_Document);
      formData.set("Id_Proof_Document", Id_Proof_Document);
    }

    try {
      const response = await axios.post(ownerAPI, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: cookies.get("token"),
          companyType: "corporation",
        },
      });

      console.log("response", response);
      toast.success(response.data.message);
      reset();
      navigate("/corporations/dashboard");
    } catch (error) {
      toast.error(error.response.data.message);
      console.log("data", data);
      setLoad(false);
    }
  };

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
  ];
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024;

  const handleChangeProofOfOwnership = (e) => {
    setUploadingFile(true);
    const file = e.target.files[0];

    if (!file) {
      setProof_Of_Ownership_Error("Please Upload a file");
      clearErrors("Proof_Of_Ownership");
      setValue("Proof_Of_Ownership", "");
      setProof_Of_Ownership(null);
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT &&
        SUPPORTED_FORMATS.includes(file.type)
      ) {
        setProof_Of_Ownership_Error("");
        clearErrors("Proof_Of_Ownership");
        setValue("Proof_Of_Ownership", file);
        setProof_Of_Ownership(file);
      } else if (file && !SUPPORTED_FORMATS.includes(file.type)) {
        setProof_Of_Ownership_Error(
          "Unsupported file type file type must be jpg/jpeg/png/pdf"
        );
        clearErrors("Proof_Of_Ownership");
        setValue("Proof_Of_Ownership", "");

        setProof_Of_Ownership(null);
      } else if (file && !file.size <= FILE_SIZE_LIMIT) {
        setProof_Of_Ownership_Error(
          "File size exceeds limit. File size must be less than 5 MB"
        );
        clearErrors("Proof_Of_Ownership");
        setValue("Proof_Of_Ownership", "");
        setProof_Of_Ownership(null);
      } else {
        setProof_Of_Ownership_Error(
          "Something went wrong please try again later"
        );
        clearErrors("Proof_Of_Ownership");
        setValue("Proof_Of_Ownership", "");
        setProof_Of_Ownership(null);
      }
    }
    setUploadingFile(false);
  };

  const handleChangeIdProofDocument = (e) => {
    setUploadingFile(true);
    const file = e.target.files[0];

    if (!file) {
      setId_Proof_Document_Error("Please Upload a file");
      clearErrors("Id_Proof_Document");
      setValue("Id_Proof_Document", "");
      setId_Proof_Document(null);
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT &&
        SUPPORTED_FORMATS.includes(file.type)
      ) {
        setId_Proof_Document_Error("");
        clearErrors("Id_Proof_Document");
        setValue("Id_Proof_Document", file);
        setId_Proof_Document(file);
      } else if (file && !SUPPORTED_FORMATS.includes(file.type)) {
        setId_Proof_Document_Error(
          "Unsupported file type file type must be jpg/jpeg/png/pdf"
        );
        clearErrors("Id_Proof_Document");
        setValue("Id_Proof_Document", "");

        setId_Proof_Document(null);
      } else if (file && !file.size <= FILE_SIZE_LIMIT) {
        setId_Proof_Document_Error(
          "File size exceeds limit. File size must be less than 5 MB"
        );
        clearErrors("Id_Proof_Document");
        setValue("Id_Proof_Document", "");
        setId_Proof_Document(null);
      } else {
        setId_Proof_Document_Error(
          "Something went wrong please try again later"
        );
        clearErrors("Id_Proof_Document");
        setValue("Id_Proof_Document", "");
        setId_Proof_Document(null);
      }
    }
    setUploadingFile(false);
  };

  const documentClearError = () => {
    setProof_Of_Ownership_Error("");
    setId_Proof_Document_Error("");
  };

  return (
    <Layout>
      <Loading load={load} />
      <div className="overflow-hidden overflow-x-hidden">
        <div className="min-h-screen w-full flex relative ">
          {/* First div : images */}
          <div className="w-[70%] hidden md:block ">
            <img
              src={ContractorProfileLeftImage}
              className="absolute top-0 bottom-0 w-[40%] min-h-screen object-cover"
              alt="Singup background"
            />
          </div>

          <div className="absolute hidden bottom-[-1%] left-0 w-full md:w-[40%] h-[40%] bg-gradient-to-b from-transparent to-[#0893bd]  md:block"></div>
          <div className=" w-full h-full pb-[3%] md:pl-[7%] md:pr-[2%] mx-3 sm:mx-3 md:mx-0">
            <div className="">
              <div className="mx-auto">
                <div className="container sm:mx-auto mx-auto">
                  <h1 className="text-5xl mt-7 md:mt-0 euclidbold text-[#0893bd] flex justify-center  sm:justify-center lg:justify-start">
                    Create Owner Account
                  </h1>
                  <p className="mt-1 text-black/50 text-opacity-50  text-base leading-tight flex justify-center  sm:justify-center lg:justify-start ">
                    Welcome to the Condominium Portal !
                  </p>

                  {/* SignUp Form Start Here  */}
                  <form
                    onSubmit={handleSubmit(onOwnerSignUp)}
                    encType="multipart/form-data"
                  >
                    <div className="bg-white rounded-sm mt-[5%]">
                      {/* First Name and Last Name */}
                      <p className=" text-3xl euclidbold text-[#0893bd] leading-tight flex items-center justify-start mb-4 underline gap-2 ">
                        <IoMdPerson />
                        Personal information
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-20">
                        <InputBox
                          title={"First Name"}
                          register={register}
                          filedName={"First_Name"}
                          must={true}
                          errors={errors}
                          className={"md:text-sm lg:text-base"}
                        />

                        <InputBox
                          title={"Last Name"}
                          register={register}
                          filedName={"Last_Name"}
                          must={true}
                          errors={errors}
                          className={"md:text-sm lg:text-base"}
                        />
                      </div>

                      {/*  Email And Phone No */}
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-20">
                        <InputBoxLive
                          title={"Email"}
                          register={register}
                          filedName={"Email"}
                          must={true}
                          errors={errors}
                          className={"md:text-sm lg:text-base"}
                          schema={schema}
                          setError={setError}
                        />

                        <InputBoxLive
                          title={"Phone No"}
                          register={register}
                          filedName={"Phone_No"}
                          must={true}
                          type="tel"
                          errors={errors}
                          className={"md:text-sm lg:text-base"}
                          maxLength="10"
                          schema={schema}
                          setError={setError}
                        />
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-20">
                        <InputBoxLive
                          type="date"
                          title={"Date of Birth"}
                          register={register}
                          filedName={"Date_Of_Birth"}
                          must={true}
                          errors={errors}
                          className={"md:text-sm lg:text-base"}
                          schema={schema}
                          setError={setError}
                          max={new Date().toISOString().split("T")[0]}
                        />
                      </div>

                      <p className=" text-3xl euclidbold text-[#0893bd] leading-tight flex items-center justify-start mb-4 underline gap-2 ">
                        <FaHouseUser />
                        Address Information
                      </p>
                      {/* Residential Address  */}
                      <div className="grid grid-cols-1  md:mr-4 lg:mr-20">
                        <InputBox
                          title={"Residential Address"}
                          register={register}
                          filedName={"Residential_Address"}
                          must={true}
                          errors={errors}
                          className={"md:text-sm lg:text-base"}
                        />
                      </div>

                      <p className=" text-3xl euclidbold text-[#0893bd] leading-tight flex items-center justify-start mb-4 underline gap-2 ">
                        <PiIdentificationCardFill />
                        Identification Information
                      </p>

                      <div className="">
                        <label className={`block font-normal text-base `}>
                          Id Proof Document
                          <span className="text-red-500">*</span>
                        </label>
                        <div className="flex items-center gap-5">
                          <input
                            type="file"
                            id="Id_Proof_Document"
                            name="Id_Proof_Document"
                            className={`hidden ${
                              uploadingFile
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            } `}
                            onChange={handleChangeIdProofDocument}
                            disabled={uploadingFile}
                            accept=".jpg,.jpeg,.png,application/pdf"
                          />

                          <label
                            htmlFor="Id_Proof_Document"
                            className={`block bg-transparent h-10 w-full pt-2 rounded-sm text-black  px-2  ps-10 focus:ring-sky-600 focus:outline-none focus:border-rose-600 ${
                              Id_Proof_Document_Error
                                ? "ring-1 ring-red-500"
                                : "ring-1 ring-gray-400"
                            }  ${
                              uploadingFile
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            } `}
                            disabled={uploadingFile}
                          >
                            {Id_Proof_Document ? (
                              <span className="line-clamp-1">
                                {Id_Proof_Document.name}
                              </span>
                            ) : (
                              <span className="text-gray-400 truncate   text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                                Upload Id Proof Document
                              </span>
                            )}
                          </label>
                        </div>
                        {/* Error */}
                        <div className="h-[3vh] mb-3">
                          {uploadingFile && <Spinner />}
                          {Id_Proof_Document_Error && (
                            <p className="text-red-500 text-xs leading-none">
                              {Id_Proof_Document_Error}
                            </p>
                          )}
                          {errors.Id_Proof_Document && ( // Error message from yup validation
                            <p className="text-red-500 text-xs leading-none">
                              {errors.Id_Proof_Document.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <p className=" text-3xl euclidbold text-[#0893bd] leading-tight flex items-center justify-start mb-4 underline gap-2 ">
                        <BsFillHouseCheckFill />
                        Property Details
                      </p>

                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-20">
                        <InputBox
                          title={"Property Name"}
                          register={register}
                          filedName={"Property_Name"}
                          must={true}
                          errors={errors}
                          className={"md:text-sm lg:text-base"}
                        />
                        <InputBox
                          title={"Property Type"}
                          register={register}
                          filedName={"Property_Type"}
                          must={true}
                          errors={errors}
                          className={"md:text-sm lg:text-base"}
                        />
                      </div>

                      <div className="">
                        <label className={`block font-normal text-base `}>
                          Proof of Ownership
                          <span className="text-red-500">*</span>
                        </label>
                        <div className="flex items-center gap-5">
                          <input
                            type="file"
                            id="Proof_Of_Ownership"
                            name="Proof_Of_Ownership"
                            className={`hidden ${
                              uploadingFile
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            } `}
                            onChange={handleChangeProofOfOwnership}
                            disabled={uploadingFile}
                            accept=".jpg,.jpeg,.png,application/pdf"
                          />

                          <label
                            htmlFor="Proof_Of_Ownership"
                            className={`block bg-transparent h-10 w-full pt-2 rounded-sm text-black  px-2  ps-10 focus:ring-sky-600 focus:outline-none focus:border-rose-600 ${
                              Proof_Of_Ownership_Error
                                ? "ring-1 ring-red-500"
                                : "ring-1 ring-gray-400"
                            }  ${
                              uploadingFile
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            } `}
                            disabled={uploadingFile}
                          >
                            {Proof_Of_Ownership ? (
                              <span className="line-clamp-1">
                                {Proof_Of_Ownership.name}
                              </span>
                            ) : (
                              <span className="text-gray-400 truncate   text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                                Upload Proof of Ownership
                              </span>
                            )}
                          </label>
                        </div>
                        {/* Error */}
                        <div className="h-[3vh] mb-3">
                          {uploadingFile && <Spinner />}
                          {Proof_Of_Ownership_Error && (
                            <p className="text-red-500 text-xs leading-none">
                              {Proof_Of_Ownership_Error}
                            </p>
                          )}
                          {errors.Proof_Of_Ownership && ( // Error message from yup validation
                            <p className="text-red-500 text-xs leading-none">
                              {errors.Proof_Of_Ownership.message}
                            </p>
                          )}
                        </div>
                      </div>

                      {/* Submit Button */}
                      <div className=" mb-3">
                        <button
                          type="submit"
                          className={`h-11  px-6 py-2.5 items-center ring-1  euclidbold ring-cyan-500   text-cyan-500 text-xl  tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:ring-1 hover:ring-cyan-600 hover:text-white transition duration-300 ease-in-out mt-4 md:mt-0 flex focus:outline-none
                     ${
                       uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                     }`}
                          onClick={documentClearError}
                          disabled={uploadingFile}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CreateOwners;
