import React from "react";

function HalfStar({className}) {
  return (
    <svg
    className={className}
      width="21"
      height="40"
      viewBox="0 0 21 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9797 38.447L20.2168 32.0193V1.13836C20.2168 0.471506 20.2168 0.138031 20.1333 0.0634814C20.0616 -0.000493568 19.9596 -0.0177435 19.8708 0.0191815C19.7673 0.0621565 19.6578 0.377131 19.4391 1.00703L15.0898 13.5192C14.9251 13.993 14.8427 14.2299 14.6943 14.4063C14.5632 14.5622 14.3961 14.6836 14.2073 14.7602C13.9937 14.8468 13.7429 14.8519 13.2414 14.8621L3.38176 15.063C1.61961 15.099 0.738565 15.1168 0.38694 15.4525C0.0826151 15.743 -0.0551601 16.167 0.0202899 16.581C0.10749 17.059 0.809715 17.5915 2.21421 18.6563L10.0727 24.6143C10.4725 24.9173 10.6723 25.0688 10.7943 25.2645C10.902 25.4373 10.9658 25.6338 10.9803 25.837C10.9966 26.067 10.924 26.307 10.7787 26.787L7.92304 36.2263C7.41267 37.9133 7.15749 38.7568 7.36802 39.195C7.55024 39.574 7.91099 39.8363 8.32792 39.8923C8.80967 39.9573 9.53302 39.4538 10.9797 38.447Z"
        fill="#FFC107"
      />
    </svg>  
  );
}

export default HalfStar;
