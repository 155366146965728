import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import axios from "axios";
import { baseUrl, cityareaAPI, serviesAPI } from "../../constants/API";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { getCityArea, getServies } from "../../constants/Request";
import { IoMdAdd } from "react-icons/io";
import { set } from "react-hook-form";

const UpdateAreaModal = ({
  isOpen,
  onClose,
  updateArea,
  selectedArea,
  isEditModel,
  addNewArea,
}) => {
  const [areaName, setAreaName] = useState("");

  useEffect(() => {
    if (isEditModel) {
      setAreaName(selectedArea?.Name);
    }
  }, [selectedArea]);

  const handleSubmit = () => {
    if (areaName.trim() === "") {
      toast.error("Area Name is required");
      return;
    }

    if (isEditModel) {
      if (areaName === selectedArea.Name) {
        onClose();
        return;
      }
      console.log(areaName);
      updateArea(areaName);
      setAreaName("");
    } else {
      addNewArea(areaName);
      setAreaName("");
    }
    onClose();
  };

  useEffect(() => {
    if (!isEditModel) {
      setAreaName("");
    }
  }, [onClose]);

  return (
    <div
      className={`${
        isOpen ? "block" : "hidden"
      } fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center`}
    >
      <div className="bg-white w-4/5 rounded-lg p-8">
        <h2 className="text-xl font-bold mb-4">
          {isEditModel ? "Update " : "New "} Area Name :{" "}
        </h2>

        <>
          <input
            type="text"
            placeholder="Area Name"
            className="border border-gray-300 rounded-md p-2 mb-4 w-full"
            value={areaName}
            onChange={(e) => setAreaName(e.target.value)}
          />

          <div className="flex justify-end">
            <button
              className="bg-primaryl hover:bg-opacity-70 text-white px-4 py-2 rounded-md mr-2"
              onClick={handleSubmit}
              type="submit"
            >
              Save
            </button>
            <button
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

const ManageWorkingArea = ({ setLoad, areas, setAreas, setAreasCount }) => {
  const [selectedArea, setSelectedArea] = useState(null);
  const [isEditModel, setIsEditModel] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const cookies = new Cookies();

  const fetchArea = async () => {
    setLoad(true);
    try {
      const response = await getCityArea();
      // console.log(response);
      setAreas(response);
      setAreasCount(response.length);
      setAreas(response.data.data);
    } catch (error) {
      console.error("Error fetching areas:", error);
    } finally {
      setLoad(false);
    }
  };

  const updateArea = async (areaName) => {
    setLoad(true);

    try {
      const response = await axios.put(
        `${cityareaAPI}/${selectedArea.ID}`,
        {
          Name: areaName,
        },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: "admin",
          },
        }
      );
      toast.success("Area Name Updated Successfully");
      fetchArea();

      // console.log(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
      setIsEditModel(false);
    }
  };

  const addNewArea = async (areaName) => {
    setLoad(true);
    try {
      const response = await axios.post(
        cityareaAPI,
        {
          Name: areaName,
        },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: "admin",
          },
        }
      );
      toast.success("Working Area Added Successfully");
      fetchArea();
      // console.log(response);
    } catch (error) {
      toast.error("Error Adding Area");
      console.error(error);
    } finally {
      setLoad(false);
    }
  };

  const handleUpdateClick = (area) => {
    setIsEditModel(true);
    setSelectedArea(area);
    setIsModalOpen(true);
  };

  const handleAddNewClick = () => {
    setIsEditModel(false);
    setIsModalOpen(true);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Manage Working Area</h2>
      <div className="flex flex-col">
        <div className="flex justify-center">
          <div className="overflow-auto max-h-[60vh] w-full table-container">
            <div className="mb-4">
              <button
                onClick={handleAddNewClick}
                className="mr-2 px-4 py-2 flex gap-2 items-center rounded-md bg-primaryl text-white font-bold"
              >
                Add New <IoMdAdd />
              </button>
            </div>
            <table className="table-auto w-full relative">
              <thead className="relative">
                <tr>
                  <th className="px-4 py-2 text-sm">Woeking Area Name </th>

                  <th className="px-4 py-2 text-sm">Update</th>
                </tr>
              </thead>
              <tbody>
                {areas?.map((area) => (
                  <tr key={area.ID}>
                    <td className="border px-4 py-2">{area.Name}</td>
                    <td className="border px-4 py-2">
                      <FaEdit
                        className="text-cyan-500 cursor-pointer"
                        onClick={() => handleUpdateClick(area)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <UpdateAreaModal
        isOpen={isModalOpen}
        onClose={() => {
          setSelectedArea(null);
          setIsModalOpen(false);
        }}
        updateArea={(areaName) => {
          updateArea(areaName);
        }}
        selectedArea={selectedArea}
        isEditModel={isEditModel}
        addNewArea={addNewArea}
      />
    </div>
  );
};

export default ManageWorkingArea;
