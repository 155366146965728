import React from "react";
import Layout from "../../components/layout/Layout";

import { NavLink } from "react-router-dom";
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoMail } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { ContractorProfileLeftImage } from "../../assets/images";

function TermsAndCondition() {
  return (
    <Layout>
      <div>
        <div>
          <div>
            <div className="h-full w-full flex relative ">
              {/* First div : images */}

              {/* Terms and Conditions Us Section */}
              <div className="w-full h-full pb-[3%] pl-[2%] pr-[3%] py-4 mx-3 sm:mx-3 md:mx-0">
                <hr className="border-t-2  w-full" />
                <h2 className="text-2xl lg:text-4xl my-3 text-primaryl euclidbold">
                  CONDOMINIUM PORTAL INC. – TERMS OF SERVICE
                </h2>
                <hr className="border-t-2 mb-4" />
                <p className="mb-4  text-gray-800 text-justify italic">
                  Last updated: June 10, 2024
                </p>

                <p className="mb-2  text-gray-800 text-justify">
                  This Condominium Portal Inc. (“<strong>CPI</strong>”) Terms of
                  Service (the "<strong>Terms of Service</strong>", together
                  with any addendum attached to these Terms of Service (each, an
                  “<strong>Addendum</strong>”, and collectively, the “
                  <strong>Addenda</strong>”), all as amended from time to time
                  the "<strong>Agreement</strong>") form an agreement between
                  the customer accessing, downloading, installing, or otherwise
                  using CPI’s services (the “<strong>Services</strong>”), and is
                  entered into on the earlier of the date Customer first uses
                  any part of the Services and the date Customer agrees to be
                  bound by this Agreement (the "<strong>Effective Date</strong>
                  "). Each of CPI and Customer will individually be referred to
                  as a "<strong>Party</strong>" and jointly as the "
                  <strong>Parties</strong>".
                </p>
                <p className="mb-2  text-gray-800 text-justify">
                  By using any Services (including the Website), including any
                  End Customer or Supplier, to access or use the Services or
                  Website, Customer agrees to the terms of this Agreement,
                  acknowledges that Customer has read, accepts, and agrees to be
                  bound by and comply with the terms and conditions set out in
                  this Agreement, as amended from time to time in accordance
                  with section 13(k). If Customer does not accept and agree to
                  be bound by this Agreement, Customer will immediately cease
                  any further use of the Services. Customer represents and
                  warrants to CPI that Customer has the capacity to enter into
                  this legally binding agreement.
                </p>
                <p className="mb-2  text-gray-800 text-justify">
                  If Customer is using the Services on behalf of another Person
                  or Persons, customer hereby represents and warrants to CPI
                  that Customer has the authority to bind each such Person to
                  this Agreement, in which case the term “Customer” shall refer
                  to such entity and its affiliates. If the individual accepting
                  this Agreement does not have such authority, or does not agree
                  with these terms and conditions, such individual must not
                  accept this Agreement and may not use the Services.
                </p>
                <p className="mb-2  text-gray-800 text-justify">
                  If you are an End Customer or Supplier or other Person being
                  invited to use the Services, you must first agree to this
                  Agreement. If you do not accept and agree to be bound by this
                  Agreement, you will immediately cease any further use of the
                  Services.
                </p>
                <p className="mb-4  text-gray-800 text-justify">
                  The Services may not be accessed for purposes of monitoring
                  their availability, performance or functionality, or for any
                  other benchmarking or competitive purposes. CPI's direct
                  competitors are prohibited from accessing the Services, except
                  with CPI's prior written consent.
                </p>

                <h1 className="euclidbold mb-1"> 1. Definitions</h1>

                <ol className="custom-list ml-2 mb-4 text-justify text-gray-800 space-y-2">
                  <li>
                    <strong>“Applicable Law”</strong> means applicable statutes,
                    by-laws, rules, regulations, orders, ordinances or
                    judgments, in each case of any Governmental or Regulatory
                    Authority.
                  </li>
                  <li>
                    <strong>“Bills”</strong> means any bills or other accounts
                    payables or accounts receivables records.
                  </li>

                  <li>
                    <strong>“Content”</strong> means information obtained by CPI
                    from publicly available sources or its third-party content
                    providers and made available to Customer through the
                    Services, or pursuant to an Order Form, as more fully
                    described in the Documentation.
                  </li>
                  <li>
                    <strong> "Customer Data"</strong> means any data,
                    information, content, records, and files that Customer or
                    any Permitted User loads or enters into, transmits to, or
                    makes available to the Services, including but not limited
                    to Personal Information and User Content. Customer Data
                    excludes Aggregated Data and any other CPI Property.
                  </li>
                  <li>
                    <strong>“Documentation”</strong> means any applicable guides
                    and policies, as updated from time to time, accessible via
                    Website or login to the Services.
                  </li>
                  <li>
                    “<strong>End Customer</strong>” means any client of the
                    Customer.
                  </li>
                  <li>
                    <strong>“Governmental or Regulatory Authority”</strong>
                    means any national, provincial, state, county, municipal,
                    quasi-governmental or self-regulatory department, authority,
                    organization, agency, commission, board, tribunal,
                    regulatory authority, dispute settlement panel or body,
                    bureau, official, minister, Crown corporation, or other law,
                    rule or regulation- making entity having jurisdiction over
                    CPI, Customer, the Services, the Customer Data or any other
                    person, property, transaction, activity, event or other
                    matter related to this Agreement, including subdivisions of,
                    political subdivisions of, and other entities created by,
                    such entities.
                  </li>
                  <li>
                    <strong>"Loss"</strong> or <strong>"Losses"</strong> means
                    any and all losses, damages, claims, actions, judgments,
                    settlements, interest, awards, penalties, fines, costs, or
                    expenses of whatever kind, including reasonable legal fees
                    and the costs of enforcing any right to indemnification
                    hereunder and the cost of pursuing any insurance providers.
                  </li>
                  <li>
                    <strong>"Modifications"</strong> means modifications,
                    improvements, customizations, patches, bug fixes, updates,
                    enhancements, aggregations, compilations, derivative works,
                    translations and adaptations, and <strong>"Modify"</strong>
                    has a corresponding meaning.
                  </li>
                  <li>
                    <strong>“Non-CPI Application”</strong> any products,
                    content, services, information, websites, or other materials
                    that are owned by third parties and are incorporated into or
                    accessible through the Services.
                  </li>
                  <li>
                    <strong>"Order Form"</strong> means an order form that
                    references these Terms of Service and that is executed by
                    both Parties or otherwise accepted by Customer.
                  </li>
                  <li>
                    <strong> “Payments”</strong> means a payment from one Person
                    to another Person, verified by Customer and/or its Permitted
                    User, using CPI Services.
                  </li>
                  <li>
                    <strong>"Person"</strong> means any individual, sole
                    proprietorship, partnership, firm, entity, unincorporated
                    association, unincorporated syndicate, unincorporated
                    organization, trust, body corporate or Governmental or
                    Regulatory Authority, and where the context requires, any of
                    the foregoing when they are acting as trustee, executor,
                    administrator or other legal representative.
                  </li>
                  <li>
                    <strong>"Personal Information"</strong> means information
                    about an identifiable individual.
                  </li>
                  <li>
                    <strong>“Permitted User”</strong> means all individuals who
                    are employees or contractors of Customer or any other Person
                    that Customer has given access to and use of the Services
                    via a Permitted User Credential and includes any other users
                    of the Services whether they are End Customers or Suppliers.
                  </li>
                  <li>
                    <strong>“Services”</strong> means CPI Services; other
                    products and services that are ordered by Customer under an
                    Order Form or online purchasing portal or made available via
                    the Website by CPI and any part thereof. “Services” excludes
                    Content and Non-CPI Applications.
                  </li>
                  <li>
                    <strong>“CPI Services”</strong> means services through which
                    CPI makes available its proprietary application for bidding,
                    tendering, and related services.
                  </li>
                  <li>
                    <strong>“Supplier”</strong> means the supplier of Customer
                    under the CPI Services.
                  </li>
                  <li>
                    <strong>"Website"</strong> means any websites used by CPI to
                    provide the Services, including CondominiumPortal.com.
                  </li>
                </ol>

                <h1 className="euclidbold mb-1"> 2. Services</h1>

                <ol className="custom-list ml-2 mb-4 text-justify text-gray-800 space-y-2">
                  <li>
                    <u>Provisioning of the Services</u>. Subject to Customer's
                    and its Permitted Users' compliance with the terms and
                    conditions of this Agreement, CPI will make the Services
                    available to Customer and Permitted Users on the terms and
                    conditions set out in this Agreement during the Term.
                    Customer is responsible for identifying and authenticating
                    all Permitted Users and for ensuring that only Permitted
                    Users’ access and use the Services. Customer shall ensure
                    that all Permitted Users comply with the terms and
                    conditions of this Agreement. Customer shall ensure that any
                    End Customer, Supplier, or other Person that it invites to
                    use the Services agrees to and is bound by this Agreement
                    prior to such End Customer, Supplier or other Person using
                    the Services.
                  </li>
                  <li>
                    <u> Restrictions on Use.</u>
                    Customer will not itself, and will not permit others to:
                    <ol className="mt-1 space-y-2">
                      <li>
                        sub-license, sell, rent, lend, lease or distribute the
                        Services or any intellectual property rights therein, or
                        otherwise make the Services available to others other
                        than Permitted Users;
                      </li>
                      <li>
                        use the Services to permit timesharing, service bureau
                        use, or commercially exploit the Services;
                      </li>
                      <li>
                        use or access the Services:
                        <ol className="mt-1 space-y-2">
                          <li>in violation of any Applicable Law;</li>
                          <li>
                            in a manner that threatens the security or
                            functionality of the Services;
                          </li>
                          <li>directly via the ‘back end’ server; or</li>
                          <li>
                            for any purpose or in any manner not expressly
                            permitted in this Agreement;
                          </li>
                        </ol>
                      </li>
                      <li>
                        use the Services to create, collect, transmit, store,
                        use, or process any Customer Data:
                        <ol className="mt-1 space-y-2">
                          <li>
                            that contains any computer viruses, worms, malicious
                            code, or any software intended to damage or alter a
                            computer system or data;
                          </li>
                          <li>
                            that Customer does not have the lawful right to
                            create, collect, transmit, store, use or process; or
                          </li>
                          <li>
                            that infringes, violates or otherwise
                            misappropriates the intellectual property or other
                            rights of any third party (including any moral
                            right, privacy right or right of publicity);
                          </li>
                        </ol>
                      </li>
                      <li> Modify the Services;</li>
                      <li>
                        reverse engineer, decompile or disassemble the Services;
                      </li>
                      <li>
                        remove or obscure any proprietary notices or labels on
                        the Services, including brand, copyright, trademark, and
                        patent or patent pending notices;
                      </li>
                      <li>
                        attempt to obtain any materials or information through
                        any means not intentionally made available through the
                        Services;
                      </li>
                      <li>
                        attempt to gain unauthorized access to the Services,
                        other Permitted User Credentials, or computer systems or
                        networks connected to any CPI server or to the Services,
                        through hacking, password mining, or any other means;
                      </li>
                      <li>
                        access or use the Services for the purpose of building a
                        similar or competitive product or service; or
                      </li>
                      <li>
                        perform any vulnerability, penetration, or similar
                        testing of the Services.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <u>Minimum System Requirements.</u> In order to use the
                    Services, Customer’s equipment and software must meet the
                    minimum system requirements communicated by CPI to Customer,
                    which will be subject to changes and updates over time.
                  </li>
                  <li>
                    <u>Downloadable Software.</u> Use of the Services may
                    require or include use of downloadable software. CPI grants
                    Customer a revocable, non-transferable, non-exclusive,
                    non-assignable, non-sublicensable, limited right for
                    Customer and its Permitted Users to use downloadable
                    software that CPI provides as part of the Services, during
                    the Term. Any Non-CPI Application that consist of
                    downloadable software are subject to the terms of Section 2
                    (j).
                  </li>
                  <li>
                    <u>Permitted Purpose.</u> Customer may access and use the
                    Services in Canada solely for Customer's business purposes.
                  </li>
                  <li>
                    <u>Customer's Responsibility for Permitted Users.</u>
                    Customer shall:
                    <div className="mt-1 space-y-2">
                      <div>
                        <div className="flex gap-3">
                          <span> (1)</span> <p> be responsible for:</p>
                        </div>

                        <ol className="mt-1 space-y-2 ml-4">
                          <li>
                            the accuracy, quality and legality of Customer Data;
                          </li>
                          <li>
                            verification of all information including but not
                            limited to Customer Funding and Payments by
                            Permitted Users;
                          </li>
                          <li>
                            the means by which Customer acquired Customer Data;
                            and
                          </li>
                          <li>
                            {" "}
                            Customer’s use of Customer Data with the Services;
                          </li>
                        </ol>
                      </div>
                      <div>
                        <div className="flex gap-3">
                          <span> (2)</span>{" "}
                          <p> ensure that each Permitted User shall:</p>
                        </div>
                        <ol className="mt-1 space-y-2 ml-4">
                          <li>
                            comply with all Applicable Laws with respect to the
                            Services, including, but not limited to, laws
                            related to the export of technical or personal data;
                          </li>
                        </ol>
                      </div>
                      <div className="flex gap-3">
                        <span> (3)</span>{" "}
                        <p>
                          {" "}
                          only use the Services with content and data for which
                          Customer has all necessary rights; and
                        </p>
                      </div>
                      <div className="flex gap-3">
                        <span> (4)</span>{" "}
                        <p>
                          use the Services in accordance with any reasonable
                          specifications provided by CPI, which may be amended
                          from time to time.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <u> Suspension of Access; Modifications.</u> CPI may:
                    <div className="space-y-2 mt-1">
                      <div className="flex gap-3">
                        <span> (a)</span>{" "}
                        <p>
                          suspend and or permanently terminate a Permitted
                          User’s access to all or a portion of the Services, all
                          in CPI’s sole discretion; and
                        </p>
                      </div>
                      <div className="flex gap-3">
                        <span> (b)</span>{" "}
                        <p>
                          make any Modifications to the Services, in CPI’s sole
                          discretion.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <u> Non-CPI Application.</u> CPI or third parties may make
                    available (for example, through a Services or otherwise)
                    third party products or services, including, for example,
                    Non-CPI Applications and implementation and other consulting
                    services. Any acquisition by Customer of such products or
                    services, and any exchange of data between Customer and any
                    Non-CPI Application provider, product or service is solely
                    between Customer and the applicable Non-CPI Application
                    provider. CPI does not warrant or support Non-CPI
                    Applications or other Non-CPI products or services, whether
                    or not they are designated by CPI as “certified” or
                    otherwise, unless expressly provided otherwise in an Order
                    Form. CPI is not responsible for any disclosure,
                    modification or deletion of Customer Data resulting from
                    access by such Non-CPI Application or its provider. The
                    Services may contain features designed to interoperate with
                    Non-CPI Applications. CPI cannot guarantee the continued
                    availability of such service features, and may cease
                    providing them without entitling Customer to any refund,
                    credit, or other compensation, if for example and without
                    limitation, the provider of a Non-CPI Application ceases to
                    make the Non-CPI Application available for interoperation
                    with the corresponding Services features in a manner
                    acceptable to CPI.
                  </li>
                  <li>
                    <u> Subcontracting.</u> CPI may engage third parties to
                    assist it in providing the Services or any part thereof.
                    Without limiting the foregoing, Customer will cooperate with
                    CPI and its third party suppliers and partners (each a “
                    <strong>CPI Integration Partners</strong>”) during the
                    implementation of the Services for Customer.
                  </li>
                </ol>

                <h1 className="euclidbold mb-1">
                  3. Permitted User Credentials; Communications Rules; Customer
                  Restricted Activities.
                </h1>
                <ol className="custom-list ml-2 mb-4 text-justify text-gray-800 space-y-2">
                  <li>
                    Customer shall permit only Permitted Users to access and use
                    the Services. CPI will issue one administrator account (the
                    “<strong>Administrator Account</strong>”) to Customer that
                    provides Customer with the capability to create user
                    identification and select roles (together, “
                    <strong>Permitted User Credentials</strong>”) for each
                    Permitted User that Customer wishes to have access to and
                    use of the Services. Customer shall ensure that its
                    Permitted Users create passwords of a reasonable length and
                    strength to ensure the security of the Permitted User
                    Credentials. Customer’s Permitted User with an Administrator
                    Account is responsible to issue Permitted User Credentials
                    for Permitted Users.
                  </li>
                  <li>
                    CPI has no obligation to verify the identity of any person
                    who gains access to the Services by means of Permitted User
                    Credentials. Customer has and will retain sole
                    responsibility for:
                    <div className="space-y-2 mt-1">
                      <div className="flex gap-3">
                        <span> (a)</span>{" "}
                        <p>all Customer Data, including its content and use;</p>
                      </div>
                      <div className="flex gap-3">
                        <span> (b)</span>{" "}
                        <p>
                          all information, instructions, and materials provided
                          by or on behalf of Customer or any Permitted User in
                          connection with the Services;
                        </p>
                      </div>
                      <div className="flex gap-3">
                        <span> (c)</span> <p>Customer’s systems;</p>
                      </div>{" "}
                      <div className="flex gap-3">
                        <span> (d)</span>{" "}
                        <p>
                          the security and use of Permitted User Credentials of
                          Customer and its Permitted Users;
                        </p>
                      </div>
                      <div className="flex gap-3">
                        <span> (e)</span>{" "}
                        <p>
                          any use of the Services by any End Customer, Supplier
                          or other Person that Customer invites to use the
                          Services; and
                        </p>
                      </div>
                      <div>
                        <div className="flex gap-3">
                          <span> (f)</span>{" "}
                          <p>
                            all access to and use of the Services directly or
                            indirectly by or through the Customer’s systems or
                            its or its Permitted Users’ Permitted User
                            Credentials, with or without Customer’s knowledge or
                            consent, including all results obtained from, and
                            all conclusions, decisions, and actions based on,
                            such access or use. Customer shall employ all
                            physical, administrative, and technical controls,
                            screening, and security procedures and other
                            safeguards necessary to:
                          </p>
                        </div>
                        <div className="space-y-2 mt-1 ml-4">
                          <div className="flex gap-3">
                            <span> (a)</span>{" "}
                            <p>
                              securely administer the distribution and use of
                              all Permitted User Credentials and protect against
                              any unauthorized access to, or use of, the
                              Services; and
                            </p>
                          </div>

                          <div className="flex gap-3">
                            <span> (b)</span>{" "}
                            <p>
                              control the content and use of Customer Data,
                              including the uploading or other provision of
                              Customer Data for processing by the Services.
                              Customer shall immediately take all necessary
                              steps, including providing notice to CPI, to
                              effect the termination of Permitted User
                              Credentials for any Permitted User if there is any
                              compromise in the security of Permitted User
                              Credentials or if unauthorized use is suspected or
                              has occurred. For clarity and avoidance of all
                              doubt, CPI will not be liable for any loss or
                              damage that Customer or any third party may incur
                              as a result of anyone using the Permitted User
                              Credentials, either with or without Customer’s
                              knowledge or Customer’s breach of its obligations
                              under this Section. CPI reserves the right to
                              suspend, deactivate, or replace the Permitted User
                              Credentials if it determines that the Permitted
                              User Credentials may have been used for an
                              unauthorized purpose.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    When Customer uses the Services or sends e-mails, texts or
                    other electronic messages to CPI, Customer is communicating
                    with CPI electronically and Customer consents to it and
                    Permitted Users receiving communications from CPI
                    electronically via email, text or any other type of
                    electronic messages (“<strong>Communications</strong>”). By
                    using the Services, Customer acknowledges and agrees that
                    CPI will send Customer and Permitted Users service-related
                    e-mails or other Communications relating to Customer’s
                    account, including service updates. Customer’s consent to
                    receive Communications and do business electronically, and
                    agreement to do so, applies to all of Customer’s
                    interactions and transactions with CPI. If Customer is a
                    member of CPI’s mailing list Customer and its Permitted
                    Users will also receive email Communications from CPI
                    regarding our products, services and initiatives (including
                    collaborations and partnerships). If Customer is a member of
                    CPI’s mailing list Customer will also receive email
                    Communications from CPI regarding CPI’s products, services
                    and initiatives (including collaborations and partnerships).
                    If Customer or a Permitted User does wish to receive these
                    communications, Customer or Permitted User can unsubscribe
                    from such promotional e-mails at any time by clicking on the
                    unsubscribe link in any of our e-mail communications. The
                    withdrawal of consent will not affect the legal validity and
                    enforceability of any obligations or any Communications
                    provided or business transacted prior to withdrawal of
                    consent. Customer shall keep CPI informed of any changes in
                    its email and mailing address so that it continues to
                    receive all Communications without interruption. CPI may
                    also provide Communications to Customer in writing to the
                    address provide within the Services. If Customer’s contact
                    information changes at any time, Customer shall update it
                    within the Services. Customer has a duty to review the
                    records relating to Bills and identify any error with
                    respect to any Communication, which was not authorized by
                    Customer, or otherwise set forth improper or inaccurate
                    information not consistent with the Communications
                    transmitted by Customer. Customer shall notify CPI
                    immediately of any Communication that it believes to be
                    unauthorized, improper or inaccurate.
                  </li>
                  <li>
                    In connection with Customer’s use of any Services, or in the
                    course of Customer’s interactions with CPI, Customer shall
                    not by itself or permit any Permitted Users to:
                    <ol className="mt-1 space-y-2">
                      <li>
                        act in a manner that is defamatory, trade libelous,
                        threatening or harassing;
                      </li>
                      <li>
                        provide CPI with false, inaccurate or misleading
                        information;
                      </li>
                      <li>
                        send or receive potentially fraudulent funds or what CPI
                        reasonably believes to be potentially fraudulent funds;
                      </li>
                      <li>
                        refuse to cooperate in an investigation regarding
                        improper use or receipt of funds or provide confirmation
                        of Customer’s identity or any information Customer
                        provide to CPI;
                      </li>
                      <li> use an anonymizing proxy;</li>
                      <li>
                        use the Services in a manner that CPI, or members of the
                        electronic funds transfer network reasonably believes to
                        be contrary to applicable network rules, including but
                        not limited to the automated clearing settlement system
                        rules and standards as defined by Payments Canada or any
                        other Applicable Law.
                      </li>
                      <li>
                        take any action that imposes an unreasonable or
                        disproportionately large load on CPI’s infrastructure;
                      </li>
                      <li>
                        use the Services other than for legitimate transactional
                        purposes; breach or attempt to breach the security of
                        any network, servers, data, computers or other hardware
                        relating to or used in connection with the Services or
                        belonging to or used or leased by any other customer of
                        CPI or any third party that is hosting or interfacing
                        with any part of the Services; use or distribute through
                        the Services any software, files or other tools or
                        devices designed to interfere with or compromise the
                        privacy, security or use of the Services or the
                        operations or assets of any other customer of CPI or any
                        third party, including but not limited to viruses,
                        Trojan horses, worms or other computer programming
                        routines.
                        <div className="mt-2">
                          If CPI believes that Customer has engaged in any
                          restricted activities or violated any of the
                          provisions set out in this Section 3 (d) or otherwise
                          in this Agreement, CPI may take various actions to
                          protect itself, a third party or Customer from fees,
                          fines, penalties and/or any other liability. These
                          actions may include, but are not limited to the
                          following:
                          <div className="ml-2">
                            <div className="flex gap-3">
                              <span>(a)</span>
                              <p>
                                close, suspend or limit Customer’s access to the
                                Services;
                              </p>
                            </div>
                            <div className="flex gap-3">
                              <span>(b)</span>
                              <p>
                                update inaccurate information provided by
                                Customer;
                              </p>
                            </div>
                            <div className="flex gap-3">
                              <span>(c)</span>
                              <p>
                                refuse to provide Services to Customer in the
                                future;
                              </p>
                            </div>
                            <div className="flex gap-3">
                              <span>(d)</span>
                              <p>
                                contact Customer’s financial institution or
                                inform law enforcement or impacted third parties
                                of Customer’s actions;
                              </p>
                            </div>
                            <div className="flex gap-3">
                              <span>(e) </span>{" "}
                              <p> take legal action against Customer.</p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ol>
                  </li>
                </ol>

                <h1 className="euclidbold mb-1">
                  4. Ownership; Reservation of Rights
                </h1>

                <ol className="custom-list ml-2 mb-4 text-justify text-gray-800 space-y-2">
                  <li>
                    <p>
                      As between CPI and Customer, Customer retains all
                      ownership and intellectual property rights in and to
                      Customer Data. Customer grants to CPI a nonexclusive,
                      worldwide, royalty-free, irrevocable, sublicensable, and
                      fully paid-up right to access, collect, use, process,
                      store, disclose and transmit Customer Data to:
                    </p>
                    <ol className="mt-1 mb-2 space-y-2">
                      <li>provide the Services;</li>
                      <li>
                        improve and enhance the Services and its other
                        offerings; and
                      </li>
                      <li>
                        produce and/or generate data, information, or other
                        materials that are anonymized and not identified as
                        relating to a particular individual or company (such
                        data, information and materials, the "
                        <strong>Aggregated Data</strong>").
                      </li>
                    </ol>
                    <p>
                      CPI may use, process, store, disclose and transmit the
                      Aggregated Data for any purpose and without restriction or
                      obligation to Customer of any kind. As between CPI and
                      Customer, all right, title, and interest in Aggregated
                      Data, and all intellectual property rights therein, belong
                      to and are retained solely by CPI.
                    </p>
                  </li>
                  <li>
                    <p>
                      CPI or its licensors retain all ownership and intellectual
                      property rights in and to:
                    </p>
                    <ol className="mt-1 mb-2 space-y-2">
                      <li>the Services;</li>
                      <li>
                        all copyright and moral rights to the format and
                        arrangement of any and all reports generated through the
                        Services, but not the content of any such reports;
                      </li>
                      <li>
                        any and all reports generated through the Services (“
                        <strong>Reports</strong>”) excluding any Customer Data
                        therein;
                      </li>
                      <li>
                        anything developed or delivered by or on behalf of CPI
                        under this Agreement;
                      </li>
                      <li>
                        all other CPI’s Confidential Information, including but
                        not limited to, any reports generated from the Services
                        or any Aggregated Data; and
                      </li>
                      <li>
                        any Modifications to the foregoing (i), (ii), (iii),
                        (iv) and (collectively "<strong>CPI Property</strong>").
                      </li>
                    </ol>
                    <p>
                      Customer may download, keep or merge Reports generated by
                      Customer’s use of the Services.
                    </p>
                  </li>
                  <li>
                    Customer grants to CPI and its affiliates a worldwide,
                    perpetual, irrevocable, royalty-free license to use and
                    incorporate into the Services, any suggestion, enhancement
                    request, recommendation, correction or other feedback
                    provided by Customer or Permitted Users relating to the
                    operation of Services or any of CPI's affiliates' services
                    ("<strong>Feedback</strong>"). Nothing in this Agreement
                    will restrict our right to use, profit from, disclose,
                    publish or otherwise exploit any Feedback, without
                    compensation to the Customer or Permitted Users and without
                    any obligation to the Customer or any Permitted User. CPI is
                    not obligated to use any Feedback.
                  </li>
                  <li>
                    All rights not expressly granted by CPI to Customer under
                    this Agreement are reserved.
                  </li>
                </ol>

                <h1 className="euclidbold mb-1"> 5. Privacy</h1>
                <p className="mb-4  text-gray-800 text-justify">
                  Customer understands that Personal Information, including the
                  Personal Information of Permitted Users, will be treated in
                  accordance with CPI's privacy policy located at
                  <mark style={{ backgroundColor: "yellow" }}>
                    CondominiumPortal.com/PrivacyPolicy
                  </mark>{" "}
                  (“
                  <strong>Privacy Policy</strong>”).
                </p>

                <h1 className="euclidbold mb-1"> 6. Support</h1>
                <p className="mb-4  text-gray-800 text-justify">
                  Customer will generally have access to CPI's technical support
                  services from{" "}
                  <mark style={{ backgroundColor: "yellow" }}>
                    {" "}
                    9:00 AM to 5:00 PM ET Monday through Friday excluding
                    holidays
                  </mark>
                  , through email at{" "}
                  <span className=" text-primaryl  cursor-pointer hover:text-primaryd">
                    <NavLink
                      to="mailto:support@condominiumportal.com"
                      className="text-primaryl cursor-pointer hover:text-primaryd"
                    >
                      support@condominiumportal.com
                    </NavLink>
                  </span>
                  . CPI reserves the right, in its sole discretion, to change
                  the support times noted in this section.
                </p>

                <h1 className="euclidbold mb-1">7. Fees and Payment </h1>
                <ol className="custom-list ml-2 mb-4 text-justify text-gray-800 space-y-2">
                  <li>
                    <u>Fees.</u> Customer will pay to CPI the fees described in
                    an Order Form (or other means) or otherwise noted on the
                    Website ("<strong>Fees</strong>"). Fees will be charged by
                    CPI and payable by Customer.
                  </li>
                  <li>
                    <u>Changes to the Fees.</u> CPI reserves the right to change
                    the Fees and institute new charges by providing not less
                    than thirty (30) days prior notice to Customer.
                  </li>
                  <li>
                    <u>Late Payment.</u> Customer may not withhold or setoff any
                    amounts due under this Agreement. Without limiting CPI’s
                    other rights, CPI may suspend Customer’s access to the
                    Services until all amounts due are paid in full. Any late
                    payment will be increased by the costs of collection (if
                    any) and will incur interest at the rate of{" "}
                    <mark style={{ backgroundColor: "yellow" }}>
                      5.00% compounded monthly
                    </mark>
                    , or the maximum legal rate (if less), plus all expenses of
                    collection, until fully paid.
                  </li>
                  <li>
                    <u>Taxes</u>. The Fees set out in this Agreement do not
                    include applicable sales, use, gross receipts, value-added,
                    excise, personal property or other taxes. Customer will be
                    responsible for and pay all applicable taxes, duties,
                    tariffs, assessments, export and import fees or similar
                    charges (including interest and penalties imposed thereon)
                    on the transactions contemplated in connection with this
                    Agreement other than taxes based on the net income or
                    profits of CPI.
                  </li>
                  <li>
                    <u>Suspension.</u> Any suspension of the Services by CPI
                    pursuant to the terms of this Agreement will not excuse
                    Customer from its obligation to make payments under this
                    Agreement.
                  </li>
                </ol>

                <h1 className="euclidbold mb-1">8. Confidential Information</h1>

                <ol className="custom-list ml-2 mb-4 text-justify text-gray-800 space-y-2">
                  <li>
                    <u>Definitions.</u> For the purposes of this Agreement, a
                    Party receiving Confidential Information (as defined below)
                    will be the "<strong>Recipient</strong>", the Party
                    disclosing such information will be the "
                    <strong>Discloser</strong>" and "
                    <strong>Confidential Information</strong>" of the Discloser
                    means any and all information of the Discloser or any of its
                    licensors that has or will come into the possession or
                    knowledge of the Recipient in connection with or as a result
                    of entering into this Agreement, including information
                    concerning the Discloser's past, present or future
                    customers, suppliers, technology or business, and where the
                    Discloser is Customer includes Customer Data; if Discloser
                    is CPI includes all CPI Property, provided that the
                    Discloser's Confidential Information does not include,
                    except with respect to Personal Information:
                    <ol className="mt-1 space-y-2">
                      <li>
                        information already known or independently developed by
                        the Recipient without access to the Discloser's
                        Confidential Information;
                      </li>
                      <li>
                        information that is publicly available through no
                        wrongful act of the Recipient; or
                      </li>
                      <li>
                        information received by the Recipient from a third party
                        who was free to disclose it without confidentiality
                        obligations.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      <u>Confidentiality Covenants.</u> The Recipient hereby
                      agrees that during the Term and at all times thereafter it
                      will not, except to exercise its license rights or perform
                      its obligations under this Agreement:
                    </p>
                    <ol className="mt-1 mb-2 space-y-2">
                      <li>
                        disclose Confidential Information of the Discloser to
                        any person, except to: in the case of Customer to its
                        own employees or Permitted Users having a "need to know"
                        and that have entered into written agreements no less
                        protective of such Confidential Information than this
                        Agreement, and to such other recipients as the Discloser
                        may approve in writing; or
                        <ol className="mt-1 ">
                          <li>
                            in the case of CPI to CPI's employees, consultants,
                            agents or affiliates, having a "need to know" and
                            that have entered into written agreements no less
                            protective of such Confidential Information than
                            this Agreement, and to such other recipients as the
                            Discloser may approve in writing and to
                            subcontractors and sub-processors to perform any
                            Services;
                          </li>
                        </ol>
                      </li>
                      <li>use Confidential Information of the Discloser; or</li>
                      <li>
                        alter or remove from any Confidential Information of the
                        Discloser any proprietary legend.
                      </li>
                    </ol>
                    <p>
                      Each Party will take industry standard precautions to
                      safeguard the other Party's Confidential Information,
                      which will in any event be at least as stringent as the
                      precautions that the Recipient takes to protect its own
                      Confidential Information of a similar type.
                    </p>
                  </li>

                  <li>
                    <u>Exceptions to Confidentiality.</u> Notwithstanding
                    Section 8, the Recipient may disclose the Discloser's
                    Confidential Information:
                    <ol className="mt-1 space-y-2">
                      <li>
                        to the extent that such disclosure is required by
                        Applicable Law or by the order of a court or similar
                        judicial or administrative body, provided that, except
                        to the extent prohibited by law, the Recipient promptly
                        notifies the Discloser in writing of such required
                        disclosure and cooperates with the Discloser to seek an
                        appropriate protective order; or
                      </li>
                      <li>
                        to its legal counsel and other professional advisors, if
                        and to the extent such persons need to know such
                        Confidential Information in order to provide applicable
                        professional advisory services in connection with the
                        Party's business.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <u>Return of Confidential Information.</u> Upon the
                    termination or expiration of this Agreement and all Order
                    Forms under this Agreement, each Party will promptly return
                    to the other Party or destroy all Confidential Information
                    (excluding any Customer Data which is addressed at Section
                    12 (e) below) of the other Party in its possession or
                    control within a reasonable amount of time, in accordance
                    with the Recipient's data destruction practices.
                    Notwithstanding the foregoing, CPI may retain any
                    electronically archived Customer's Confidential Information,
                    provided that such retained information remains subject to
                    the confidentiality obligations in this Agreement.
                  </li>
                </ol>

                <h1 className="euclidbold mb-1"> 9. Warranty and Disclaimer</h1>
                <ol className="custom-list ml-2 mb-4 text-justify text-gray-800 space-y-2">
                  <li>
                    <u>Customer Warranty.</u> Customer represents and warrants
                    to and covenants with CPI that:
                    <ol className="mt-1 space-y-2">
                      <li>
                        the Customer Data will only contain Personal Information
                        in respect of which Customer has provided all notices
                        and disclosures (including to each Permitted User),
                        obtained all applicable third party consents and
                        permissions, and otherwise has all authority, in each
                        case as required by Applicable Laws, to enable CPI to
                        provide the Services, including with respect to the
                        collection, storage, access, use, disclosure, processing
                        and transmission of Personal Information, including by
                        or to CPI and to or from all applicable third parties;
                        and
                      </li>
                      <li>
                        Customer will obtain all necessary consents, permissions
                        and licenses with respect to any and all Customer
                        Funding and Payments to the extent necessary to use the
                        Services.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <u> General disclaimer</u> CPI makes no representation
                    regarding the accuracy of the information obtained by use of
                    the services. CPI makes no representation that the services
                    or any materials provided through the services, is
                    appropriate or available for use in locations outside of
                    Canada and accessing them from territories where their
                    contents are illegal or is prohibited. Permitted users who
                    choose to access the services from other locations do so on
                    their own initiative and are responsible for compliance with
                    local laws. CPI will not be responsible for any third-party
                    software, non-CPI application or hardware cpi provides or
                    uses in providing the services. Cpi will not be responsible
                    for the interception or delay of data transmitted to or from
                    Customer. Customer’s use of the services and content, and
                    its use of the services is at Customer’s sole risk and
                    discretion and cpi hereby disclaims any and all liability to
                    customer, any permitted users, or any third party relating
                    thereto. CPI does not warrant that the services will be
                    uninterrupted or error free or that all errors can or will
                    be corrected; nor does it make any warranty as to the
                    results that may be obtained from use of the services.
                    Except as specifically provided in this agreement, the
                    services (or any part thereof) and any other products
                    provided by cpi to customer are provided "as is" and "as
                    available". To the maximum extent permitted by applicable
                    law, cpi hereby disclaims all express, implied, collateral
                    or statutory warranties, representations and conditions,
                    whether written or oral, including any implied warranties or
                    conditions of merchantability, merchantable quality,
                    compatibility, title, non- infringement, security,
                    reliability, completeness, quiet enjoyment, accuracy,
                    quality, integration or fitness for a particular purpose or
                    use, or any warranties or conditions arising out of course
                    of dealing or usage of trade. Without limiting the
                    generality of any of the foregoing, cpi expressly disclaims
                    any representation, condition or warranty that any data or
                    information provided to customer in connection with
                    customer's use of the services (or any part thereof), is
                    accurate, or can or should be relied upon by customer for
                    any purpose whatsoever.
                  </li>
                </ol>

                <h1 className="euclidbold mb-1"> 10. Indemnities</h1>
                <ol className="custom-list ml-2 mb-4 text-justify text-gray-800 space-y-2">
                  <li>
                    <p> </p>
                    <ol className="mt-1 mb-2 space-y-2">
                      <li>
                        CPI will indemnify, defend, and hold harmless the
                        Customer and its officers, directors, employees, and
                        agents (each, a “<strong>Customer Indemnitee</strong>”)
                        from and against any and all Losses incurred by a
                        Customer Indemnitee arising out of or relating to any
                        claim, action, demand, inquiry, audit, proceeding, or
                        investigation of any nature, civil, criminal,
                        administrative, regulatory, or other, whether at law, in
                        equity or otherwise (collectively, an “
                        <strong>Action</strong>”) by a third party (other than
                        an Affiliate or a Customer Indemnitee) that the CPI
                        Services infringe any third party intellectual property
                        right in Canada. The foregoing obligation does not apply
                        to any Action or Losses arising out of or relating to
                        any:
                        <ol className="mt-1 space-y-2">
                          <li>
                            incorporation of any CPI Services into, or any
                            combination, operation, or use of any CPI Services
                            with, any products or services not provided or
                            authorized by CPI, unless such infringement would
                            also have resulted solely from the use of the CPI
                            Services without their incorporation in, or
                            combination, operation or use, with such other
                            products or services;
                          </li>
                          <li>
                            modification of any CPI Services other than by CPI
                            or with CPI’s express written approval;
                          </li>
                          <li> unauthorized use of the CPI Services; or </li>
                          <li> Customer’s indemnity in Section 10 (b).</li>
                        </ol>
                      </li>
                      <li>
                        If the CPI Services are, or in CPI’s opinion are likely
                        to be, claimed to infringe, misappropriate, or otherwise
                        violate any third-party Intellectual property right, or
                        if the Customer’s use of any CPI Services is enjoined or
                        threatened to be enjoined, CPI may, at its option and
                        sole cost and expense:
                        <ol className="mt-1 space-y-2">
                          <li>
                            obtain the right for the Customer to continue to use
                            the affected CPI Services materially as contemplated
                            by this Agreement;
                          </li>
                          <li>
                            modify or replace CPI Services, in whole or in part,
                            to seek to make the CPI Services (as so modified or
                            replaced) non-infringing while providing materially
                            equivalent features and functionality, in which case
                            such modifications or replacements will constitute
                            CPI Services under this Agreement; or
                          </li>
                          <li>
                            if CPI determines that neither of the foregoing two
                            options are reasonably available, by written notice
                            to the Customer, terminate the CPI Services and this
                            Agreement, in whole or in part, and require the
                            Customer to immediately cease using the CPI
                            Services.
                          </li>
                        </ol>
                      </li>
                    </ol>
                    <p>
                      This section 10 (a) sets forth Customer's sole remedies
                      and CPI’s sole liability and obligation for any actual,
                      threatened, or alleged claims that this Agreement or any
                      subject matter hereof (including the CPI Services)
                      infringes, misappropriates, or otherwise violates any
                      third party intellectual property rights.
                    </p>
                  </li>
                  <li>
                    The Customer will defend, indemnify and hold harmless CPI,
                    and its officers, directors, employees and agents (each, a "
                    <strong>Provider Indemnitee</strong>") from and against any
                    and all Losses incurred by a Provider Indemnitee arising out
                    of or relating to any Action that arise from or relate to:
                    <ol className="mt-1 space-y-2">
                      <li>
                        Customer Data including but not limited to that any
                        Customer Data infringes the rights, including the
                        intellectual property rights, of any third party;
                      </li>
                      <li>
                        Customer's or Permitted Users’ breach of any
                        obligations, representations, warranties or covenants
                        under this Agreement;
                      </li>
                      <li>
                        unauthorized use of the Services by the Customer or any
                        Permitted User;
                      </li>
                      <li>
                        Customer’s relationship with any End Customer, Supplier,
                        Person or any other third party involved in the
                        performance of Customer’s services including but not
                        limited to related to any dispute with any End Customer,
                        Supplier or other Person;
                      </li>
                      <li>security breaches; </li>
                      <li>
                        misappropriation of confidential or proprietary
                        information, including without limitation, any
                        infringement, misappropriation, or violation of any
                        patents, copyrights, trademarks, trade secret rights, or
                        other proprietary rights, or personal rights of any
                        persons, firms or entities, including without
                        limitation, rights of publicity or privacy;{" "}
                      </li>
                      <li>
                        negligence or more culpable act or omission (including
                        but not limited to breach of Applicable Laws, criminal
                        conduct, recklessness, willful misconduct or fraud) by
                        Customer, any Permitted User, End Customer, Supplier or
                        any third party on behalf of Customer or in any way in
                        connection with any Payment, Services or this Agreement;
                        or{" "}
                      </li>
                      <li>
                        use of the Services (or any part thereof) by Customer or
                        any Permitted User in combination with any third party
                        software, application or service. Customer will fully
                        cooperate with CPI in the defense of any claim defended
                        by Customer pursuant to its indemnification obligations
                        under this Agreement and will not settle any such claim
                        without the prior written consent of CPI.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Each Party will promptly notify the other Party in writing
                    of any Action for which such Party believes it is entitled
                    to be indemnified pursuant to this Section 10. The Party
                    seeking indemnification (the “<strong>Indemnitee</strong>”)
                    will cooperate with the other Party (the “
                    <strong>Indemnitor</strong>”) at the Indemnitor’s sole cost
                    and expense. The Indemnitor will promptly take control of
                    the defense and investigation of such Action and will employ
                    counsel of its choice to handle and defend the same, at the
                    Indemnitor’s sole cost and expense. The Indemnitee’s failure
                    to perform any obligations under this Section 10 (c) will
                    not relieve the Indemnitor of its indemnity obligations
                    under this Section 10 except to the extent that the
                    Indemnitor can demonstrate that it has been materially
                    prejudiced as a result of such failure. The Indemnitee may
                    participate in and observe the proceedings at its own cost
                    and expense with counsel of its own choosing.
                  </li>
                </ol>

                <h1 className="euclidbold mb-1">
                  {" "}
                  11. Limitation of Liabilitiesn
                </h1>

                <p className="mb-1  text-gray-800 text-justify">
                  The Parties acknowledge that the following provisions have
                  been negotiated by them and reflect a fair allocation of risk
                  and form an essential basis of the bargain and will survive
                  and continue in full force and effect despite any failure of
                  consideration or of an exclusive remedy:
                </p>
                <ol className="custom-list ml-2 mb-4 text-justify text-gray-800 space-y-2">
                  <li>
                    <u>Amount</u>. In no event will the total aggregate
                    liability of CPI in connection with or under this agreement,
                    whether in contract, tort (including negligence or gross
                    negligence), or otherwise, exceed the amount of fees paid by
                    customer for the CPI Services in the month immediately
                    preceding the event giving rise to the claim. For greater
                    certainty, the existence of one or more claims under this
                    agreement will not increase this maximum liability amount.
                    In no event will CPI’s third party suppliers have any
                    liability arising out of or in any way connected to this
                    agreement.
                  </li>
                  <li>
                    <u>Type</u>. To the maximum extent permitted under
                    applicable law, in no event will CPI be liable to customer
                    or any permitted user for any:
                    <ol className="mt-1 space-y-2">
                      <li>
                        special, exemplary, punitive, indirect, incidental or
                        consequential damages;
                      </li>
                      <li>
                        lost or loss of (a) savings, (b) profit, (c) data, (d)
                        use, or (e) goodwill;
                      </li>
                      <li> business interruption; </li>
                      <li>
                        costs for the procurement of substitute services;{" "}
                      </li>
                      <li>personal injury or death; or </li>
                      <li>
                        personal or property damage arising out of or in any way
                        connected to this agreement, regardless of cause of
                        action or the theory of liability, whether in contract,
                        tort (including negligence or gross negligence), or
                        otherwise, and even if notified in advance of the
                        possibilities of such damages.
                      </li>
                    </ol>
                  </li>
                </ol>

                <h1 className="euclidbold mb-1"> 12. Term and Termination</h1>
                <ol className="custom-list ml-2 mb-4 text-justify text-gray-800 space-y-2">
                  <li>
                    <u>Term.</u> A subscription to Services will commence on the
                    Effective Date and will remain in effect unless earlier
                    terminated in accordance with this Section 12 (“
                    <strong>Term</strong>”).
                  </li>
                  <li>
                    <u>Termination without cause.</u> Notwithstanding any other
                    Sections of this Agreement, CPI may, in its discretion,
                    terminate the Services, this Agreement or any part thereof
                    at any time by providing notice to Customer. Customer may
                    cancel the Services and terminate this Agreement at any time
                    by sending an email to CPI at{" "}
                    <span className=" text-primaryl  cursor-pointer hover:text-primaryd">
                      <NavLink
                        to="mailto:support@condominiumportal.com"
                        className="text-primaryl cursor-pointer hover:text-primaryd"
                      >
                        support@condominiumportal.com
                      </NavLink>
                    </span>
                    .
                  </li>
                  <li>
                    <u>Termination for cause.</u> Without limiting any of CPI’s
                    other rights in this Agreement, if CPI has grounds to
                    believe that Customer is using the Services for any improper
                    purpose, CPI may immediately suspend or terminate Customer’s
                    access to and use of the Services and Customer will be
                    notified of such suspension or termination. Upon termination
                    of Customer’s access and use of the Services, this Agreement
                    will automatically terminate. CPI may, in addition to other
                    relief, terminate this Agreement if the Customer commits a
                    material breach of this Agreement and fails to correct such
                    breach within 30 calendar days after receipt of notice of
                    such breach. CPI may in its discretion terminate this
                    Agreement effective immediately upon delivery of notice of
                    termination to Customer if Customer becomes insolvent,
                    ceases to conduct business in the ordinary course, takes any
                    step or proceeding available to Customer for the benefit of
                    insolvent debtors, or is subject to a proceeding for
                    liquidation, dissolution or winding up, or a receiver,
                    receiver-manager, liquidator or trustee in bankruptcy.
                  </li>
                  <li>
                    <u> Effect of Termination.</u> Upon expiration or earlier
                    termination of this Agreement, Customer will immediately
                    discontinue use of the Services and Customer will delete or,
                    if requested by CPI, return any CPI Property in its
                    possession, and certify in writing to the CPI that the CPI
                    Property has been deleted or destroyed. No expiration or
                    termination will affect Customer's obligation to pay all
                    Fees that may have become due before such expiration or
                    termination or entitle the Customer to any refund. All Fees
                    due and payable and any amounts due to CPI are immediately
                    due and are to be immediately paid by Customer to CPI.
                  </li>
                  <li>
                    <u>Retrieval of Customer Data.</u> Within 30 calendar days
                    following termination, CPI will, at Customer's written
                    request either:
                    <ol className="mt-1 space-y-2">
                      <li>
                        provide to Customer a copy of all of Customer’s images
                        of Bill (in a standard format defined by CPI); or
                      </li>
                      <li>
                        {" "}
                        delete or otherwise render inaccessible any Customer
                        Data that remains in the hardware or systems used by CPI
                        to provide the Services. After such 30-day period, CPI
                        will have no obligation to maintain or provide any
                        Customer Data and may thereafter delete or destroy all
                        copies of Customer Data in its systems or otherwise in
                        its possession or control, unless legally prohibited.
                        Provided, however, that CPI will not be required to
                        remove any Customer Data from its backup media and
                        services that are maintained in accordance with its
                        standard back procedures of record retention policies
                        until such time as such backups are scheduled to be
                        deleted provided that in all cases Customer Data will
                        continue to be protected in accordance with this
                        Agreement.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <u>Survival.</u> The following Sections, together with any
                    other provision of this Agreement which expressly or by its
                    nature survives termination or expiration, or which
                    contemplates performance or observance subsequent to
                    termination or expiration of this Agreement, will survive
                    expiration or termination of this Agreement for any reason:
                    Section 4 (Ownership; Reservation of Rights), Section 5
                    (Privacy), Section 7 (Fees and Payment), Section 8
                    (Confidential Information), Section 9 (Warranty and
                    Disclaimer), Section 10 (Indemnities), Section 11
                    (Limitation of Liabilities), Section 12 (f) (Survival), and
                    Section 13 (General Provisions).
                  </li>
                </ol>

                <h1 className="euclidbold mb-1">13. General Provisions</h1>
                <ol className="custom-list ml-2 mb-4 text-justify text-gray-800 space-y-2">
                  <li>
                    <strong>Notices.</strong> Notices sent to either Party will
                    be effective when delivered in writing and in person or by
                    email, one day after being sent by overnight courier, or
                    five days after being sent by first class mail postage
                    prepaid to the official contact designated by the Party to
                    whom a notice is being given. Notices must be sent:
                    <ol className="mt-1 space-y-2">
                      <li>
                        <p>
                          if to CPI, to the following CPI's address and email
                          contact:
                        </p>
                        <div className="ml-5">
                          <p className="mb-1  text-gray-800 text-justify">
                            Condominium Portal Inc.
                          </p>
                          <p className="mb-1  text-gray-800 text-justify">
                            1290 Central Pkwy W 11th Floor Suite 1100,
                          </p>
                          <p className="mb-1  text-gray-800 text-justify">
                            Mississauga, ON L5C 4R3
                          </p>
                          <p className="mb-1 flex items-center gap-1 text-gray-800 text-justify ">
                            Email:{" "}
                            <span className=" text-primaryl  cursor-pointer hover:text-primaryd">
                              <NavLink
                                to="mailto:support@condominiumportal.com"
                                className="text-primaryl cursor-pointer hover:text-primaryd"
                              >
                                support@condominiumportal.com
                              </NavLink>
                            </span>
                          </p>
                        </div>
                        <p>and</p>
                      </li>
                      <li>
                        if to Customer, to the current mailing or email address
                        that CPI has on file with respect to Customer. CPI may
                        change its contact information by posting the new
                        contact information on the Website or by giving notice
                        thereof to Customer. Customer is solely responsible for
                        keeping Customer's contact information on file with CPI
                        current at all times during the Term.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <u>Assignment.</u> Customer may not assign this Agreement to
                    any third party without CPI's prior written consent. Any
                    purported assignment or delegation by Customer in violation
                    of this Section will be null and void. CPI may assign this
                    Agreement or any rights under this Agreement to any third
                    party without Customer's consent. This Agreement enures to
                    the benefit of and is binding upon the Parties and their
                    respective successors and permitted assigns.
                  </li>
                  <li>
                    <u>Governing Law and Attornment.</u> This Agreement and any
                    action related thereto will be governed by and construed in
                    accordance with the laws of the Province of Ontario and the
                    federal laws of Canada applicable therein, without regard to
                    conflicts of law principles. The Parties will initiate any
                    lawsuits in connection with this Agreement in Toronto,
                    Ontario Canada, and irrevocably attorn to the exclusive
                    personal jurisdiction and venue of the courts sitting
                    therein. The U.N. Convention on Contracts for the
                    International Sale of Goods will not apply to this
                    Agreement. This choice of jurisdiction does not prevent CPI
                    from seeking injunctive relief with respect to a violation
                    of intellectual property rights or confidentiality
                    obligations in any appropriate jurisdiction.
                  </li>
                  <li>
                    <u>Export Restrictions.</u> Customer will comply with all
                    export laws and regulations that may apply to its access to
                    or use of the Services. CPI makes no representation or
                    warranty that the Services may be exported without Customer
                    first obtaining appropriate licenses or permits under
                    Applicable Law, or that any such license or permit has been,
                    will be, or can be obtained.
                  </li>
                  <li>
                    <u>Construction.</u> Except as otherwise provided in this
                    Agreement, the Parties' rights and remedies under this
                    Agreement are cumulative and are in addition to, and not in
                    substitution for, any other rights and remedies available at
                    law or in equity or otherwise. The terms "include" and
                    "including" mean, respectively, "include without limitation"
                    and "including without limitation." The headings of sections
                    of this Agreement are for reference purposes only and have
                    no substantive effect. The terms "consent" or "discretion"
                    mean the right of a Party to withhold such consent or
                    exercise such discretion, as applicable, arbitrarily and
                    without any implied obligation to act reasonably or explain
                    its decision to the other Party.
                  </li>
                  <li>
                    <u>Force Majeure.</u> Neither Party will be liable for
                    delays caused by any event or circumstances beyond that
                    Party's reasonable control (except for Customer’s obligation
                    to pay Fees or Customer's indemnities under this Agreement),
                    including acts of God, acts of government, flood, fire,
                    earthquakes, civil unrest, acts of terror, pandemic, strikes
                    or other labour problems (other than those involving that
                    Party's employees), Internet service failures or delays, or
                    the unavailability or Modification by third parties of
                    telecommunications or hosting infrastructure or third party
                    websites ("Force Majeure"). For clarity, a Force Majeure
                    event will not excuse the Customer from its failure to pay
                    Fees or Customer's indemnities under this Agreement.
                  </li>
                  <li>
                    <u>Severability.</u> Any provision of this Agreement found
                    by a tribunal or court of competent jurisdiction to be
                    invalid, illegal or unenforceable will be severed from this
                    Agreement and all other provisions of this Agreement will
                    remain in full force and effect.
                  </li>
                  <li>
                    <u>Waiver.</u> A waiver of any provision of this Agreement
                    must be in writing and a waiver in one instance will not
                    preclude enforcement of such provision on other occasions.
                  </li>
                  <li>
                    <u> Independent Contractors.</u> CPI's relationship to
                    Customer is that of an independent contractor, and neither
                    Party is an agent or partner of the other. Neither Party
                    will have, and neither Party will represent to any third
                    party that it has, any authority to act on behalf of the
                    other Party.
                  </li>
                  <li>
                    <u>Entire Agreement.</u> This Agreement constitutes the
                    entire agreement between the Parties with respect to the
                    subject matter of this Agreement and supersedes all prior or
                    contemporaneous agreements, representations or other
                    communications between the Parties, whether written or oral.
                  </li>
                  <li>
                    <u>Amendments.</u> No amendment, supplement, modification,
                    waiver, or termination of this Agreement and, unless
                    otherwise expressly specified in this Agreement, no consent
                    or approval by any Party, will be binding unless executed in
                    writing by the Party or Parties to be bound thereby.
                    Notwithstanding the preceding sentence, CPI may unilaterally
                    amend this agreement, in whole or in part (each, an
                    "amendment"), by giving customer prior notice of such
                    amendment or posting notice of such amendment on the
                    website. Unless otherwise indicated by cpi, any such
                    amendment will become effective as of the date the notice of
                    such amendment is provided to customer or is posted on the
                    website (whichever is the earlier).
                  </li>
                  <li>
                    <u>Customer Lists.</u> CPI may identify the Customer by name
                    and logo as a CPI customer on the Website and on other
                    promotional materials. Any goodwill arising from the use of
                    the Customer's name and logo will inure to the benefit of
                    the Customer.
                  </li>
                  <li>
                    <u>English Language.</u> It is the express wish of the
                    Parties that this Agreement and all related documents be
                    drawn up in English.
                  </li>
                </ol>
              </div>
              <div className="w-[45%]  hidden md:block ">
                <img
                  src={ContractorProfileLeftImage}
                  className="absolute top-6 bottom-0 w-[30%] h-full object-cover"
                  alt="Signup background"
                />
              </div>
              <div className="absolute hidden  right-0 w-full md:w-[30%] h-[40%] bg-gradient-to-b from-transparent to-[#0893bd]  md:block"></div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default TermsAndCondition;
