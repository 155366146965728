import React, { memo } from "react";
import ReactPaginate from "react-paginate";
import "./Pagination2.css";
function Pagination2({
  totalPages,
  currentPage,
  setPage,
  setLoad,
  setContracts,
}) {
  const onPageChange = ({ selected }) => {
    setPage(selected + 1);
    setContracts([]);
    setLoad(true);
  };

  return (
    <ReactPaginate
      pageCount={totalPages}
      onPageChange={onPageChange}
      forcePage={currentPage - 1}
      previousLabel={"Prev"}
      nextLabel={"Next"}
      breakLabel={"..."}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      containerClassName={"pagination"}
      activeClassName={"active"}
      previousLinkClassName={"previous"}
      nextLinkClassName={"next"}
      disabledClassName={"disabled"}
    />
  );
}

export default memo(Pagination2);
