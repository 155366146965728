import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faBars } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import Cookies from "universal-cookie";
import toast, { Toaster } from "react-hot-toast";
import Cookies2 from "js-cookie";
import { IconDown, IconUp, LogoWithText } from "../../assets/images";
import userimg from "../../assets/image/user.png";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [profile, setProfile] = useState("");
  const [drop, setDrop] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  const [isLoginDropdownOpen, setIsLoginDropdownOpen] = useState(false);
  const [iscorporation, setIsCorporation] = useState(false);
  const cookies = new Cookies();
  const navigator = useNavigate();

  const token = cookies.get("token");
  const companyType = cookies.get("companyType");
  const dropdownRef = useRef(null);
  const dropdownRefImg = useRef(null);

  const logoutHandler = () => {
    // Remove all cookies related to the session
    Cookies2.remove("token");
    Cookies2.remove("companyType");
    Cookies2.remove("Profile_Photo");
    Cookies2.remove("access");
    Cookies2.remove("selectedPropertyManagerId");
    Cookies2.remove("status");
    cookies.remove("token");
    cookies.remove("companyType");
    cookies.remove("access");
    cookies.remove("Profile_Photo");
    cookies.remove("selectedPropertyManagerId");
    cookies.remove("status");

    navigator("/");
    window.location.reload();
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const dropdownHandler = () => {
    setDrop((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsMobileMenuOpen(true);
    }
  };
  const handleClickOutside2 = (event) => {
    if (
      dropdownRefImg.current &&
      !dropdownRefImg.current.contains(event.target)
    ) {
      setDrop(false);
    }
  };

  useEffect(() => {
    if (cookies.get("Profile_Photo")) {
      setProfile(cookies.get("Profile_Photo"));
    } else {
      setProfile(userimg);
    }

    // document.addEventListener("click", handleClickOutside);
    // document.addEventListener("click", handleClickOutside2);
    // return () => {
    //   document.removeEventListener("click", handleClickOutside);
    //   document.removeEventListener("click", handleClickOutside2);
    // };
  }, []);

  return (
    <nav className="bg-white relative font-normal w-full h-fit px-[12%]">
      <div className="flex flex-wrap relative justify-between items-center h-full">
        <Toaster />
        <Link
          to={
            companyType === "contractor"
              ? "/contractors/contractlisting"
              : companyType === "corporation"
              ? "/corporations/dashboard"
              : companyType === "admin"
              ? "/admin"
              : "/"
          }
          className="flex"
        >
          <img
            src={LogoWithText}
            className="h-fit w-[10vh] pt-[1vh]"
            alt="Condominium Logo"
          />
        </Link>
        <button
          ref={dropdownRef}
          onClick={toggleMobileMenu}
          className="left-[50%] flex items-center w-10 h-10 justify-center text-sm text-black rounded-lg md:hidden hover:bg-secondaryl focus:outline-none focus:ring-2 focus:ring-gray-200 hover:text-white hover:font-semibold"
          aria-expanded={isMobileMenuOpen ? "true" : "false"}
        >
          <span className="sr-only">Toggle mobile menu</span>
          {/* <FontAwesomeIcon icon={faBars} className="w-5 h-5" /> */}
          <svg
            className="w-5 h-5 "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>

        <div
          className={`${
            isMobileMenuOpen ? "block" : "hidden"
          } w-full md:block md:w-auto absolute md:static top-[105%] z-10`}
        >
          <ul className="flex flex-col md:p-0 font-normal border-gray-100 rounded-lg bg-gray-50 md:flex-row md:mt-0 md:border-0 md:bg-white gap-6">
            {/* Conditional rendering for Company type links */}
            {companyType === "corporation" && token && (
              <>
                <li
                  onMouseEnter={() => setIsOpen(!isOpen)}
                  onMouseLeave={() => setIsOpen(!isOpen)}
                  className="px-3 py-2.5 relative w-full z-50 text-black text-base font-normal hover:bg-secondaryl hover:text-white hover:font-semibold transition-transform duration-300 transform hover:translate-x-2"
                >
                  <div className="flex gap-2 font-bold md:p-0">
                    Projects Listing
                    {isOpen ? (
                      <div className="pt-2">
                        <IconUp color={isOpen ? "white" : "#666666"} />
                      </div>
                    ) : (
                      <div className="pt-2">
                        <IconDown color={isOpen ? "white" : "#666666"} />
                      </div>
                    )}
                  </div>
                  {isOpen && (
                    <ul className="text-black absolute md:absolute top-[90%] z-[900] -left-1 block bg-white border shadow-lg">
                      {cookies.get("access") === "board member" ? (
                        ""
                      ) : (
                        <li className="py-2 px-4 hover:bg-secondaryl hover:text-white hover:font-semibold">
                          {cookies.get("status") === 2 ? (
                            <div
                              className="cursor-pointer"
                              onClick={() => toast.error("profile paused")}
                            >
                              Create Listing
                            </div>
                          ) : (
                            <Link to="/corporations/contract">
                              Create Listing
                            </Link>
                          )}
                        </li>
                      )}

                      <li className="py-2 px-4 whitespace-nowrap w-full hover:bg-secondaryl hover:text-white hover:font-semibold">
                        <Link
                          to="/corporations/managecontracts"
                          className="whitespace-nowrap w-full flex items-center"
                        >
                          Archived Projects
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                {cookies.get("access") === "board member" ? (
                  ""
                ) : (
                  <li
                    onMouseEnter={() => setIsOpen3(!isOpen3)}
                    onMouseLeave={() => setIsOpen3(!isOpen3)}
                    className="px-3 py-2.5 relative w-full z-50 text-black text-base font-normal hover:bg-secondaryl hover:text-white hover:font-semibold transition-transform duration-300 transform hover:translate-x-2"
                  >
                    <div className="flex gap-2 font-bold md:p-0">
                      Residents
                      {isOpen3 ? (
                        <div className="pt-2">
                          <IconUp color={isOpen3 ? "white" : "#666666"} />
                        </div>
                      ) : (
                        <div className="pt-2">
                          <IconDown color={isOpen3 ? "white" : "#666666"} />
                        </div>
                      )}
                    </div>
                    {isOpen3 && (
                      <ul className="text-black absolute md:absolute top-[90%] z-[900] -left-1 block bg-white border shadow-lg">
                        <li className="py-2 px-4 hover:bg-secondaryl hover:text-white hover:font-semibold">
                          <Link to="/corporations/owners">Create Owners</Link>
                        </li>

                        <li className="py-2 px-4 whitespace-nowrap w-full hover:bg-secondaryl hover:text-white hover:font-semibold">
                          <Link
                            to="/corporations/tenant"
                            className="whitespace-nowrap w-full flex items-center"
                          >
                            Create Tenant
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                )}

                <li className="px-3 z-0 relative py-2.5 text-black text-base font-normal hover:bg-secondaryl hover:text-white hover:font-semibold transition-transform duration-300 transform hover:translate-x-2">
                  <Link to="/corporations/dashboard" className="block md:p-0">
                    Dashboard
                  </Link>
                </li>
                <li className="px-3 py-2.5 text-black text-base font-normal hover:bg-secondaryl hover:text-white hover:font-semibold transition-transform duration-300 transform hover:translate-x-2">
                  <Link to="/corporations/messages" className="block md:p-0">
                    Messages
                  </Link>
                </li>
              </>
            )}
            {/* Other links */}
            {cookies.get("companyType") !== "admin" && (
              <>
                <li className="px-3 py-2.5 z-0 relative text-black text-base font-normal hover:bg-secondaryl hover:text-white hover:font-semibold transition-transform duration-300 transform hover:translate-x-2">
                  <Link to="/about" className="block md:p-0">
                    About
                  </Link>
                </li>
                <li className="px-3 py-2.5 z-0 relative text-black text-base font-normal hover:bg-secondaryl hover:text-white hover:font-semibold transition-transform duration-300 transform hover:translate-x-2">
                  <Link to="/blog" className="block md:p-0">
                    Blog
                  </Link>
                </li>
                <li className="px-3 py-2.5 z-0 relative text-black text-base font-normal hover:bg-secondaryl hover:text-white hover:font-semibold transition-transform duration-300 transform hover:translate-x-2">
                  <Link
                    to="/contact"
                    className="block whitespace-nowrap md:p-0"
                  >
                    Contact us
                  </Link>
                </li>
              </>
            )}

            {companyType === "contractor" && token && (
              <>
                <li className="px-3 py-2.5 text-black text-base font-normal hover:bg-secondaryl hover:text-white hover:font-semibold transition-transform duration-300 transform hover:translate-x-2">
                  <Link to="/contractors/reviews" className="block  md:p-0">
                    Reviews
                  </Link>
                </li>
                <li className="px-3 py-2.5 text-black text-base font-normal hover:bg-secondaryl hover:text-white hover:font-semibold transition-transform duration-300 transform hover:translate-x-2">
                  <Link to="/contractors/messages" className="block  md:p-0">
                    Messages
                  </Link>
                </li>
                <li
                  onMouseEnter={() => setIsOpen2(!isOpen2)}
                  onMouseLeave={() => setIsOpen2(!isOpen2)}
                  className="px-3 py-2.5 relative text-black text-base font-normal hover:bg-secondaryl hover:text-white hover:font-semibold
                  transition-transform duration-300 transform hover:translate-x-2
                  "
                >
                  <Link
                    to="/contractors/contractlisting"
                    className="flex gap-2 font-bold md:p-0 "
                  >
                    Projects
                    {isOpen2 ? (
                      <div className="pt-2">
                        <IconUp color={isOpen2 ? "white" : "#666666"} />
                      </div>
                    ) : (
                      <div className="pt-2  ">
                        <IconDown color={isOpen2 ? "white" : "#666666"} />
                      </div>
                    )}
                  </Link>
                  {isOpen2 && (
                    <ul className=" z-50 text-black absolute top-[90%] left-0 bg-white border  shadow-lg">
                      <li className="py-2 px-4 truncate hover:bg-secondaryl hover:text-white hover:font-semibold">
                        <Link to="/contractors/contractlisting">
                          Current Listings
                        </Link>
                      </li>
                      <li className=" z-50 py-2 px-4 truncate hover:bg-secondaryl hover:text-white hover:font-semibold">
                        <Link to="/contractors/appliedcontracts">
                          Applied Listings
                        </Link>
                      </li>
                      <li className=" z-50 py-2 px-4 truncate hover:bg-secondaryl hover:text-white hover:font-semibold">
                        <Link to="/contractors/approvedcontracts">
                          Approved Listings
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
              </>
            )}

            {/* Login dropdown */}
            {!token && (
              <li
                className="px-3 py-2.5 z-0 relative text-black text-base font-normal hover:bg-secondaryl hover:text-white hover:font-semibold transition-transform duration-300 transform hover:translate-x-2"
                onMouseEnter={() => setIsLoginDropdownOpen(true)}
                onMouseLeave={() => setIsLoginDropdownOpen(false)}
              >
                <div
                  onClick={() => setIsLoginDropdownOpen(!isLoginDropdownOpen)}
                  className="flex items-center cursor-pointer"
                >
                  <span>Login</span>
                  {isLoginDropdownOpen ? (
                    <div className="pl-2 ">
                      <IconUp
                        color={isLoginDropdownOpen ? "white" : "#666666"}
                      />
                    </div>
                  ) : (
                    <div className="pl-2">
                      <IconDown
                        color={isLoginDropdownOpen ? "white" : "#666666"}
                      />
                    </div>
                  )}
                </div>
                {isLoginDropdownOpen && (
                  <ul className="text-black absolute top-[90%] z-[900] left-0 block bg-white border shadow-lg">
                    <li className="py-2 cursor-pointer relative px-4 hover:bg-secondaryl hover:text-white hover:font-semibold">
                      <div
                        onClick={() => {
                          setIsLoginDropdownOpen(true);
                          setIsCorporation(!iscorporation);
                        }}
                      >
                        Corporations
                      </div>
                      {iscorporation && (
                        <ul className="left-[100%] absolute border-t border-gray-300 bg-gray-300">
                          <li className="py-2 px-4 text-black hover:bg-secondaryl hover:text-white hover:font-semibold">
                            <NavLink to="/corporations/login">
                              Property Managers
                            </NavLink>
                          </li>
                          <li className="py-2 px-4 text-black hover:bg-secondaryl hover:text-white hover:font-semibold">
                            <NavLink to="/corporations/boardmember/login">
                              Board Members
                            </NavLink>
                          </li>
                        </ul>
                      )}
                    </li>

                    <li className="py-2 px-4 hover:bg-secondaryl hover:text-white hover:font-semibold">
                      <NavLink to="/contractors/login">Contractors</NavLink>
                    </li>
                    <li className="py-2 px-4 hover:bg-secondaryl hover:text-white hover:font-semibold">
                      <div onClick={() => toast.success("Coming Soon")}>
                        Owners
                      </div>
                    </li>
                    <li className="py-2 px-4 hover:bg-secondaryl hover:text-white hover:font-semibold">
                      <div onClick={() => toast.success("Coming Soon")}>
                        Tenants
                      </div>
                    </li>
                  </ul>
                )}
              </li>
            )}
          </ul>
        </div>

        {/* Profile Section */}
        {token && (
          <li className="py-2.5 relative flex flex-row text-black text-base  font-normal cursor-pointer">
            <>
              <FontAwesomeIcon
                icon={faBell}
                className="text-dark text-lg mr-4 mt-3"
              />
              {profile ? (
                <img
                  src={profile}
                  alt=""
                  className="w-14 h-14 rounded-full "
                  onClick={dropdownHandler}
                  ref={dropdownRefImg}
                />
              ) : (
                <img
                  src={userimg}
                  alt=""
                  className="w-14 h-14 rounded-full"
                  onClick={dropdownHandler}
                  ref={dropdownRefImg}
                />
              )}
              {drop && (
                <div
                  className="absolute  top-[65px] right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabIndex="-1"
                >
                  <div className="py-1 relative z-[999]" role="none">
                    <div
                      onClick={() => {
                        if (companyType === "contractor") {
                          navigator("/contractors/profile");
                        } else if (cookies.get("access") === "board member") {
                          toast.error(
                            "Board Members are not allowed to change anything in profile!!! Please Contact Your Property Manager"
                          );
                        } else if (companyType === "corporation") {
                          navigator("/corporations/profile");
                        } else if (companyType === "admin") {
                          navigator("/admin/profile");
                        } else {
                          navigator("/");
                        }
                      }}
                      className="text-gray-700 block px-4 py-2 text-sm   hover:bg-secondaryl hover:text-white hover:font-semibold"
                    >
                      My Profile
                    </div>
                  </div>
                  <div className="py-1" role="none" onClick={logoutHandler}>
                    <div className="text-gray-700 flex px-4 py-2 text-sm  items-center  hover:bg-secondaryl hover:text-white hover:font-semibold">
                      <FontAwesomeIcon
                        icon={faSignOutAlt}
                        className="text-lg mr-2 transition-colors hover:text-white"
                      />
                      Logout
                    </div>
                  </div>
                </div>
              )}
            </>
          </li>
        )}
      </div>
    </nav>
  );
};

export default Header;
