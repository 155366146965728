import DisplayContracts from "../../../components/Contracts/DisplayContracts.jsx";
import React, { useState, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { contractAPI } from "../../../constants/API.js";
import Cookies from "universal-cookie";
import { LogoWithoutText } from "../../../assets/images/index.jsx";
import Layout from "../../../components/layout/Layout.jsx";

function ManageContracts() {
  const cookies = new Cookies();
  const [contracts, setContracts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [load, setLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectSortOpportunity, setSelectSortOpportunity] = useState("Sort");
  const [selectFilterOpportunity, setSelectFilterOpportunity] =
    useState("Filter");

  const sortOpportunities = [
    { id: 1, label: "Popularity" },
    { id: 2, label: "Newest First" },
    { id: 3, label: "Oldest First" },
    { id: 4, label: "Closing soon" },
    { id: 5, label: "Job Closed" },
  ];

  const filterOpportunities = [
    { id: 1, label: "All" },
    { id: 2, label: "Open" },
    { id: 3, label: "Closed" },
  ];

  const token = cookies.get("token");
  const fetchData = useCallback(async () => {
    try {
      setLoad(true);

      const apiUrl = `${contractAPI}/corporationarchivescontracts`;
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: token,
          companyType: "corporation",
        },
        params: {
          page: page,
          pageSize: 5,
          sort: selectSortOpportunity,
          filter: selectFilterOpportunity,
        },
      });

      let serverContracts = response.data.data.contract.map((contract) => {
        const { CpProposals, ...rest } = contract; // Destructure CpProposals and rest of the properties

        return {
          ...rest, // Spread the rest of the properties
          proposal: { ...CpProposals[0] }, // Assign proposal or null if CpProposals is empty
        };
      });

      setContracts(serverContracts);
      setTotalPages(response.data.data.totalPages);
      setCurrentPage(response.data.data.currentPage);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoad(false);
    }
  }, [page, selectSortOpportunity, selectFilterOpportunity, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Layout>
      {load && (
        <div className="absolute z-50 flex items-center justify-center h-full w-full bg-black opacity-55">
          <div className="loginLoader"></div>
          <img
            src={LogoWithoutText}
            className="lazy_image absolute"
            height={100}
            width={100}
            alt=""
          />
        </div>
      )}
      <DisplayContracts
        title="Project Archives"
        contracts={contracts}
        totalPages={totalPages}
        currentPage={currentPage}
        setPage={setPage}
        buttonName={"VIEW"}
        contractsPageType={"manage"}
        load={load}
        setLoad={setLoad}
        setContracts={setContracts}
        selectSortOpportunity={selectSortOpportunity}
        setSelectSortOpportunity={setSelectSortOpportunity}
        selectFilterOpportunity={selectFilterOpportunity}
        setSelectFilterOpportunity={setSelectFilterOpportunity}
        sortOpportunities={sortOpportunities}
        filterOpportunities={filterOpportunities}
      />
    </Layout>
  );
}

export default ManageContracts;
