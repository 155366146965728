import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../../components/layout/Layout";
import { contractAPI } from "../../../constants/API";
import Cookies from "universal-cookie";
import axios from "axios";
import { Location } from "../../../assets/images";
import {
  fetchProposals,
  updateProposalStatusToSeen,
} from "../../../constants/Request";
import { Link, useNavigate } from "react-router-dom";
import uimage from "../../../assets/image/user.png";
import Pagination2 from "../../../components/Elements/Pagination2";
import toast from "react-hot-toast";
import AssignBoardMember from "../../../components/Corporation/BoardMember/AssignBoardMember";
import Loading from "../../../components/Elements/Loading";
import { TbHandClick } from "react-icons/tb";
import Test from "../../Test";
import { set } from "react-hook-form";

function Dashboard() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [contracts, setContracts] = useState([]);
  const [proposals, setProposals] = useState([]);
  const [location, setlocation] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [load, setLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const accessName = cookies.get("access");
  const fetchData = useCallback(async () => {
    try {
      setLoad(true);
      const apiUrl = `${contractAPI}/corporationdashboardcontracts`;
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "corporation",
        },
        params: {
          page: page,
          pageSize: 5,
        },
      });
      setContracts(getContractCardImage(response.data.data.data));
      setTotalPages(response.data.data.totalPages);
      setCurrentPage(response.data.data.currentPage);
    } catch (error) {
      console.error(error.response.data.message);
    } finally {
      setLoad(false);
    }
  }, [page]);

  useEffect(() => {
    fetchData();
    handleCloseModel();
  }, [fetchData]);

  const handleOpenModel = () => {
    setIsModelOpen(true);
  };

  const [sampleApprovalProposalPdf, setSampleApprovalProposalPdf] =
    useState(null);
  const handleCloseModel = () => {
    setSampleApprovalProposalPdf(null);
    setIsModelOpen(false);
  };

  const getProposalsHandler = async (id, projectLocation) => {
    try {
      setLoad(true);
      const res = await fetchProposals(id);
      if (!Array.isArray(res.data.proposals)) {
        setProposals([]);
        setlocation("");
      } else {
        setProposals(res.data.proposals);
        setlocation(projectLocation);
      }
    } catch (error) {
      setlocation("");
      setProposals([]);
      toast.error(error.response.data.message);
    } finally {
      setLoad(false);
    }
  };

  const viewProposalsHandler = async (ID, status, item) => {
    try {
      if (status === 1) {
        navigate(`/corporations/proposal/${ID}`, { state: item });
      } else {
        const res = await updateProposalStatusToSeen(ID);
        if (res.status === 200) {
          navigate(`/corporations/proposal/${ID}`);
        }
      }
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  const proposalapprovalHandler = async (sampleapprovalproposalpdf) => {
    setSampleApprovalProposalPdf(sampleapprovalproposalpdf);
    handleOpenModel();
  };

  const getContractCardImage = (data) => {
    data.map((item, index) => {
      for (let i = 1; i <= 10; i++) {
        if (item[`Project_Photos_${i}`]) {
          data[index]["ContractCard_Image"] = item[`Project_Photos_${i}`];
          break;
        }
      }
    });

    return data;
  };

  return (
    <>
      <Loading load={load} />

      <Layout>
        <div className="text-center px-[10%]">
          <hr className="my-4" />
          <h1 className="text-4xl euclidbold mb-4 text-primaryl  flex mr-auto ml-10">
            Dashboard
          </h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-12 gap-6 px-[10%] m-auto relative">
          {/* First Column */}

          <div className="col-span-7 md:shadow-md h-fit border justify-between md:w-auto flex md:flex-col flex-wrap md:gap-2 p-4 mb-6 mt-6">
            <h2 className="text-2xl euclidbold text-indigo-900 font-bold">
              Current Listing
            </h2>

            {contracts.length > 0 && (
              <div className="w-full">
                {contracts.map((item, index) => (
                  <div
                    key={item.ID}
                    className="w-full z-0 shadow-lg flex border border-gray-400 rounded my-2 justify-evenly items-center flex-col lg:flex-row py-2 lg:py-0 hover:bg-gray-100"
                  >
                    {/* Card Images  */}
                    <div className="h-full w-full flex lg:justify-start items-center justify-center py-2 lg:py-0">
                      {item.ContractCard_Image ? (
                        <img
                          src={item.ContractCard_Image}
                          alt={item.Project_Name}
                          className="w-[140px] h-[140px] object-fill p-4 pl-0 pr-0"
                        />
                      ) : (
                        <p className="p-3">No Image</p>
                      )}
                    </div>
                    {/* Card Content */}
                    <div className="w-full h-full flex flex-col justify-center lg:justify-start items-center lg:items-start py-2 lg:py-0">
                      {/* Title */}
                      <div className="text-indigo-900 euclidbold text-xl mb-2 font-bold">
                        {item.Project_Name}
                      </div>
                      {/* Location */}
                      <div className="text-sm flex space-x-2 ">
                        <Location />
                        <span className="text-gray-500">
                          {item.Project_Location}
                        </span>
                      </div>
                    </div>
                    {/* Card Buttons */}
                    <div className="h-full w-full flex flex-col justify-between lg:justify-start items-center pr-[5%] py-2 lg:py-0">
                      <div
                        onClick={() =>
                          getProposalsHandler(item.ID, item.Project_Location)
                        }
                        className=" w-[150px] z-10 px-1 py-1.5 euclidbold items-center  cursor-pointer ring-1 ring-cyan-500 flex justify-center text-cyan-500 text-[14px] truncate hover:ring-cyan-600  tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white transition duration-300 ease-in-out md:mt-0 "
                      >
                        {item.proposalCount} BIDS
                      </div>
                      {cookies.get("access") === "board member" ? (
                        ""
                      ) : (
                        <div
                          onClick={() => {
                            if (cookies.get("status") === 2) {
                              return toast.error("profile paused");
                            } else {
                              return navigate(
                                `/corporations/editcontract/${item.ID}`
                              );
                              // return navigate(`/test2/${item.ID}`);
                            }
                          }}
                          className=" w-[150px] px-1 py-1.5 euclidbold items-center  cursor-pointer ring-1 ring-cyan-500 flex justify-center truncate text-cyan-500 text-[14px] hover:ring-cyan-600 font-bold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white transition duration-300 ease-in-out mt-3 md:mt-0"
                        >
                          EDIT LISTING
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {contracts &&
              !load &&
              !(contracts.length > 0) &&
              accessName !== "board member" && (
                <div>
                  <div className="text-center text-lg text-gray-600 mt-10">
                    Current Listing are not available at the moment ! Please add
                    new listing
                  </div>

                  <div className="mt-3">
                    <Link to="/corporations/contract">
                      <span className="font-bold flex items-center justify-center text-indigo-900 hover:text-indigo-950 euclidbold">
                        <TbHandClick /> Create New Listing
                      </span>
                    </Link>
                  </div>
                </div>
              )}

            {contracts &&
              !load &&
              !(contracts.length > 0) &&
              accessName == "board member" && (
                <div>
                  <div className="text-center text-lg text-gray-600 mt-10">
                    Current Listing are not available at the moment
                  </div>
                </div>
              )}

            {!load && (
              <div className="my-5">
                {totalPages > 1 && (
                  // <Pagination page={page} setPage={setPage} totalPages={totalPages} />
                  <Pagination2
                    totalPages={totalPages} // Total number of pages
                    currentPage={currentPage} // Current active page
                    setPage={setPage}
                    setContracts={setContracts}
                    setLoad={setLoad}
                  />
                )}
              </div>
            )}
          </div>
          {/* Second Column */}
          <div className="col-span-5 border-l pl-4 mb-4">
            <div className=" md:shadow-md h-fit border justify-between md:w-auto flex md:flex-col flex-wrap md:gap-2 p-4 mb-6 mt-6">
              <h2 className="text-2xl euclidbold text-indigo-900 font-bold">
                Submitted Proposals
              </h2>
              {location === "" ? (
                <div>No Proposals Yet</div>
              ) : (
                <>
                  {proposals.map((item) => {
                    return (
                      <div
                        key={item.ID}
                        className="w-[100%] shadow-lg flex border border-gray-400 rounded my-2 justify-evenly items-center flex-col lg:flex-row py-2 lg:py-0"
                      >
                        {/* Card Images  */}
                        <div className="h-full w-full flex lg:justify-start items-center justify-center py-2 lg:py-0">
                          <img
                            src={uimage}
                            alt={item.projectName}
                            className="w-[100px] h-[100px] object-fill p-2 pl-2 rounded-full"
                          />
                        </div>
                        {/* Card Content */}
                        <div className="w-full h-full flex flex-col justify-center lg:justify-start items-center lg:items-start py-2 lg:py-0">
                          {/* Title */}
                          <div className="text-indigo-900 euclidbold text-xl mb-2 font-bold">
                            {item.Company_Name}
                            {/* {item.Your_Name} */}
                          </div>
                          {/* Location */}
                          <div className="text-sm flex space-x-2 ">
                            <Location />
                            <span className="text-gray-500">{location}</span>
                          </div>
                        </div>
                        {/* Card Buttons */}
                        <div className="h-full w-full flex flex-col justify-between lg:justify-start items-center pr-[5%] py-2 lg:py-0">
                          <div
                            onClick={() =>
                              viewProposalsHandler(item.ID, item.Status, item)
                            }
                            className="w-[150px] px-1 py-1.5 euclidbold items-center cursor-pointer ring-1 ring-cyan-500 flex justify-center text-cyan-500 text-[13px] truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white transition duration-300 ease-in-out md:mt-0"
                          >
                            VIEW PROPOSAL
                          </div>
                          {cookies.get("access") === "board member" ? (
                            ""
                          ) : (
                            <button
                              disabled={
                                cookies.get("access") === "board member"
                              }
                              onClick={() => {
                                if (cookies.get("status") === 2) {
                                  return toast.error("profile paused");
                                } else {
                                  return proposalapprovalHandler(
                                    item.Sample_Approval_Proposal_PDF
                                  );
                                }
                              }}
                              className="w-[150px] px-1 py-1.5 P-2 euclidbold items-center cursor-pointer ring-1 ring-cyan-500 flex justify-center truncate text-cyan-500 text-[13px] hover:ring-cyan-600 font-bold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white transition duration-300 ease-in-out mt-3 md:mt-0"
                            >
                              ACCEPT PROPOSAL
                            </button>
                          )}
                        </div>
                        {/* Model */}

                        <AssignBoardMember
                          isOpen={isModelOpen}
                          onClose={handleCloseModel}
                          proposalId={item.ID}
                          setLoad={setLoad}
                          load={load}
                          sampleApprovalProposalPdf={sampleApprovalProposalPdf}
                          setSampleApprovalProposalPdf={
                            setSampleApprovalProposalPdf
                          }
                        />
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Dashboard;
