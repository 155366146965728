import React, { useState } from "react";
import {
  ContractorProfileLeftImage,
  LogoWithoutText,
} from "../../../assets/images";
import MyProfile from "./MyProfile";
import Loading from "../../Elements/Loading";

function ProfilePage() {
  const [load, setLoad] = useState(false);

  return (
    <div>
      <div className="h-full w-full flex relative ">
        <Loading load={load} />

        {/* First div : images */}

        <div className="w-[70%] hidden md:block ">
          <img
            src={ContractorProfileLeftImage}
            className="absolute top-0 bottom-0 w-[40%] h-full object-cover"
            alt="Singup background"
          />
        </div>

        <div className="absolute hidden bottom-[-1%] left-0 w-full md:w-[40%] h-[40%] bg-gradient-to-b from-transparent to-[#0893bd]  md:block"></div>

        {/* Secound Div */}
        <MyProfile load={load} setLoad={setLoad} />
      </div>
    </div>
  );
}

export default ProfilePage;
