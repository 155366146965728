import React, { useState } from "react";
import Layout from "../../../components/layout/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { reviewsAPI } from "../../../constants/API";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { LogoWithoutText } from "../../../assets";

function SetReviews() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [rating, setRating] = useState(0);
  const params = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
      const res = await axios.post(
        reviewsAPI,
        { contractId: params.id, name, description, rating },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );
      if (res.statusText === "Created") {
        navigate("/corporations/managecontracts");
      }
    } catch (error) {
      toast.error("Failed to submit review. Please try again later.");
    } finally {
      setName("");
      setDescription("");
      setRating(0);
      setLoad(false);
    }
  };

  return (
    <Layout>
      {load && (
        <div className="absolute z-50 flex items-center justify-center h-full w-full bg-black opacity-55">
          <div className="loginLoader"></div>
          <img
            src={LogoWithoutText}
            className="lazy_image absolute"
            height={100}
            width={100}
            alt=""
          />
        </div>
      )}
      <div className="max-w-lg mx-auto mt-10">
        <h1 className="text-2xl font-semibold mb-6">Write a Review</h1>
        <form onSubmit={handleSubmit}>
          {/* Your Name */}
          <div className="mb-4">
            <label htmlFor="name" className="block mb-1 font-medium">
              Your Name
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-400"
            />
          </div>
          {/* Description */}
          <div className="mb-4">
            <label htmlFor="description" className="block mb-1 font-medium">
              Description
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-400"
            />
          </div>
          {/* Star Rating */}
          <div className="mb-4 flex items-center space-x-2">
            <label className="font-medium">Rating:</label>
            {[1, 2, 3, 4, 5].map((star) => (
              <FontAwesomeIcon
                key={star}
                icon={star <= rating ? solidStar : regularStar}
                className={`h-6 w-6 cursor-pointer text-yellow-500`}
                onClick={() => setRating(star)}
              />
            ))}
          </div>
          {/* Submit Button */}
          <button
            type="submit"
            className="w-full py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
          >
            Submit Review
          </button>
        </form>
      </div>
    </Layout>
  );
}

export default SetReviews;
