import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Cookies from "universal-cookie";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { approveProposal, viewFileHandler } from "../../constants/Request.jsx";
import ReadInputBox from "../Elements/ReadInputBox.jsx";
import Layout from "../layout/Layout.jsx";
import { LogoWithoutText } from "../../assets/images/index.jsx";
import axios from "axios";
import { proposalAPI } from "../../constants/API.js";
import toast from "react-hot-toast";
import AssignBoardMember from "../Corporation/BoardMember/AssignBoardMember.jsx";
import Loading from "../Elements/Loading.jsx";

function ProposalView() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const params = useParams();
  let proposalId = params.id;
  const [load, setLoad] = useState(false);
  const [proposalFileUrl, setProposalFileUrl] = useState(null);

  const [proposal, setproposal] = useState(false);
  const [fileType, setFileType] = useState(null);
  const [isModelOpen, setIsModelOpen] = useState(false);

  // Form validation schema using yup
  const schema = yup.object().shape({
    Company_Name: yup.string().required("Company Name is required"),
    Your_Name: yup.string().required("Your Name is required"),
    Designation: yup.string().required("Designation is required"),
    Estimate_Time: yup
      .number()
      .required("Estimate Time  is required")
      .typeError("Estimate Time  is required")
      .min(1, "Estimate time  must be greater than 0"),
    Other_Details: yup.string(),
    Attach_Proposal: yup.string().required("Proposal Upload is required"),
    token: yup.string().default(cookies.get("token")),
  });

  // Form handling using react-hook-form
  const {
    register,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema) });

  const [sampleApprovalProposalPdf, setSampleApprovalProposalPdf] =
    useState(null);

  // Already Send the Details From the before the call then optimize time permomance
  const fetchProposal = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${proposalAPI}/${proposalId}`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      if (response.data.data.Status === 2) {
        setproposal(true);
      }
      if (response.data) {
        Object.keys(response.data.data).forEach((key) => {
          setValue(key, response.data.data[key]);
        });

        const attachment = response.data.data.Attach_Proposal;
        setSampleApprovalProposalPdf(
          response.data.data.Sample_Approval_Proposal_PDF
        );
        // console.log(attachment);
        // Check if the attachment is an image
        if (
          attachment.endsWith(".jpg") ||
          attachment.endsWith(".jpeg") ||
          attachment.endsWith(".png")
        ) {
          setFileType("image");
          setProposalFileUrl(attachment);
        }
        if (attachment.endsWith(".pdf")) {
          setFileType("pdf");
          const newUrl = await viewFileHandler(`${attachment}`, "proposal");
          setProposalFileUrl(newUrl);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to Fetch Proposal");
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchProposal();
  }, []);

  const proposalapprovalHandler = async () => {
    handleOpenModel();
  };

  const handleOpenModel = () => {
    setIsModelOpen(true);
  };

  const handleCloseModel = () => {
    setIsModelOpen(false);
  };

  return (
    <Layout>
      <Loading load={load} />
      <div className="w-[93%] lg:max-w-[100%] mx-auto px-4 pt-10 sm:px-6 lg:px-8">
        <div className="p-5 shadow-lg  border border-gray-400   lg:border-gray-400 bg-white rounded">
          <div className="flex-1">
            <p className="text-2xl text-indigo-900  euclidbold py-3 ">
              Proposal
            </p>
            <hr className=" border-gray-300" />
          </div>
          <div className="mt-4">
            <form>
              <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 ">
                {/* Company Name */}
                <div className="mb-3 md:pr-4 lg:pr-0">
                  <ReadInputBox
                    title={"Company Name"}
                    register={register}
                    filedName={"Company_Name"}
                    must={true}
                  />
                </div>

                {/*Your Name  */}
                <div className="mb-3 md:pl-4 lg:pl-0">
                  <ReadInputBox
                    title={"Your Name"}
                    register={register}
                    filedName={"Your_Name"}
                    must={true}
                    errors={errors}
                  />
                </div>

                {/* Designations */}
                <div className="mb-3 md:pr-4 lg:pr-0">
                  <ReadInputBox
                    title={"Designation"}
                    register={register}
                    filedName={"Designation"}
                    must={true}
                    errors={errors}
                  />
                </div>
              </div>

              <div className="grid sm:grid-rows-1 md:grid-rows-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:grid-flow-col">
                {/* Estimation time */}
                <div className="mb-3 md:col-span-1 lg:pr-12 ">
                  <div className="flex items-center">
                    <ReadInputBox
                      title="Estimate time"
                      register={register}
                      filedName={"Estimate_Time"}
                      must={true}
                      errors={errors}
                      width="w-11/12"
                      className={"sm:text-base md:text-sm lg:text-base"}
                    />
                    <p className="">Days </p>
                  </div>
                </div>

                {/* Other Details */}
                <div className="md:mb-3 md:row-span-2 md:col-span-2 md:ml-14 lg:ml-0 md:pl-4">
                  <div className="mb-3">
                    {/* Label */}
                    <label
                      htmlFor="otherDetails"
                      className={`block font-normal `}
                    >
                      Other Details
                    </label>
                    {/* Input */}
                    <textarea
                      type="text"
                      id="otherDetails"
                      name="otherDetails"
                      {...register("Other_Details")}
                      className={` block h-40 max-h-40 w-full rounded-sm cursor-not-allowed text-gray-500 bg-gray-100  ring-1 px-2 ring-gray-400 ps-10 focus:ring-gray-400 focus:outline-none focus:border-gray-400`}
                      placeholder="Type Other Details"
                      readOnly
                      disabled
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="w-full h-full my-4">
            <label className={`block font-normal mb-2 `}>
              Attached Proposal <sup className="text-red-500 text-base">*</sup>
            </label>
            <p>
              {!load &&
                (proposalFileUrl ? (
                  fileType === "pdf" ? (
                    <>
                      <object
                        data={proposalFileUrl}
                        type="application/pdf"
                        width="100%"
                        height="600px"
                        className="w-full h-[90vh]"
                      >
                        <p>
                          Alternative text - include a link{" "}
                          <a href={"imageUrl"}>to the PDF!</a>
                        </p>
                      </object>
                      {/* <NavLink
                    to={proposalFileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="h-11 w-42 px-6 whitespace-nowrap py-2.5 border-2 border-cyan-500 text-cyan-500 text-xl font-bold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white transition duration-300 items-center flex justify-center euclidbold"
                  >
                    Download PDF
                  </NavLink> */}
                    </>
                  ) : (
                    <img
                      src={proposalFileUrl}
                      alt="Corporation Certificate"
                      className="w-[90vw] h-[50vh]"
                    />
                  )
                ) : (
                  "No Documents Provided"
                  // "No Documents Provided"
                ))}
            </p>
          </div>
        </div>

        {cookies.get("access") === "board member" ? (
          ""
        ) : (
          <div className="my-8 flex w-full flex-row justify-between items-center">
            <button
              onClick={() => {
                if (cookies.get("status") === 2) {
                  return toast.error("profile paused");
                } else {
                  return navigate("/corporations/messages");
                }
              }}
              className="h-11 w-32 px-6 py-2.5  border-2  border-cyan-500   text-cyan-500 text-xl font-bold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800  hover:text-white transition duration-300 items-center flex justify-center euclidbold "
            >
              MESSAGE
            </button>
            <button
              onClick={() => {
                if (cookies.get("status") === 2) {
                  return toast.error("profile paused");
                } else {
                  return proposalapprovalHandler();
                }
              }}
              disabled={proposal}
              className="h-11 w-32 px-6 py-2.5 border-2 border-cyan-500 text-cyan-500 text-xl font-bold tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:text-white transition duration-300 items-center flex justify-center euclidbold"
            >
              {!proposal ? "APPROVE" : "APPROVED"}
            </button>
          </div>
        )}
      </div>

      {/* Model */}
      <AssignBoardMember
        isOpen={isModelOpen}
        onClose={handleCloseModel}
        proposalId={proposalId}
        setLoad={setLoad}
        load={load}
        sampleApprovalProposalPdf={sampleApprovalProposalPdf}
        setSampleApprovalProposalPdf={setSampleApprovalProposalPdf}
      />
    </Layout>
  );
}

export default ProposalView;
