import React from "react";
import Layout from "../../../components/layout/Layout.jsx";
import MyProfile from "../../../components/Corporation/Profile/MyProfile.jsx";

function CorporationProfile() {
  return (
    <Layout>
     <MyProfile/>
    </Layout>
  );
}

export default CorporationProfile;
