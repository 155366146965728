import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { baseUrl, contractorAPI } from "../../constants/API";
import Cookies from "universal-cookie";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import { GoClockFill } from "react-icons/go";
import ContractorDetailsModal from "./ContractorDetailsModel";
import { BsEyeFill } from "react-icons/bs";
import { set } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";

const ManageContractors = ({ setLoad }) => {
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedContractor, setSelectedContractor] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newApplicationsCount, setNewApplicationsCount] = useState(0);
  const [approvedApplicationsCount, setApprovedApplicationsCount] = useState(0);
  const [rejectedApplicationsCount, setRejectedApplicationsCount] = useState(0);
  const cookies = new Cookies();
  const tableBodyRef = useRef(null);

  useEffect(() => {
    fetchContractorData();
  }, [activeTab]);

  useEffect(() => {
    setNewApplicationsCount(
      data.filter((item) => item.Verification_Status === null).length
    );
    setApprovedApplicationsCount(
      data.filter((item) => item.Verification_Status === 1).length
    );
    setRejectedApplicationsCount(
      data.filter((item) => item.Verification_Status === 0).length
    );
  }, [data]);

  useEffect(() => {
    // Scroll to top when data changes
    if (tableBodyRef.current) {
      tableBodyRef.current.scrollTop = 0;
    }
  }, [data]);

  const fetchContractorData = async () => {
    setLoad(true);
    try {
      const response = await axios.get(contractorAPI, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "admin",
        },
      });
      setData(response.data.contractors.reverse());
    } catch (error) {
      console.error("Error fetching contractor data:", error);
    } finally {
      setLoad(false);
    }
  };

  const filterData = () => {
    let filteredData = data;

    switch (activeTab) {
      case "New":
        filteredData = data.filter(
          (contractor) => contractor.Verification_Status === null
        );
        break;
      case "Approved":
        filteredData = data.filter(
          (contractor) => contractor.Verification_Status === true
        );
        break;
      case "Rejected":
        filteredData = data.filter(
          (contractor) => contractor.Verification_Status === false
        );
        break;
      default:
        filteredData = data;
        break;
    }

    if (searchTerm) {
      filteredData = filteredData.filter(
        (contractor) =>
          contractor.First_Name.toLowerCase().includes(
            searchTerm.toLowerCase()
          ) ||
          contractor.Last_Name.toLowerCase().includes(
            searchTerm.toLowerCase()
          ) ||
          contractor.Uid_Or_Email.toLowerCase().includes(
            searchTerm.toLowerCase()
          ) ||
          contractor.Recovery_Email.toLowerCase().includes(
            searchTerm.toLowerCase()
          ) ||
          contractor.Company_Name.toLowerCase().includes(
            searchTerm.toLowerCase()
          )
      );
    }

    return filteredData;
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const renderStatus = (status) => {
    if (status === null) {
      return (
        <div className="flex items-center">
          <GoClockFill className="text-blue-500 mr-1" />
          <span>Pending</span>
        </div>
      );
    } else if (status === 1) {
      return (
        <div className="flex items-center whitespace-nowrap text-green-500">
          <RiCheckLine className="mr-1" />
          Approved
        </div>
      );
    } else if (status === 0) {
      return (
        <div className="flex items-center whitespace-nowrap text-red-500">
          <RiCloseLine className="mr-1" />
          Rejected
        </div>
      );
    } else if (status === 2) {
      return (
        <div className="flex items-center whitespace-nowrap text-yellow-500">
          <GoClockFill className="mr-1" />
          Paused
        </div>
      );
    }
  };

  const handleViewDetails = (contractor) => {
    setSelectedContractor(contractor);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleContractorAction = async (contractorId, action) => {
    try {
      // Send PUT request to update the contractor status on the backend
      setLoad(true);
      await axios.put(
        `${baseUrl}/admin/contractor/${contractorId}`,
        { Verification_Status: action },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: "admin",
          },
        }
      );

      // Fetch all contractor data again to update the state
      await fetchContractorData();
      console.log("Contractor Status Updated");
      toast.success("Contractor Status Updated");
    } catch (error) {
      console.error(
        `Error performing ${action} action for contractor ${contractorId}:`,
        error
      );
      toast.error("Contractor Status Update Failed");
      // Handle error
    } finally {
      setLoad(false);
    }
  };

  return (
    <div>
      <div className="flex justify-center items-center mb-4">
        <button
          className={`px-4 py-2 rounded whitespace-nowrap hover:bg-cyan-600 hover:text-white ${
            activeTab === "New" ? "bg-cyan-500 text-white" : "bg-gray-300"
          }`}
          onClick={() => handleTabClick("New")}
        >
          New Applications ({newApplicationsCount})
        </button>
        <button
          className={`px-4 py-2 rounded whitespace-nowrap hover:bg-cyan-600 hover:text-white ${
            activeTab === "Approved" ? "bg-cyan-500 text-white" : "bg-gray-300"
          } ml-4`}
          onClick={() => handleTabClick("Approved")}
        >
          Approved Applications ({approvedApplicationsCount})
        </button>
        <button
          className={`px-4 py-2 rounded whitespace-nowrap hover:bg-cyan-600 hover:text-white ${
            activeTab === "Rejected" ? "bg-cyan-500 text-white" : "bg-gray-300"
          } ml-4`}
          onClick={() => handleTabClick("Rejected")}
        >
          Rejected Applications ({rejectedApplicationsCount})
        </button>
        <form className="flex justify-center w-full rounded-xl">
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            className="border border-gray-300 px-4 py-2 rounded-l"
          />
        </form>
      </div>

      <div className="flex justify-center">
        <div
          className="overflow-auto max-h-[60vh] w-full table-container"
          ref={tableBodyRef}
        >
          <table className="table-auto w-full relative">
            <thead className="relative">
              <tr>
                <th className="px-4 py-2 text-sm">Name</th>
                <th className="px-4 py-2 text-sm">Company Name</th>
                <th className="px-4 py-2 text-sm">Designation</th>
                <th className="px-4 py-2 text-sm">Uid_Or_Email</th>
                <th className="px-4 py-2 text-sm">View</th>
                <th className="px-4 py-2 text-sm">Status</th>
                {/* New action column headers */}
                <th className="px-4 py-2 text-sm">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filterData().map((contractor) => (
                <tr key={contractor.ID}>
                  <td className="border px-4 py-2">
                    {contractor.First_Name} {contractor.Last_Name}
                  </td>
                  <td className="border px-4 py-2">
                    {contractor.Company_Name}
                  </td>
                  <td className="border px-4 py-2">{contractor.Designation}</td>
                  <td className="border px-4 py-2">
                    {contractor.Uid_Or_Email}
                  </td>
                  <td className="border px-4 py-2">
                    <BsEyeFill
                      onClick={() => handleViewDetails(contractor)}
                      className="text-blue-500 cursor-pointer"
                    />
                  </td>
                  <td className="border px-4 py-2">
                    {renderStatus(contractor.Verification_Status)}
                  </td>
                  {/* Action buttons */}
                  <td className="border px-4 py-2">
                    {/* Approve button */}
                    {contractor.Verification_Status !== 1 && (
                      <button
                        onClick={() => handleContractorAction(contractor.ID, 1)}
                        className="text-green-500 hover:text-green-700"
                      >
                        Approve
                      </button>
                    )}

                    {/* Reject button */}
                    {contractor.Verification_Status !== 0 && (
                      <button
                        onClick={() => handleContractorAction(contractor.ID, 0)}
                        className="text-red-500 hover:text-red-700 ml-2"
                      >
                        Reject
                      </button>
                    )}
                    {/* Pause button */}
                    {contractor.Verification_Status !== 2 && (
                      <button
                        onClick={() => handleContractorAction(contractor.ID, 2)}
                        className="text-yellow-500 hover:text-yellow-700 ml-2"
                      >
                        Pause
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ContractorDetailsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        contractor={selectedContractor}
        setLoad={setLoad}
        fetchContractorData={fetchContractorData}
      />
    </div>
  );
};

export default ManageContractors;
