import { createRoot } from "react-dom/client";
import "./index.css";
import 'react-loading-skeleton/dist/skeleton.css'

import App from "./App";
import { Provider } from "react-redux";
import store from "./store";

const rootElement = createRoot(document.getElementById("root"));
rootElement.render(
  
    <Provider store={store}>
      <div className="logo_heading">
        <App />
      </div>
    </Provider>

);
