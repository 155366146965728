import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { set, useForm } from "react-hook-form";
import Cookies from "universal-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { viewFileHandler } from "../../constants/Request.jsx";
import ReadInputBox from "../Elements/ReadInputBox.jsx";

import { IoDocumentAttach } from "react-icons/io5";
import { HiDocumentCheck } from "react-icons/hi2";
import AssignBoardMemberDetails from "./AssignBoardMemberDetails.jsx";

function ContractorProposalView({ contract }) {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const params = useParams();
  let proposalId = params.id;
  const [load, setLoad] = useState(false);
  const [proposalFileUrl, setProposalFileUrl] = useState(null);
  const [proposalFileType, setProposalproposalFileType] = useState(null);
  const [approveProposalFileUrl, setApproveProposalFileUrl] = useState(null);
  const [approveProposalFileType, setApproveProposalFileType] = useState(null);
  const [companyType, setCompanyType] = useState(cookies.get("companyType"));
  const [docs, setDocs] = useState();

  // Form validation schema using yup
  const schema = yup.object().shape({
    Company_Name: yup.string().required("Company Name is required"),
    Your_Name: yup.string().required("Your Name is required"),
    Designation: yup.string().required("Designation is required"),
    Estimate_Time: yup
      .number()
      .required("Estimate Time  is required")
      .typeError("Estimate Time  is required")
      .min(1, "Estimate time  must be greater than 0"),
    Other_Details: yup.string(),
    Attach_Proposal: yup.string().required("Proposal Upload is required"),
    // token: yup.string().default(cookies.get("token")),
  });

  const {
    register,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    const setDataHandler = async () => {
      setLoad(true);
      try {
        if (contract) {
          Object.keys(contract.proposal).forEach((key) => {
            setValue(key, contract.proposal[key]);
          });

          if (contract.proposal.Attach_Proposal.endsWith(".pdf")) {
            const fileurl = await viewFileHandler(
              contract.proposal.Attach_Proposal,
              "proposal"
            );

            setProposalproposalFileType("pdf");
            setProposalFileUrl(fileurl);
          } else if (
            contract.proposal.Attach_Proposal.endsWith(".docx") ||
            contract.proposal.Attach_Proposal.endsWith(".doc")
          ) {
            const fileurl = await viewFileHandler(
              contract.proposal.Attach_Proposal,
              "proposal"
            );

            console.log("fileurl docs", fileurl);
            setDocs([{ uri: fileurl }]);
            setProposalproposalFileType("word");
            setProposalFileUrl(fileurl);
          } else {
            setProposalproposalFileType("image");
            setProposalFileUrl(contract.proposal.Attach_Proposal);
          }

          if (contract.proposal.Status === 2) {
            if (contract.proposal.Approval_Proposal_PDF.endsWith(".pdf")) {
              const fileurl = await viewFileHandler(
                contract.proposal.Approval_Proposal_PDF,
                "approvalproposalpdf"
              );
              setApproveProposalFileType("pdf");
              setApproveProposalFileUrl(fileurl);
            } else {
              setApproveProposalFileType("image");
              setApproveProposalFileUrl(
                contract.proposal.Approval_Proposal_PDF
              );
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoad(false);
      }
    };

    setDataHandler();
  }, [contract]);

  return (
    <>
      <div className="w-[93%] lg:max-w-[100%] mx-auto px-4 pt-10 sm:px-6 lg:px-8">
        <div className="flex-1">
          <p className="text-2xl text-indigo-900  euclidbold py-3 ">Proposal</p>
          <hr className=" border-gray-300" />
        </div>
        <div className="mt-4">
          <form>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 ">
              {/* Company Name */}
              <div className="mb-3 md:pr-4 lg:pr-0">
                <ReadInputBox
                  title={"Company Name"}
                  register={register}
                  filedName={"Company_Name"}
                  must={true}
                />
              </div>

              {/*Your Name  */}
              <div className="mb-3 md:pl-4 lg:pl-0">
                <ReadInputBox
                  title={"Your Name"}
                  register={register}
                  filedName={"Your_Name"}
                  must={true}
                  errors={errors}
                />
              </div>

              {/* Designations */}
              <div className="mb-3 md:pr-4 lg:pr-0">
                <ReadInputBox
                  title={"Designation"}
                  register={register}
                  filedName={"Designation"}
                  errors={errors}
                  must={true}
                />
              </div>
            </div>

            <div className="grid sm:grid-rows-1 md:grid-rows-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:grid-flow-col">
              {/* Estimation time */}
              <div className="mb-3 md:col-span-1 lg:pr-12 ">
                <div className="flex items-center">
                  <ReadInputBox
                    title="Estimate time"
                    register={register}
                    filedName={"Estimate_Time"}
                    errors={errors}
                    width="w-11/12"
                    className={"sm:text-base md:text-sm lg:text-base"}
                    must={true}
                  />
                  <p className="">Days </p>
                </div>
              </div>

              {/* Other Details */}
              <div className="md:mb-3 md:row-span-2 md:col-span-2 md:ml-14 lg:ml-0 md:pl-4">
                <div className=" mb-3">
                  {/* Label */}
                  <label
                    htmlFor="otherDetails"
                    className={`block font-normal `}
                  >
                    Other Details
                  </label>
                  {/* Input */}
                  <textarea
                    type="text"
                    id="otherDetails"
                    name="otherDetails"
                    {...register("Other_Details")}
                    className={` block h-40 max-h-40 w-full rounded-sm cursor-not-allowed text-gray-500 bg-gray-200  ring-1 px-2 ring-gray-400 ps-10 focus:ring-gray-400 focus:outline-none focus:border-gray-400`}
                    placeholder="Type Other Details"
                    readOnly
                    disabled
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="w-full h-full my-4">
          <label
            className={`flex gap-2 items-center font-normal text-xl  text-indigo-900 euclidbold mb-2`}
          >
            <IoDocumentAttach />
            <p className="underline">Attach Proposal</p>
            <sup className="text-red-500 text-base ">*</sup>
          </label>
          {load ? (
            "Loading..."
          ) : proposalFileUrl ? (
            proposalFileType === "pdf" ? (
              <object
                data={proposalFileUrl}
                type="application/pdf"
                width="100%"
                height="600px"
                className="w-full h-[90vh]"
              >
                <p>PDF could not be displayed</p>
              </object>
            ) : proposalFileType === "word" ? (
              <iframe
                src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                  "https://condominiumstorage.blob.core.windows.net/cpdevtest/proposal/1723489396265vvv.docx"
                )}`}
                width="100%"
                height="600px"
                frameBorder="0"
                title="Document Viewer"
              />
            ) : (
              // <DocxToHtml docxUrl={proposalFileUrl} />
              <img
                src={proposalFileUrl}
                alt="Proposal Document"
                className="w-[90vw] h-[50vh]"
              />
            )
          ) : (
            "No Documents Provided"
          )}
        </div>

        {contract.proposal.Status === 2 && (
          <div className="w-full h-full my-10">
            <label
              className={`flex gap-2 items-center font-normal text-xl underline text-indigo-900  euclidbold mb-2`}
            >
              <HiDocumentCheck />
              Approved Proposal Document :
            </label>
            {load ? (
              "Loading..."
            ) : approveProposalFileUrl ? (
              approveProposalFileType === "pdf" ? (
                <object
                  data={approveProposalFileUrl}
                  type="application/pdf"
                  width="100%"
                  height="600px"
                  className="w-full h-[90vh]"
                >
                  <p>PDF could not be displayed</p>
                </object>
              ) : (
                <img
                  src={approveProposalFileUrl}
                  alt="Approved Proposal Document"
                  className="w-[90vw] h-[50vh]"
                />
              )
            ) : (
              "No Documents Provided"
            )}
          </div>
        )}

        {(companyType === "corporation" || companyType === "broadmember") &&
          contract.proposal.Status === 2 && (
            <AssignBoardMemberDetails
              contract={contract}
              load={load}
              setLoad={setLoad}
            />
          )}
      </div>
    </>
  );
}

export default ContractorProposalView;
