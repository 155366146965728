import React, { useEffect, useState } from "react";

function InputBoxTimeLiveHandleChange({
  title,
  register,
  filedName,
  errors,
  placeHolder = "",
  className,
  type = "time",
  width = "w-full",
  min,
  max,
  maxLength = "",
  schema,
  setError,
  onChange,
}) {
  return (
    <div>
      <div className="flex gap-2">
        {/* Label */}
        <label htmlFor={filedName} className="block font-normal mt-1 min-w-8">
          {title}
        </label>
        {/* Input */}
        <input
          type={type}
          id={filedName}
          name={filedName}
          {...register(filedName)}
          className={` block bg-transparent h-10   min-w-32  rounded-sm text-black  ring-1 px-2 ring-gray-400 ps-10 focus:ring-sky-600 focus:outline-none focus:border-rose-600 ${
            errors[filedName] ? "ring-1 ring-red-500" : ""
          } ${className}`}
          placeholder="hrs:mins"
          onChange={onChange}
          // Conditionally attach the event listener
        />
      </div>
      {/* Error */}
      <div className="h-[3vh] pt-1 max-w-18">
        {errors[filedName] && (
          <p className="text-red-500 text-xs leading-none">
            {errors[filedName].message}
          </p>
        )}
      </div>
    </div>
  );
}

export default InputBoxTimeLiveHandleChange;
