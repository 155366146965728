import React, { useState, useEffect, useRef } from "react";
import { IconDown, IconUp } from "../../assets/images";

const CheckBoxDropDown = ({
  title,
  options,
  onSelect,
  selectOption,
  setValue,
  filedName,
  clearErrors,
  errors,
  dbfield,
  setError,
  schema,
  must = false,
  isOther = false,
  otherOptionId = "",
  isOpenOther = "",
  setIsOpenOther = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };
  const handleSelecteItems = (optionId, e) => {
    e.stopPropagation();
    const isSelected = selectOption.includes(optionId);
    if (isSelected) {
      onSelect(selectOption.filter((ID) => ID !== optionId));
    } else {
      onSelect((pre) => [...pre, optionId]);
    }

    if (must) {
      const value = e.target.value;
      const arrayValue = Array.isArray(value) ? value : [value];
      schema
        .validateAt(filedName, { [filedName]: arrayValue }) // Validate the field
        .then((valid) => {
          if (errors[filedName]) {
            clearErrors(filedName);
          }
        })
        .catch((err) => {
          // If the field is invalid, add the error message
          setError(filedName, { type: "manual", message: err.errors[0] });
        })
        .finally(() => {
          // Any cleanup or additional actions you want to perform
        });
    }

    if (
      isOther &&
      optionId === otherOptionId &&
      !selectOption.includes(otherOptionId)
    ) {
      setIsOpenOther(true);
      setIsOpen(false);
    }

    if (
      isOther &&
      optionId === otherOptionId &&
      selectOption.includes(otherOptionId)
    ) {
      setIsOpenOther(false);
    }
  };

  // const handleSelectOption = () => {
  //     onSelect(selectOption);
  //     setValue(filedName, selectOption);
  //     if (errors[filedName]) {
  //         clearErrors(filedName);
  //     }
  //     toggleDropdown();
  // };

  // Close dropdown when clicked outside

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setValue(`${filedName}`, selectOption);
  }, [selectOption]);

  return (
    <div ref={dropdownRef} className="relative inline-block">
      {/* Dropdown button */}
      <button
        className="text-gray-500 w-full h-14  shadow-lg focus:outline-none focus:font-medium rounded-sm text-base px-auto py-2.5 text-center inline-flex min-w-44 items-center"
        onClick={toggleDropdown}
        type="button"
      >
        <span className="grid grid-cols-2 items-center px-2 ">
          <p className="text-base ">{title}</p>
          <p className="justify-end flex">
            {" "}
            {isOpen ? <IconUp /> : <IconDown />}
          </p>
        </span>
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute z-50 h-auto max-h-60 overflow-y-auto top-full left-0 shadow-xl bg-white divide-y divide-gray-100 rounded-sm  w-full text-gray-800">
          <ul className="py-1 text-base text-gray-700 cursor-pointer">
            {options?.map((item, index) => (
              <li className="" key={index}>
                <div className="space-x-2 pt-2 px-2 flex items-center">
                  <input
                    type="checkbox"
                    className="cursor-pointer text-base -mt-2"
                    value={item.ID}
                    checked={selectOption.includes(item.ID)}
                    onChange={(e) => handleSelecteItems(item.ID, e)}
                    id={index}
                  />

                  <label htmlFor={index} className="text-base  cursor-pointer">
                    {item[dbfield]}
                  </label>
                </div>
                {index !== options.length - 1 && <hr className="" />}
              </li>
            ))}
          </ul>
        </div>
      )}
      {/* Error */}
      <div className="h-[3vh] pt-1">
        {errors[filedName] && (
          <p className="text-red-500 text-xs leading-none">
            {errors[filedName].message}
          </p>
        )}
      </div>
    </div>
  );
};

export default CheckBoxDropDown;
