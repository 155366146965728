import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { ContractorProfileLeftImage } from "../../../assets/images";
import axios from "axios";
import { boardMember } from "../../../constants/API";
import toast from "react-hot-toast";
import InputBoxLive from "../../../components/Elements/InputBoxLive";
import InputBox from "../../../components/Elements/InputBox";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../components/Elements/Loading";
import Layout from "../../../components/layout/Layout";
import ReadInputBox from "../../../components/Elements/ReadInputBox";
import SignatureModel from "../../../components/Corporation/BoardMember/SignatureModel";
import { MdOutlineFileUpload } from "react-icons/md";

const VerifyBoardMember = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  if (!queryParams.get("token")) {
    navigate("/");
  }
  const token = queryParams.get("token");
  const [alreadyVerified, setAlreadyVerified] = useState(false);
  const [boardMemberNotFound, setBoardMemberNotFound] = useState(false);
  const [findError, setFindError] = useState(false);

  const [load, setLoad] = useState(false);

  const schema = yup.object().shape({
    Board_Member_Name: yup.string().required("Board Member Name is required"),
    Board_Member_Email: yup
      .string()
      .required("Recovery Email is required")
      .matches(
        /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/,
        "Invalid Email Address"
      ),
    Board_Member_Phone_No: yup
      .string()
      .required("Board Member Phone Number is reuired")
      .matches(
        /^[0-9]{10}$/, // Regular expression for 10-digit phone number
        "Phone No must be exactly 10 digits"
      ),
    Designation: yup.string().required("Designation is required"),
    Signature: yup.string().required("Signature is required"),
    Term_And_Policy: yup.boolean().oneOf([true], "This field must be checked"),
  });

  const {
    register,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset,
    handleSubmit,
  } = useForm({ resolver: yupResolver(schema) });

  const getBoardMembersData = async () => {
    try {
      setLoad(true);
      const response = await axios.get(`${boardMember}/verify`, {
        headers: {
          authorization: token,
          companyType: "boardmember",
        },
      });
      const data = response.data.data;
      if (data.Signature) {
        setAlreadyVerified(true);
      } else {
        Object.keys(data).forEach((key) => {
          setValue(key, data[key]);
        });
        setAlreadyVerified(false);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      // console.log("error", error.response.data.message);

      if (error.response.data.data === false) {
        setBoardMemberNotFound(true);
      }
      setFindError(true);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    getBoardMembersData();
  }, []);

  const handleSaveBoardMembers = async (data) => {
    // console.log(data);
    const formdata = new FormData();

    Object.keys(data).forEach((key) => {
      formdata.append(key, data[key]);
    });

    formdata.delete("Signature");
    formdata.append("Signature", signature);

    try {
      setLoad(true);
      await axios.put(`${boardMember}/verify`, formdata, {
        headers: {
          authorization: token,
          companyType: "boardmember",
        },
      });
      toast.success("Board Member verified successfully");
      reset();
      navigate("/corporations/boardmember/login");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoad(false);
    }
  };

  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; //  MB
  const SUPPORTED_FILE_TYPES = ["image/jpeg", "image/png"];
  const [signature, setSignature] = useState(null);
  const [displaySignature, setDisplaySignature] = useState(null);
  const [signatureError, setSignatureError] = useState("");
  const [signatureUploded, setSignatureUploded] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadingSignature, setUploadingSignature] = useState(false);
  // const [signatureImage, setSignatureImage] = useState(null);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [signatureDrawError, setSignatureDrawError] = useState("");

  const handleChangeSignature = (e) => {
    const file = e.target.files[0];
    setSignatureDrawError("");
    if (!file) {
      setSignature(null);
      setSignatureError("");
      setSignatureUploded(false);
      setValue("Signature", null);
      clearErrors("Signature");
      setDisplaySignature(null);
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT &&
        SUPPORTED_FILE_TYPES.includes(file.type)
      ) {
        setSignature(file);
        setSignatureError("");
        setSignatureUploded(true);
        setValue("Signature", file);
        clearErrors("Signature");
        setDisplaySignature(URL.createObjectURL(file));
        setIsModelOpen(false);
      } else if (file && !SUPPORTED_FILE_TYPES.includes(file.type)) {
        setSignature(null);
        setSignatureError(
          "Unsupported file type file type must be jpg , jpeg, png or pdf"
        );
        clearErrors("Signature");
        setSignatureUploded(false);
        setValue("Signature", null);
        setDisplaySignature(null);
      } else if (file && !file.size <= FILE_SIZE_LIMIT) {
        setSignature(null);
        setSignatureError("File size exceeds limit (5 MB)");
        setSignatureUploded(false);
        clearErrors("Signature");
        setValue("Signature", null);
        setDisplaySignature(null);
      } else {
        setSignature(null);
        setSignatureError("Something went wrong! Please try again.");
        setSignatureUploded(false);
        clearErrors("Signature");
        setValue("Signature", null);
        setDisplaySignature(null);
      }
    }
  };

  const handleOpenModel = () => {
    setIsModelOpen(true);
  };

  const handleCloseModel = () => {
    setIsModelOpen(false);
  };

  const setClereErrors = () => {
    setSignatureError("");
    setSignatureDrawError("");
  };

  return (
    <Layout>
      <div>
        <Loading load={load} />

        <div className="h-full w-full flex   ">
          {/* First div : images */}

          <div className="w-[70%] hidden md:block ">
            <img
              src={ContractorProfileLeftImage}
              className="absolute top-0 bottom-0 w-[40%] h-full object-cover"
              alt="Signup background"
            />
          </div>

          <div className="absolute hidden bottom-[-1%] left-0 w-full md:w-[40%] h-[40%] bg-gradient-to-b from-transparent to-[#0893bd]  md:block"></div>

          <div className="w-full h-full pb-[3%] md:pl-[5%] md:pr-[2%] mx-3 sm:mx-3 md:mx-0">
            <div className="">
              <div className="mx-auto">
                <div className="container sm:mx-auto mx-auto ">
                  <h1 className="text-5xl  euclidbold text-[#0893bd] flex justify-center  sm:justify-center lg:justify-start mb-[5%]">
                    Verify Board Member
                  </h1>
                  {!load &&
                    !alreadyVerified &&
                    !findError &&
                    !boardMemberNotFound && (
                      <form
                        onSubmit={handleSubmit(handleSaveBoardMembers)}
                        encType="multipart/form-data"
                      >
                        <div>
                          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-20 ">
                            <InputBox
                              title={"Board_Member_Name"}
                              register={register}
                              filedName={"Board_Member_Name"}
                              must={true}
                              errors={errors}
                              className={"md:text-sm lg:text-base"}
                            />

                            <InputBoxLive
                              title={"Board_Member_Phone_No"}
                              register={register}
                              filedName={"Board_Member_Phone_No"}
                              must={true}
                              type="tel"
                              errors={errors}
                              className={"md:text-sm lg:text-base"}
                              maxLength="10"
                              schema={schema}
                              setError={setError}
                            />
                          </div>

                          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-20">
                            <ReadInputBox
                              title={"Board_Member_Email"}
                              register={register}
                              filedName={"Board_Member_Email"}
                              must={true}
                              errors={errors}
                              className={"md:text-sm lg:text-base"}
                            />
                            <InputBox
                              title={"Designation"}
                              register={register}
                              filedName={"Designation"}
                              must={true}
                              errors={errors}
                              className={"md:text-sm lg:text-base"}
                            />
                          </div>

                          <div className="md:gap-16 md:mr-4 lg:mr-20">
                            <div className="mb-3 w-full">
                              <label
                                htmlFor="Signature"
                                className="block font-normal"
                              >
                                Board Member Signature
                                <sup className="text-red-500 text-base">*</sup>
                              </label>

                              <div className="space-y-2">
                                {displaySignature && (
                                  <div className="h-32 w-32 rounded-sm ring-1 ring-gray-400 overflow-hidden">
                                    <img
                                      src={displaySignature}
                                      alt="Signature"
                                    />
                                  </div>
                                )}

                                <div
                                  onClick={handleOpenModel}
                                  className="bg-sky-600 flex gap-2 items-center justify-center cursor-pointer text-white w-fit px-4 py-2 rounded-md hover:bg-sky-700"
                                >
                                  <MdOutlineFileUpload />
                                  {displaySignature
                                    ? "Change Signature"
                                    : "Upload Signature"}
                                </div>
                                <div className="h-[3vh] pt-1">
                                  {errors.Signature && (
                                    <p className="text-red-500 text-xs leading-none">
                                      {errors.Signature.message}
                                    </p>
                                  )}
                                </div>

                                <SignatureModel
                                  isOpen={isModelOpen}
                                  onClose={handleCloseModel}
                                  signature={signature}
                                  setSignature={setSignature}
                                  setValue={setValue}
                                  errors={errors}
                                  handleChangeSignature={handleChangeSignature}
                                  uploadingFile={uploadingFile}
                                  setUploadingFile={setUploadingFile}
                                  setUploadingSignature={setUploadingSignature}
                                  signatureError={signatureError}
                                  displaySignature={displaySignature}
                                  uploadingSignature={uploadingSignature}
                                  signatureUploded={signatureUploded}
                                  setDisplaySignature={setDisplaySignature}
                                  setSignatureError={setSignatureError}
                                  signatureDrawError={signatureDrawError}
                                  setSignatureDrawError={setSignatureDrawError}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="">
                            <div className="flex gap-3 items-center">
                              <input
                                type="checkbox"
                                id="Term_And_Policy"
                                name="Term_And_Policy"
                                {...register("Term_And_Policy")}
                                className="bg-transparent  text-black min-h-7 min-w-7"
                              />
                              <label htmlFor="Term_And_Policy" className="pt-2">
                                I agree to all the{" "}
                                <Link
                                  href="/"
                                  className="text-blue-500 hover:text-blue-600"
                                >
                                  Terms
                                </Link>{" "}
                                and
                                <Link
                                  href="/"
                                  className="text-blue-500 hover:text-blue-600"
                                >
                                  {" "}
                                  Privacy policy
                                </Link>
                              </label>
                            </div>
                            <div className="h-[2vh] pt-1">
                              {errors.Term_And_Policy && (
                                <p className="text-red-500 text-xs  leading-none">
                                  {errors.Term_And_Policy.message}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className=" mb-3">
                            <button
                              type="submit"
                              onClick={setClereErrors}
                              className={`h-11  px-6 py-2.5 items-center ring-1  euclidbold ring-cyan-500   text-cyan-500 text-xl  tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:ring-1 hover:ring-cyan-600 hover:text-white transition duration-300 ease-in-out mt-4 md:mt-0 flex  ${
                                uploadingFile
                                  ? "cursor-not-allowed"
                                  : "cursor-pointer"
                              }`}
                              disabled={uploadingFile}
                            >
                              SAVE
                            </button>
                          </div>
                        </div>
                      </form>
                    )}

                  {!load && alreadyVerified && (
                    <div className="mt-10">
                      <h1 className="text-xl ">
                        Board Member already verified successfully !
                      </h1>
                      <Link
                        to="/corporations/boardmember/login"
                        className="text-sky-500 hover:text-sky-600"
                      >
                        Login Click Here
                      </Link>
                    </div>
                  )}

                  {findError && boardMemberNotFound && (
                    <div className="mt-10">
                      <h1 className="text-xl text-red-500">
                        Board Member not found!
                      </h1>
                    </div>
                  )}

                  {findError && !boardMemberNotFound && (
                    <div className="mt-10">
                      <h1 className="text-xl text-red-500">
                        Something went wrong! Please try again.
                      </h1>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VerifyBoardMember;
