import React, { useState, useEffect } from "react";
import { set, useForm, Controller, get } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  uploadBusinessCard,
  contractorProfileImagesAPI,
} from "../../../constants/API.js";
import { ApprovedIcon, Spinner } from "../../../assets/images/index.jsx";
import toast from "react-hot-toast";
import InputBox from "../../Elements/InputBox.jsx";
import CheckBoxDropDown from "../../Elements/CheckBoxDropDown.jsx";
import uimg from "../../../assets/image/uimg.png";
import { contractorSignupAsync } from "../../../store/Auth/Auth.js";
import {
  getCityArea,
  getDesignation,
  getServies,
  viewFileHandler,
} from "../../../constants/Request.jsx";
import InputBoxLive from "../../Elements/InputBoxLive.jsx";
import InputBoxTimeLive from "../../Elements/InputBoxTimeLive.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { TiBusinessCard } from "react-icons/ti";
import { RiFileUserFill } from "react-icons/ri";
import InputBoxPassword from "../../Elements/InputBoxPassword.jsx";

import InputBoxHandleChange from "../../Elements/InputBoxHandleChange.jsx";
import InputBoxLiveHandleChange from "../../Elements/InputBoxLiveHandleChange.jsx";
import InputBoxTimeLiveHandleChange from "../../Elements/InputBoxTimeLiveHandleChange.jsx";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { IoMdPerson } from "react-icons/io";
import { MdBusinessCenter } from "react-icons/md";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { GrUserExpert } from "react-icons/gr";

function ContractorSignupForm({ setLoad }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [selectServices, setSelectServices] = useState([]);
  const [selectArea, setSelectArea] = useState([]);

  // image upload handling constants
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5 MB
  const SUPPORTED_FILE_TYPES = ["image/jpeg", "image/png", "application/pdf"];
  const FILE_SIZE_LIMIT_PROFILE_PHOTO = 5 * 1024 * 1024; // 5 MB
  const SUPPORTED_FILE_TYPES_PROFILE_PHOTO = ["image/jpeg", "image/png"];

  // Example supported file types

  const [businessCard, setBusinessCard] = useState(null);
  const [businessCardError, setBusinessCardError] = useState("");
  const [businessCardUploded, setBusinessCardUploded] = useState("");
  const [businessCardURL, setBusinessCardURL] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadingBusinessCard, setUploadingBusinessCard] = useState(false);
  const [uploadingProfilePhoto, setUploadingProfilePhoto] = useState(false);
  const [displayBusinessCard, setDisplayBusinessCard] = useState(null); // For displaying the uploaded image
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoError, setProfilePhotoError] = useState("");
  const [profilePhotoUploded, setProfilePhotoUploded] = useState("");
  const [profilePhotoURL, setProfilePhotoURL] = useState("");
  const [displayProfilePhoto, setDisplayProfilePhoto] = useState(null); // For displaying the uploaded image
  const [servicesOptions, setServicesOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [otherServiceOptionId, setOtherServiceOptionId] = useState(null);
  const [isOpenOtherService, setIsOpenOtherService] = useState(false);

  useEffect(() => {
    getServies()
      .then((data) => {
        setServicesOptions(data);
        const other = data?.find((item) => item.Name === "Other");
        setOtherServiceOptionId(other?.ID);
      })
      .catch((error) => console.error("Error fetching services", error));
    getCityArea()
      .then((data) => {
        setAreaOptions(data);
      })
      .catch((error) => console.error("Error fetching areas:", error));
  }, []);

  // Form validation schema using yup
  const schema = yup.object().shape({
    First_Name: yup.string().required("First Name is required"),
    Last_Name: yup.string().required("LastName is required"),
    Recovery_Email: yup
      .string()
      .required("Email is required")
      .matches(
        /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/,
        "Invalid recovery email"
      ),
    Uid_Or_Email: yup
      .string()
      .required("User ID or email is required")
      .test("valid-userIdOrEmail", "Invalid user ID or email", (value) => {
        if (!value) return true; // Skip validation if the field is empty
        // Check if the value matches the pattern of either a user ID or an email
        return (
          /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/.test(value) ||
          /^[a-zA-Z0-9]+$/.test(value)
        );
      })
      .test(
        "not-same-as-uidOrEmail",
        "User Id cannot be the same as Email Id",
        function (value) {
          const Recovery_Email = this.parent.Recovery_Email;
          // If either of the fields is empty, skip the validation
          if (!value || !Recovery_Email) return true;
          return value !== Recovery_Email;
        }
      ),
    Password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        "Use a Strong Password"
      ),

    Phone_No: yup
      .string()
      .required("Phone No is required")
      .matches(
        /^[0-9]{10}$/, // Regular expression for 10-digit phone number
        "Phone No must be exactly 10 digits"
      ),
    Company_Name: yup.string().required("Company Name is required"),
    Company_Phone_No: yup
      .string()
      .required("Company Phone No is required")
      .matches(
        /^[0-9]{10}$/, // Regular expression for 10-digit phone number
        "Company Phone No must be exactly 10 digits"
      ),
    Company_Address: yup.string().required("Company Address is required"),
    Company_Fax_No: yup
      .string()
      .test("vaild-faxNo", "Invalid Fax No", function (value) {
        if (!value) return true; // Skip validation if the field is empty
        return /^[0-9]{10}$/.test(value);
      }),
    Insurance: yup.string(),
    Service: yup.array().min(1, "Service Provided is requried").default([]),
    Other_Service: yup
      .string()
      .test(
        "is-required",
        "Other Service Write is required Because Other Service is selected",
        function (value) {
          const service = this.parent.Service || [];
          if (service.includes(otherServiceOptionId)) {
            return value !== "";
          }
          return true;
        }
      ),
    Company_Business_Card: yup.string(),
    Profile_Photo: yup.string(),
    Working_Hours_From: yup.string().required("Working Hours From is required"),

    Working_Hours_To: yup
      .string()
      .required("Working Hours To is required")
      .test(
        "is-not-same",
        "To time must be different from From time",
        function (value) {
          const Working_Hours_From = this.parent.Working_Hours_From;
          return value !== Working_Hours_From;
        }
      )
      .test(
        "greater-than-from",
        "To time must be greater than From time",
        function (value) {
          const Working_Hours_From = this.parent.Working_Hours_From;
          return value > Working_Hours_From;
        }
      ),
    Designation: yup.string(),
    // Remember_Me: yup.boolean(),
    Term_And_Policy: yup.boolean().oneOf([true], "This field must be checked"),
    Area: yup.array(),
  });

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError,
    trigger,
    getValues,
  } = useForm({ resolver: yupResolver(schema) });

  //////////////////  Handle Contractor SignUp
  const onContractorSignUp = async (data, e) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.set(key, data[key]);
    });

    e.preventDefault();
    formData.delete("Company_Business_Card");
    formData.delete("Profile_Photo");
    if (businessCard) {
      console.log("Business Card", businessCard);

      formData.append("Company_Business_Card", businessCard);
    }

    if (profilePhoto) {
      console.log("Profile Photo", profilePhoto);

      formData.append("Profile_Photo", profilePhoto);
    }

    if (isOpenOtherService === false) {
      formData.set("Other_Service", "");
    }

    let res;
    try {
      setLoad(true);

      res = await dispatch(contractorSignupAsync(formData));
      if (res?.payload?.message) {
        toast.success(res.payload.message, { duration: 6000 });
        navigate("/contractors/login");
      }
    } catch (error) {
      toast.error(
        error.response.data.message || "Failed to register contractor"
      );
    } finally {
      setLoad(false);
    }
  };

  // Proceed to next step
  const nextStep = () => {
    // setStep(step + 1);
    handleNextStep();
  };

  const handleNextStep = async () => {
    const fieldsToValidate = {
      1: ["First_Name", "Last_Name", "Recovery_Email", "Phone_No"],
      2: [
        "Company_Name",
        "Company_Phone_No",
        "Company_Address",
        "Working_Hours_From",
        "Working_Hours_To",
      ],
      3: ["Designation", "Company_Fax_No"],
      4: [
        "Insurance",
        "Service",
        "Other_Service",
        "Password",
        "Uid_Or_Email",
        "Area",
      ],
      5: ["Service", "Area"],
    };

    const isValid = await trigger(fieldsToValidate[step]);

    console.log(isValid);

    if (isValid) {
      console.log(step, " and ", step + 1);

      setStep(step + 1);
    } else {
      console.log("Validation failed");
    }
  };

  // Go back to previous step
  const prevStep = () => {
    setStep(step - 1);
  };

  // Handle Business Card Upload Local
  const handleChangeBusinessCard = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBusinessCard(null);
      setBusinessCardError("");
      setBusinessCardUploded("");
      setBusinessCardURL("");
      setDisplayBusinessCard(null);
      clearErrors("companyBusinessCard");
      setDisplayBusinessCard(null);
    }

    if (!file) {
      setBusinessCard(null);
      setBusinessCardError("");
      setBusinessCardUploded("");
      setBusinessCardURL("");
      clearErrors("companyBusinessCard");
      setDisplayBusinessCard(null);
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT &&
        SUPPORTED_FILE_TYPES.includes(file.type)
      ) {
        setBusinessCard(file);
        setBusinessCardUploded("");
        setBusinessCardError("");
        setDisplayBusinessCard(null);
        setBusinessCardURL("");
      } else if (file && !SUPPORTED_FILE_TYPES.includes(file.type)) {
        setBusinessCard(null);
        setBusinessCardError(
          "Unsupported file type file type must be jpg , jpeg, png or pdf"
        );
        setBusinessCardUploded("");
        setBusinessCardURL("");
        setDisplayBusinessCard(null);
      } else if (file && !file.size <= FILE_SIZE_LIMIT) {
        setBusinessCard(null);
        setBusinessCardError("File size exceeds limit (5 MB)");
        setBusinessCardUploded("");
        setBusinessCardURL("");
        setDisplayBusinessCard(null);
      } else {
        setBusinessCard(null);
        setBusinessCardError("Something went wrong! Please try again.");
        setBusinessCardUploded("");
        setBusinessCardURL("");
        setDisplayBusinessCard(null);
      }
    }
  }; // Empty dependency array means the function will not change after the initial render

  const handleChangeProfilePhoto = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setProfilePhoto(null);
      setProfilePhotoError("");
      setProfilePhotoUploded("");
      setProfilePhotoURL("");
      clearErrors("profilePhoto");
      setDisplayProfilePhoto(null);
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT_PROFILE_PHOTO &&
        SUPPORTED_FILE_TYPES_PROFILE_PHOTO.includes(file.type)
      ) {
        setProfilePhoto(file);
        setProfilePhotoUploded("");
        setProfilePhotoError("");
        setProfilePhotoURL("");
        clearErrors("profilePhoto");
        const displayImage = URL.createObjectURL(file);
        setDisplayProfilePhoto(displayImage);
      } else if (
        file &&
        !SUPPORTED_FILE_TYPES_PROFILE_PHOTO.includes(file.type)
      ) {
        setProfilePhoto(null);
        setProfilePhotoError(
          "Unsupported file type file type must be jpg , jpeg or png"
        );
        setProfilePhotoUploded("");
        setProfilePhotoURL("");
        clearErrors("profilePhoto");
        setDisplayProfilePhoto(null);
      } else if (file && !file.size <= FILE_SIZE_LIMIT_PROFILE_PHOTO) {
        setProfilePhoto(null);
        setProfilePhotoError("File size exceeds limit (5 MB)");
        setProfilePhotoUploded("");
        setProfilePhotoURL("");
        clearErrors("profilePhoto");
        setDisplayProfilePhoto(null);
      } else {
        setProfilePhoto(null);
        setProfilePhotoError("Something went wrong! Please try again.");
        setProfilePhotoUploded("");
        setProfilePhotoURL("");
        clearErrors("profilePhoto");
        setDisplayProfilePhoto(null);
      }
    }
  };

  // Handle Business Card Upload API
  const handleUploadBusinessCard = async () => {
    if (!businessCard) {
      if (!businessCardError) {
        setBusinessCardError("Please Select a Company Business Card to Upload");
        setBusinessCardUploded("");
        setDisplayBusinessCard(null);
      }
      return;
    }

    const formData = new FormData();
    formData.append("file", businessCard);
    try {
      setBusinessCardError("");
      setUploadingBusinessCard(true);
      setUploadingFile(true);

      const response = await axios.post(uploadBusinessCard, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log(response);
      setBusinessCardError("");
      setBusinessCardURL(response.data.data.url);
      const displayImage = viewFileHandler(
        response.data.data.url,
        "contractor"
      );
      setDisplayBusinessCard(displayImage);
      setBusinessCardError("");
      setBusinessCardUploded("Uploaded Successfully");
    } catch (error) {
      console.error("Error uploading image:", error);
      setBusinessCardError("Failed to upload image");
      setBusinessCardUploded("");
      setBusinessCardURL("");
      setBusinessCardUploded("");
      setDisplayBusinessCard(null);
    } finally {
      setUploadingBusinessCard(false);
      setUploadingFile(false);
    }
  };

  const handleUploadProfilePhoto = async () => {
    if (!profilePhoto) {
      if (!profilePhotoError) {
        setProfilePhotoError("Please Select a Profile Photo to Upload");
        setProfilePhotoUploded("");
      }
      return;
    }

    const formData = new FormData();
    formData.append("file", profilePhoto);
    try {
      setProfilePhotoError("");
      setUploadingProfilePhoto(true);
      setUploadingFile(true);

      const response = await axios.post(contractorProfileImagesAPI, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setProfilePhotoError("");
      setProfilePhotoURL(response.data.data.url);
      setProfilePhotoError("");
      setProfilePhotoUploded("Uploaded Successfully");
    } catch (error) {
      console.error("Error uploading image:", error);
      setProfilePhotoError("Failed to upload image");
      setProfilePhotoUploded("");
      setProfilePhotoURL("");
      setProfilePhotoUploded("");
    } finally {
      setUploadingProfilePhoto(false);
      setUploadingFile(false);
    }
  };

  const businessCardClearError = () => {
    setBusinessCardError("");
    setProfilePhotoError("");
  };

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    const { name, value } = e.target;
    setValue(name, value); // Update the form value
    trigger(name); // Trigger validation for the specific field
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <p className=" text-3xl euclidbold text-[#0893bd] leading-tight flex items-center justify-center  sm:justify-center lg:justify-start mb-4 underline gap-2 ">
              <IoMdPerson />
              Personal information
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-20">
              <InputBoxLiveHandleChange
                title="First Name"
                register={register}
                filedName="First_Name"
                must={true}
                errors={errors}
                schema={schema} // Ensure this is passed correctly
                setError={setError}
                onChange={handleChange}
              />

              <InputBoxHandleChange
                title={"Last Name"}
                register={register}
                filedName={"Last_Name"}
                must={true}
                errors={errors}
                className={"md:text-sm lg:text-base"}
                onChange={handleChange}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-20">
              <InputBoxLiveHandleChange
                title={"Recovery Email"}
                register={register}
                filedName={"Recovery_Email"}
                must={true}
                errors={errors}
                className={"md:text-sm lg:text-base"}
                schema={schema}
                setError={setError}
                onChange={handleChange}
              />

              <InputBoxLiveHandleChange
                title={"Phone No"}
                register={register}
                filedName={"Phone_No"}
                must={true}
                type="tel"
                errors={errors}
                className={"md:text-sm lg:text-base"}
                maxLength="10"
                schema={schema}
                setError={setError}
                onChange={handleChange}
              />
            </div>

            <div className="flex justify-end md:mr-4 lg:mr-20">
              <div
                className="px-3 py-2 rounded-lg bg-primaryl cursor-pointer w-fit text-white text-center font-semibold shadow-md transform transition-all duration-300 tracking-widest flex items-center gap-2 hover:bg-secondaryl hover:shadow-lg hover:scale-105 hover:-translate-y-1"
                onClick={nextStep}
              >
                Next
                <FaArrowRight className="transition-transform duration-300 transform hover:translate-x-1" />
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <p className=" text-3xl euclidbold text-[#0893bd] leading-tight flex items-center justify-center   sm:justify-center lg:justify-start mb-4 underline gap-2 ">
              <MdOutlineBusinessCenter />
              Business Information
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-18 ">
              <InputBoxHandleChange
                title={"Company Name"}
                register={register}
                filedName={"Company_Name"}
                must={true}
                errors={errors}
                className={"md:text-sm lg:text-base"}
                onChange={handleChange}
              />
              <InputBoxLiveHandleChange
                title={"Company Phone No"}
                register={register}
                filedName={"Company_Phone_No"}
                must={true}
                type="tel"
                errors={errors}
                className={"md:text-sm lg:text-base"}
                maxLength="10"
                schema={schema}
                setError={setError}
                onChange={handleChange}
              />
            </div>
            <div className="grid grid-cols-1  md:mr-4 lg:mr-20 p-1">
              <InputBoxHandleChange
                title={"Company Address"}
                register={register}
                filedName={"Company_Address"}
                must={true}
                errors={errors}
                className={"md:text-sm lg:text-base"}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3 ">
              {/* Label */}
              <label className="block font-normal">
                Working Hours
                <sup className="text-red-500 text-base">*</sup>
              </label>
              {/* Input */}
              <div className="flex flex-wrap gap-5">
                <InputBoxTimeLiveHandleChange
                  title={"From"}
                  register={register}
                  filedName={"Working_Hours_From"}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  schema={schema}
                  setError={setError}
                  onChange={handleChange}
                />
                <InputBoxTimeLiveHandleChange
                  title={"To"}
                  register={register}
                  filedName={"Working_Hours_To"}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  schema={schema}
                  setError={setError}
                  onChange={handleChange}
                />
              </div>

              {/* Error */}
            </div>

            <div className="flex justify-between md:mr-4 lg:mr-20">
              <div
                className="px-3 py-2 rounded-lg bg-primaryl cursor-pointer w-fit text-white text-center font-semibold shadow-md transform transition-all duration-300 tracking-widest flex items-center gap-2 hover:bg-secondaryl hover:shadow-lg hover:scale-105 hover:-translate-y-1"
                onClick={prevStep}
              >
                <FaArrowLeft className="transition-transform duration-300 transform hover:-translate-x-1" />
                Back
              </div>
              <div
                className="px-3 py-2 rounded-lg bg-primaryl cursor-pointer w-fit text-white text-center font-semibold shadow-md transform transition-all duration-300 tracking-widest flex items-center gap-2 hover:bg-secondaryl hover:shadow-lg hover:scale-105 hover:-translate-y-1"
                onClick={nextStep}
              >
                Next
                <FaArrowRight className="transition-transform duration-300 transform hover:translate-x-1" />
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <div>
              {/* screen three */}
              <p className=" text-3xl euclidbold text-[#0893bd] leading-tight flex items-center justify-center   sm:justify-center lg:justify-start mb-4 underline gap-2 ">
                <MdBusinessCenter />
                Additional Business Details
              </p>

              {/* Designation and Working Hours*/}
              <div className="grid grid-cols-1 lg:grid-cols-2  lg:gap-16 md:mr-4 lg:mr-20">
                {/* Designation */}
                <InputBoxHandleChange
                  title={"Designation"}
                  register={register}
                  filedName={"Designation"}
                  must={false}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  onChange={handleChange}
                />
                <InputBoxLiveHandleChange
                  title={"Company Fax No"}
                  register={register}
                  filedName={"Company_Fax_No"}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  schema={schema}
                  setError={setError}
                  type="tel"
                  maxLength="10"
                  onChange={handleChange}
                />
              </div>

              {/* Company Business Card and Profile Photo */}
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-20">
                <div className="mb-3">
                  {/* Label */}
                  <label className={`block font-normal  text-base `}>
                    Company Business Card
                  </label>

                  {/* Input */}
                  <div className="">
                    <div className="">
                      <input
                        type="file"
                        id="companyBusinessCard"
                        name="companyBusinessCard"
                        accept=".jpg,.jpeg,.png,.pdf"
                        className={`hidden ${
                          uploadingFile
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        } `}
                        onChange={handleChangeBusinessCard}
                        disabled={uploadingFile}
                      />

                      {/* Label For style is input tag and use in File input */}
                      <label
                        htmlFor="companyBusinessCard"
                        className={`block bg-transparent h-10 w-full rounded-sm text-black  px-2  ps-10 focus:ring-sky-600 focus:outline-none focus:border-rose-600 ${
                          businessCardError
                            ? "ring-1 ring-red-500"
                            : "ring-1 ring-gray-400"
                        }  ${
                          uploadingFile
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        } `}
                        disabled={uploadingFile}
                      >
                        <div className="flex items-center h-full space-x-1">
                          <TiBusinessCard className="h-full w-12 text-cyan-500" />

                          {businessCard ? (
                            <span className="line-clamp-1">
                              {businessCard.name}
                            </span>
                          ) : (
                            <span className="text-gray-400 truncate  text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                              Upload company business card
                            </span>
                          )}
                        </div>
                      </label>
                    </div>
                  </div>

                  {/* Error */}
                  <div className="h-[3vh] pt-0">
                    {uploadingBusinessCard && <Spinner />}

                    {businessCardError && (
                      <p className="text-red-500 text-xs leading-none">
                        {businessCardError}
                      </p>
                    )}
                    {businessCardUploded && (
                      <p className="text-gray-600 text-sm leading-none flex items-center gap-2 mt-2">
                        <ApprovedIcon /> Businesscard uploaded Successfully!
                      </p>
                    )}
                  </div>
                </div>

                <div className="">
                  <div className="">
                    <label className={`block font-normal text-base `}>
                      Profile Photo
                    </label>
                    <div className="flex items-center gap-5">
                      <input
                        type="file"
                        id="profilePhoto"
                        name="profilePhoto"
                        className={`hidden ${
                          uploadingFile
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        } `}
                        onChange={handleChangeProfilePhoto}
                        disabled={uploadingFile}
                        accept=".jpg,.jpeg,.png"
                      />
                      {/* <!-- Profile Photo --> */}
                      <label
                        htmlFor="profilePhoto"
                        className={`h-24 w-24 mb-4 ${
                          uploadingFile
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        }  rounded-sm  focus:ring-sky-600 focus:outline-none focus:border-rose-600 ${
                          profilePhotoError
                            ? "ring-1 ring-red-500"
                            : "ring-1 ring-gray-300"
                        } `}
                        disabled={uploadingFile}
                      >
                        {profilePhotoURL ? (
                          <img
                            className="h-24 w-24"
                            src={profilePhotoURL}
                            alt="Profile "
                          />
                        ) : profilePhoto ? (
                          <img
                            className="h-24 w-24"
                            src={displayProfilePhoto}
                            alt="Profile "
                          />
                        ) : (
                          <RiFileUserFill className="h-24 w-24 text-cyan-500" />
                        )}
                      </label>
                      {/* <!-- Upload Button --> */}
                    </div>
                    {/* Error */}
                    <div className="h-[3vh] pt-0">
                      {uploadingProfilePhoto && <Spinner />}

                      {profilePhotoError && (
                        <p className="text-red-500 text-xs leading-none">
                          {profilePhotoError}
                        </p>
                      )}
                      {profilePhotoUploded && (
                        <p className="text-gray-600 text-sm leading-none flex items-center gap-2 mt-2">
                          <ApprovedIcon /> Profile Photo uploaded Successfully!
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between md:mr-4 lg:mr-20">
                <div
                  className="px-3 py-2 rounded-lg bg-primaryl cursor-pointer w-fit text-white text-center font-semibold shadow-md transform transition-all duration-300 tracking-widest flex items-center gap-2 hover:bg-secondaryl hover:shadow-lg hover:scale-105 hover:-translate-y-1"
                  onClick={prevStep}
                >
                  <FaArrowLeft className="transition-transform duration-300 transform hover:-translate-x-1" />
                  Back
                </div>
                <div
                  className="px-3 py-2 rounded-lg bg-primaryl cursor-pointer w-fit text-white text-center font-semibold shadow-md transform transition-all duration-300 tracking-widest flex items-center gap-2 hover:bg-secondaryl hover:shadow-lg hover:scale-105 hover:-translate-y-1"
                  onClick={nextStep}
                >
                  Next
                  <FaArrowRight className="transition-transform duration-300 transform hover:translate-x-1" />
                </div>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            {/* screen four */}
            <p className=" text-3xl euclidbold text-[#0893bd] leading-tight flex items-center justify-center   sm:justify-center lg:justify-start mb-4 underline gap-2 ">
              <GrUserExpert />
              Professional Expertise
            </p>
            {/* UserId OR Email and Password */}
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-20">
              <InputBoxLive
                title={"USER ID"}
                register={register}
                filedName={"Uid_Or_Email"}
                must={true}
                errors={errors}
                className={"md:text-sm lg:text-base"}
                schema={schema}
                setError={setError}
                onChange={handleChange}
              />

              <div className="flex items-center gap-4">
                <div className="w-full">
                  <InputBoxPassword
                    title={"Password"}
                    register={register}
                    filedName={"Password"}
                    must={true}
                    errors={errors}
                    className={"md:text-sm lg:text-base"}
                    schema={schema}
                    setError={setError}
                    type={showPassword ? "text" : "password"}
                  />
                </div>

                <div
                  className="text-gray-400 cursor-pointer w-5"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="text-lg transition-colors hover:text-gray-500"
                  />
                </div>
              </div>
            </div>

            {/* Service Provided Checkbox */}
            <div className="">
              <div className="mb-3">
                {/* Label */}
                <label
                  className={`block font-normal  
                    }`}
                >
                  Service Provided{" "}
                  <span className="text-red-500 text-base">*</span>
                </label>
                {/* Input */}
                <div className="grid grid-cols-1  md:grid-cols-2 items-center md:gap-16 ">
                  <div className="">
                    <CheckBoxDropDown
                      title={"Service Provided"}
                      options={servicesOptions}
                      onSelect={setSelectServices}
                      selectOption={selectServices}
                      setValue={setValue}
                      filedName={"Service"}
                      clearErrors={clearErrors}
                      errors={errors}
                      setError={setError}
                      dbfield={"Name"}
                      schema={schema}
                      must={true}
                      isOther={true}
                      otherOptionId={otherServiceOptionId}
                      isOpenOther={isOpenOtherService}
                      setIsOpenOther={setIsOpenOtherService}
                    />
                  </div>

                  {/* If Display A Service Data text box view comment box open */}

                  <div className="mb-3 lg:-ml-10">
                    <input
                      type="text"
                      id="servicesDisplay"
                      name="servicesDisplay"
                      className={`bg-transparent block h-10 min-w-20 w-full cursor-default rounded-sm text-black  ring-1 px-2 ring-gray-400 ps-10 focus:ring-sky-600 focus:outline-none focus:border-rose-600 `}
                      placeholder="Display Selected Services"
                      value={
                        servicesOptions
                          ?.filter((item) => selectServices?.includes(item.ID))
                          .map((item) => item.Name)
                          .join(", ") || ""
                      }
                      readOnly
                    />

                    {/* Service Provide Error Display for the display selected service below */}
                    {/* <div className="h-[2vh] pt-1">
                            {errors.service && (
                              <p className="text-red-500 text-xs leading-none">
                                {errors.service.message}
                              </p>
                            )}
                          </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* Service Provided */}
            {isOpenOtherService && (
              <div className="grid grid-cols-1  md:mr-4 lg:mr-20">
                <InputBox
                  title={"Other Service Provided"}
                  register={register}
                  filedName={"Other_Service"}
                  errors={errors}
                  must={true}
                  placeHolder={"Type Other Service Sperated by Comma(,)"}
                  className={"md:text-sm lg:text-base"}
                />
              </div>
            )}

            {/* Wroking Area */}
            <div className="">
              <div className="mb-3">
                {/* Label */}
                <label
                  className={`block font-normal  
            }`}
                >
                  Working Area
                </label>
                {/* Input */}
                <div className="grid grid-cols-1 md:grid-cols-2 items-center md:gap-16">
                  <div className="">
                    <CheckBoxDropDown
                      title={"Select Area / City"}
                      options={areaOptions}
                      onSelect={setSelectArea}
                      selectOption={selectArea}
                      setValue={setValue}
                      filedName={"Area"}
                      clearErrors={clearErrors}
                      errors={errors}
                      dbfield={"Name"}
                      setError={setError}
                      schema={schema}
                    />
                  </div>

                  <div className="mb-3 lg:-ml-10">
                    <input
                      type="text"
                      id="areaDisplay"
                      name="areaDisplay"
                      className={`bg-transparent block h-10 min-w-20 w-full cursor-default rounded-sm text-black ring-1 px-2 ring-gray-400 ps-10 focus:ring-sky-600 focus:outline-none focus:border-rose-600 `}
                      placeholder="Display Selected Area/City"
                      value={
                        areaOptions
                          ?.filter((item) => selectArea?.includes(item.ID))
                          .map((item) => item.Name)
                          .join(", ") || ""
                      }
                      readOnly
                    />

                    <div className="h-[2vh] pt-1"></div>
                  </div>
                </div>
              </div>
            </div>

            {/* Company Fax No and Insurance */}
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-20">
              <InputBoxHandleChange
                title={"Insurance"}
                register={register}
                filedName={"Insurance"}
                errors={errors}
                className={"md:text-sm lg:text-base"}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <div className="lg:-mr-28">
                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    id="termsAndPolicy"
                    name="termsAndPolicy"
                    {...register("Term_And_Policy")}
                    className="bg-transparent  text-black min-h-7 min-w-7"
                  />
                  <label htmlFor="termsAndPolicy" className="pt-2">
                    I agree to all the{" "}
                    <Link
                      to="/terms"
                      className="text-blue-500 hover:text-blue-600"
                    >
                      {" "}
                      Terms
                    </Link>{" "}
                    and
                    <Link
                      to="/privacypolicy"
                      className="text-blue-500 hover:text-blue-600"
                    >
                      {" "}
                      Privacy policy
                    </Link>
                  </label>
                </div>
                <div className="h-[2vh] pt-1">
                  {errors.Term_And_Policy && (
                    <p className="text-red-500 text-xs  leading-none">
                      {errors.Term_And_Policy.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-between md:mr-4 lg:mr-20">
              <div
                className="px-3 py-2 rounded-lg bg-primaryl cursor-pointer w-fit text-white text-center font-semibold shadow-md transform transition-all duration-300 tracking-widest flex items-center gap-2 hover:bg-secondaryl hover:shadow-lg hover:scale-105 hover:-translate-y-1"
                onClick={prevStep}
              >
                <FaArrowLeft className="transition-transform duration-300 transform hover:-translate-x-1" />
                Back
              </div>
              {/* Submit Button */}
              {/* <div className=" mb-3">
                <button
                  onClick={businessCardClearError}
                  type="submit"
                  className={`h-11  px-6 py-2.5 items-center ring-1  euclidbold ring-cyan-500   text-cyan-500 text-xl  tracking-widest hover:bg-gradient-to-r from-cyan-600 to-blue-800 hover:ring-1 hover:ring-cyan-600 hover:text-white transition duration-300 ease-in-out mt-4 md:mt-0 flex
                     ${
                       uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                     }`}
                  disabled={uploadingFile}
                >
                  SIGN UP
                </button>
              </div> */}
              <button
                onClick={businessCardClearError}
                type="submit"
                className="px-3 py-2 focus:outline-none rounded-lg bg-primaryl cursor-pointer w-fit text-white text-center font-semibold shadow-md transform transition-all duration-300 tracking-widest flex items-center gap-2 hover:bg-secondaryl hover:shadow-lg hover:scale-105 hover:-translate-y-1"
              >
                SIGN UP
                <FaArrowRight className="transition-transform duration-300 transform hover:translate-x-1" />
              </button>
            </div>
          </div>
        );

      default:
        return <div />;
    }
  };

  return (
    <div className="w-full min-h-[62vh] md:pl-[7%] md:pr-[2%] mx-3 sm:mx-3 md:mx-0">
      <div className="">
        <div className="mx-auto">
          <div className="container sm:mx-auto mx-auto">
            <h1 className="text-5xl mt-7 md:mt-0 euclidbold text-[#0893bd] flex justify-center  sm:justify-center lg:justify-start">
              Create account
            </h1>
            <p className="mt-1 text-black/50 text-opacity-50  text-base leading-tight flex justify-center  sm:justify-center lg:justify-start ">
              Create Contractor Account and Join portal of opportunity!
            </p>

            {/* SignUp Form Start Here  */}
            <form
              onSubmit={handleSubmit(onContractorSignUp)}
              encType="multipart/form-data"
            >
              <div className="bg-white rounded-sm my-5 ">{renderForm()}</div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContractorSignupForm;
