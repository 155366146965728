import React, { useEffect, useState } from "react";
import { Downloads, Location } from "../../assets/images/index.jsx";
import ContractImage from "./ContractImage.jsx";
import { NavLink, useLocation } from "react-router-dom";
import { getWorkingHoursByCorporationId } from "../../constants/Request.jsx";

function ContractDetailsCard() {
  const location = useLocation();

  const [displayImage, setDisplayImage] = useState(
    location.state.Project_Photos_1 || ""
  );
  const [projectPhotos, setProjectPhotos] = useState([]);
  const [workingHours, setWorkingHours] = useState([]); // New state for working hours

  useEffect(() => {
    ContractImageManage();
  }, [location.state]);

  const ContractImageManage = async () => {
    for (let i = 1; i <= 10; i++) {
      const photoKey = `Project_Photos_${i}`;
      if (
        location.state[photoKey] !== null &&
        location.state[photoKey] !== ""
      ) {
        setProjectPhotos((prev) => [...prev, location.state[photoKey]]);
      }
    }
  };

  const workinghours = async (corporationId) => {
    try {
      const res = await getWorkingHoursByCorporationId(corporationId);
      setWorkingHours(res); // Store the working hours in state
    } catch (error) {
      console.error("Error fetching working hours:", error);
    }
  };

  useEffect(() => {
    if (location.state?.Corporation_ID) {
      workinghours(location.state.Corporation_ID);
    }
  }, [location.state?.Corporation_ID]);

  return (
    <div className="max-w-6xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className=""></div>
        <div className="flex-1 px-4">
          <p className="text-lg font-bold">Details</p>
          <hr className="border-gray-300" />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="md:flex-1 px-4">
          <div className="h-[460px] rounded-lg dark:bg-gray-700 mb-4">
            {displayImage !== "" ? (
              <img
                className="w-full h-full"
                src={displayImage}
                alt={location.state.Project_Name}
              />
            ) : (
              "No Image"
            )}
          </div>

          <div className="flex-wrap hidden md:flex px-1">
            {projectPhotos.map((photo, index) => (
              <ContractImage
                src={photo}
                key={index}
                setDisplayImage={setDisplayImage}
                displayImage={displayImage}
              />
            ))}
          </div>
        </div>

        <div className="md:flex-1 px-4">
          <div className="space-y-1">
            <div className="text-indigo-900 euclidbold text-2xl py-3 sm:pt-3 md:pt-3 lg:pt-0">
              {location.state.Project_Name}
            </div>
            <div className="text-base flex space-x-2 items-center">
              <Location />
              <p className="text-gray-700">{location.state.Project_Location}</p>
            </div>

            <div className="space-y-2 pt-4 pb-3">
              <p className="text-sm">
                <span>
                  Project Starting Date:
                  <span className="font-bold euclidbold">
                    {location.state.Start_Date}
                  </span>
                </span>
              </p>
              <p className="text-sm">
                <span>
                  Project Proposal Deadline Date:
                  <span className="font-bold euclidbold">
                    {location.state.Proposal_Deadline}
                  </span>
                </span>
              </p>
            </div>
            {location?.state?.Project_Photos && (
              <NavLink
                to={location.state.Project_Photos}
                className="h-12 px-1 sm:text-sm w-fit py-2.5 text-[9px] whitespace-nowrap border-2  border-cyan-500   text-cyan-500 text-xl font-bold tracking-widest hover:border-secondaryl   transition duration-300 items-center flex uppercase justify-center euclidbold my-3"
              >
                <img src={Downloads} alt="" srcSet="" />
              </NavLink>
            )}

            <p className="text-gray-700 text-base text-justify font-normal overflow-hidden">
              {location.state.Project_Description}
            </p>

            <div>
              <h3 className="font-bold text-lg my-2">
                Property manager On site Schedule:
              </h3>
              {workingHours.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-2">
                  <ul className="list-disc pl-3">
                    {workingHours
                      .slice(0, Math.ceil(workingHours.length / 2))
                      .map(
                        (wh) =>
                          wh.Checked && (
                            <li key={wh.ID} className="text-sm">
                              <strong>{wh.Day}:</strong>{" "}
                              {wh.Checked &&
                              wh.Working_Hours_From &&
                              wh.Working_Hours_To
                                ? `${wh.Working_Hours_From} - ${wh.Working_Hours_To}`
                                : "Not Working"}
                            </li>
                          )
                      )}
                  </ul>
                  <ul className="list-disc pl-3">
                    {workingHours.slice(Math.ceil(workingHours.length / 2)).map(
                      (wh) =>
                        wh.Checked && (
                          <li key={wh.ID} className="text-sm">
                            <strong>{wh.Day}:</strong>{" "}
                            {wh.Checked &&
                            wh.Working_Hours_From &&
                            wh.Working_Hours_To
                              ? `${wh.Working_Hours_From} - ${wh.Working_Hours_To}`
                              : "Not Working"}
                          </li>
                        )
                    )}
                  </ul>
                </div>
              ) : (
                <p>No working hours available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContractDetailsCard;
