import React, { useEffect, useState } from "react";

function InputBoxLive({
  title,
  register,
  filedName,
  must,
  errors,
  placeHolder = "",
  className,
  type = "text",
  width = "w-full",
  min,
  max,
  maxLength = "",
  schema,
  setError,
  disabled = false,
  continueValidation = false,
}) {
  const [touched, setTouched] = useState(false);

  const handleKeyDown = (event) => {
    if (type === "number" || type === "tel") {
      // Check if the pressed key is not a number
      if (
        !(
          (
            (event.key >= "0" && event.key <= "9") ||
            event.key === "Tab" ||
            event.key === "F5" ||
            event.key === "ArrowLeft" ||
            event.key === "ArrowRight" ||
            event.key === "Delete" ||
            event.keyCode === 38 ||
            event.keyCode === 40 ||
            event.keyCode === 8 || // backspace
            (event.ctrlKey && event.keyCode === 65) ||
            (event.ctrlKey && event.keyCode === 86) || // Ctrl + V
            (event.ctrlKey && event.keyCode === 67)
          ) // Ctrl + C
        )
      ) {
        // Prevent the default behavior (don't write the character)
        event.preventDefault();
      }
    }
  };

  const handlePaste = (event) => {
    if (type === "number" || type === "tel") {
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData("text");

      // Check if the pasted data contains only numbers
      if (!/^\d+$/.test(pastedData)) {
        event.preventDefault();
      }
    }
  };

  const handleKeyUp = (event) => {
    if (touched) {
      const value = event.target.value;

      schema
        .validateAt(filedName, { [filedName]: value }) // Validate the field
        .then((valid) => {
          // If the field is valid, remove the error message
          if (errors[filedName]) {
            delete errors[filedName];
          }
          setTouched(false);
        })
        .catch((err) => {
          // If the field is invalid, add the error message
          setError(filedName, { type: "manual", message: err.errors[0] });
          setTouched(true);
        })
        .finally(() => {});
    }
  };

  const handleBlur = (event) => {
    const value = event.target.value;

    if (!value == "") {
      schema
        .validateAt(filedName, { [filedName]: value }) // Validate the field
        .then((valid) => {
          // If the field is valid, remove the error message
          if (errors[filedName]) {
            delete errors[filedName];
          }
          setTouched(false);
        })
        .catch((err) => {
          // If the field is invalid, add the error message
          setError(filedName, { type: "manual", message: err.errors[0] });
          setTouched(true);
        })
        .finally(() => {});
    }
  };

  return (
    <div className="mb-3">
      {/* Label */}
      <label
        htmlFor={filedName}
        className={`block font-normal  
  }`}
      >
        <p>
          {title === "Recovery Email" ? "Email" : title}
          {must ? <span className="text-red-500 text-base">*</span> : ""}
        </p>
      </label>
      {/* Input */}
      <input
        type={type}
        id={filedName}
        name={filedName}
        {...register(filedName)}
        className={` block  h-10  ${width}  rounded-sm text-black  ring-1 px-2 ring-gray-400 ps-10 focus:ring-sky-600 focus:outline-none focus:border-rose-600 ${
          errors[filedName] ? "ring-1 ring-red-500" : ""
        } ${className} ${disabled ? "bg-gray-200" : "bg-transparent"}`}
        {...(placeHolder
          ? { placeholder: placeHolder }
          : {
              placeholder: `${
                title === "USER ID"
                  ? "User ID other than Email"
                  : title === "Recovery Email"
                  ? "Type Email"
                  : `Type ${title}`
              }`,
            })}
        min={min}
        max={max}
        maxLength={maxLength}
        onKeyUp={handleKeyUp}
        onKeyDown={type === "number" || type === "tel" ? handleKeyDown : null}
        onBlur={handleBlur}
        onPaste={handlePaste}
        // Conditionally attach the event listener
      />

      {/* Error */}
      <div className="h-[2vh] pt-1">
        {errors[filedName] && (
          <p className="text-red-500 text-xs leading-none">
            {errors[filedName].message}
          </p>
        )}
      </div>
    </div>
  );
}

export default InputBoxLive;
