import React from "react";
import { Footer, Header } from "../../components";
import aboutimg from "../../assets/image/Rectangle 524.png";
import Contact from "../Contact/Contact";
import ContactForm from "../Contact/ContactForm";
function About() {
  return (
    <div className="overflow-hidden">
      <Header />
      {/* Content */}
      <hr className="w-4/5 mx-auto mt-0 md:mt-[5vh]" />
      <div className="py-4 md:py-16 px-[2.5%] md:px-[8%]">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-7 gap-2 items-center">
            {/* Image Section */}
            <div className="lg:px-4 xl:px-16 lg:col-span-3 order-2 lg:order-2">
              <div className="w-full">
                <img src={aboutimg} alt="About Us" className="w-full ml-auto" />
              </div>
            </div>
            {/* About Us Section */}
            <div className="px-0 lg:col-span-4 order-1 lg:order-1">
              <h2 className="text-2xl lg:text-4xl  text-primaryl euclidbold">
                About Us
              </h2>
              <hr className="border-t-2 mb-4" />
              <p className="mb-4 italic text-gray-800 text-justify">
                Welcome to Condominium Portal Inc., your gateway to
                revolutionizing the condominium contracting landscape. Founded
                by Chintukumar Patel, a visionary entrepreneur with a passion
                for innovation and a deep understanding of the industry,
                Condominium Portal is set to transform how condominium
                corporations and contractors collaborate.
              </p>
              <p className="mb-4 italic text-gray-800 text-justify">
                At Condominium Portal, we believe in transparency, efficiency,
                and trust. Our platform is designed to bridge the gap between
                traditional contracting processes and modern digital solutions,
                empowering condominium corporations to make informed decisions,
                save costs, and streamline their operations.
              </p>
              <p className="mb-4 italic text-gray-800 text-justify">
                What sets us apart is our commitment to creating a level playing
                field for contractors of all sizes. Whether you're a small
                business looking to expand your reach or an established
                contractor seeking new opportunities, Condominium Portal
                provides a platform where talent meets opportunity.
              </p>
              <p className="mb-4 italic text-gray-800 text-justify">
                With a focus on user experience, integrity, and continuous
                improvement, Condominium Portal is not just a service provider
                but a strategic partner in your journey towards success. Join us
                in shaping the future of condominium contracting today.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
      <Footer />
    </div>
  );
}
export default About;
